/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import React from 'react';
import './ChannelCard.scss';
import Button from 'react-bootstrap/Button';
import { VerifiedTooltipLinkDark } from '../CustomCheckTooltips';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import userEngagementActions from 'redux/middleware/userEngagement';
import { useState } from 'react';
// import { toast } from 'react-toastify';
import { getProfilePicture } from 'utils/helpers';
import 'react-toastify/dist/ReactToastify.css';

function ChannelCard({
  imgAlt = '',
  channelName,
  subscriberCount,
  channelHref,
  verified,
  imgSrc,
  Count,
  outline = false,
  isFollow,
  setFollowUnFollowToggle,
  hashTag,
  tagName,
  postCount,
}) {
  const { userDetails } = useSelector((state) => state?.Profile?.UserDetails);
  const loginDetails = JSON.parse(localStorage.getItem('__tmw_user'));
  const [isFollowing, setIsFollowing] = useState(isFollow);
  let buttonVariant;

  if (outline) {
    buttonVariant = isFollowing ? 'outline-secondary' : 'outline-danger';
  } else {
    buttonVariant = isFollowing ? 'secondary' : 'danger';
  }
  const buttonText = isFollowing ? 'Following' : 'Follow';

  const followHandler = async () => {
    if (loginDetails?.token) {
      try {
        const apiResponse = await userEngagementActions.followUser(
          loginDetails?.token,
          channelHref
        );
        if (apiResponse.status) {
          const { data } = apiResponse;
          setIsFollowing(!isFollowing);
          setFollowUnFollowToggle();
        } else {
        }
      } catch (error) {}
    } else {
      // toast.error('Please Sign-in to follow');
    }
  };

  const unFollowHandler = async () => {
    try {
      const apiResponse = await userEngagementActions.removeFollowing(
        loginDetails?.token,
        channelHref
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setIsFollowing(!isFollowing);
        setFollowUnFollowToggle();
      } else {
      }
    } catch (error) {}
  };

  const [text, setText] = useState(hashTag);

  //Your handler Component
  const addDefaultSrc = (ev) => {
    ev.target.src = getProfilePicture('');
  };

  return (
    <React.Fragment>
      {text ? (
        <div className="channels-card">
          <div className="channels-card-image">
            <div className="channels-card-image-btn"></div>
          </div>
          <div className="channels-card-body">
            <div className="channels-title">
              <Link to={`/profile/${channelHref}`}>
                #{tagName} {verified ? <VerifiedTooltipLinkDark /> : ''}
              </Link>
            </div>
            <div className="channels-view">{Count} Post</div>
          </div>
        </div>
      ) : (
        <div className="channels-card">
          <div className="channels-card-image">
            <Link to={`/profile/${channelHref}`}>
              <img
                className="img-fluid"
                src={imgSrc}
                alt={imgAlt}
                onError={addDefaultSrc}
              />
            </Link>
            <div className="channels-card-image-btn">
              <Button
                disabled={userDetails?._id === channelHref ? true : false}
                variant={buttonVariant}
                onClick={isFollowing ? unFollowHandler : followHandler}
              >
                {buttonText}
              </Button>
            </div>
          </div>
          <div className="channels-card-body">
            <div className="channels-title">
              <Link to={`/profile/${channelHref}`}>
                {channelName} {verified ? <VerifiedTooltipLinkDark /> : ''}
              </Link>
            </div>
            <div className="channels-view">
              {subscriberCount} Followers | {postCount || 0} Posts
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default ChannelCard;
