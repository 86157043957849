/* eslint-disable no-empty */
import React from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
// import { Calendar } from 'react-date-range';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import AuthActions from 'redux/middleware/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  CityFailed,
  CityPending,
  CitySuccess,
} from 'redux/reducers/resourceSlice';
import resourceConfiguration from 'redux/middleware/resourceConfiguration';
// import dayjs from 'dayjs';

export default function UpdateProfileForm({
  userDetails,
  coverImageSelect,
  profileImageSelect,
}) {
  const { Country, City } = useSelector((state) => state.Profile.Resources);

  const userLoggedIn = JSON.parse(localStorage.getItem('__tmw_user'));
  const dispatch = useDispatch();
  // const [openDate, setOpenDate] = useState(false);
  const [profile, setProfile] = useState({
    lastName: '',
    firstName: '',
    description: '',
    dob: '',
    country: '',
    city: '',
    countryId: '',
    cityId: '',
    gender: '',
    facebook: '',
    twitter: '',
    insta: '',
    profilePicture: profileImageSelect,
    coverPhoto: coverImageSelect,
  });

  const callCitiesApi = async () => {
    dispatch(CityPending());
    try {
      const apiResponse = await resourceConfiguration.getCities(
        profile?.countryId
      );

      if (apiResponse.status) {
        const { data } = apiResponse;
        const payload = {
          City: data,
        };
        dispatch(CitySuccess(payload));
      } else {
        const payload = {
          message: apiResponse.message,
        };
        dispatch(CityFailed(payload));
      }
    } catch (error) {
      dispatch(CityFailed(error));
    }
  };

  const submissionHandler = async () => {
    try {
      const apiResponse = await AuthActions.UpdateProfile(
        profile,
        userLoggedIn.token
      );
      if (apiResponse.status) {
        toast.success(apiResponse.message);
      } else {
        const payload = {
          message: apiResponse.message,
        };
        toast.error(payload.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setProfile({
      lastName: userDetails?.LastName,
      firstName: userDetails?.FirstName,
      description: userDetails?.Description,
      dob: userDetails?.DOB,
      country: userDetails?.Country,
      city: userDetails?.City,
      countryId: userDetails?.CountryId,
      cityId: userDetails?.CityId,
      gender: userDetails?.Gender,
      facebook: userDetails?.SocialLinks?.fb,
      twitter: userDetails?.SocialLinks?.twitter,
      insta: userDetails?.SocialLinks?.instagram,
      profilePicture: profileImageSelect || userDetails?.Config?.ProfilePic,
      coverPhoto: coverImageSelect || userDetails?.Config?.CoverPhoto,
    });
  }, [userDetails]);

  useEffect(() => {}, [profile]);

  useEffect(() => {
    if (profile?.countryId) {
      callCitiesApi();
    }
  }, [profile?.countryId]);

  useEffect(() => {
    setProfile((prevState) => {
      return {
        ...prevState,
        profilePicture: profileImageSelect || userDetails?.Config?.ProfilePic,
        coverPhoto: coverImageSelect || userDetails?.Config?.CoverPhoto,
      };
    });
  }, [profileImageSelect, coverImageSelect]);

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <div className="osahan-form">
            <Row>
              <Col lg={12}>
                <Form.Group controlId="video-title">
                  <Row>
                    <Col lg={6} sm={12}>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        value={profile?.firstName}
                        onChange={(e) =>
                          setProfile((prevState) => {
                            return { ...prevState, firstName: e.target.value };
                          })
                        }
                      />
                    </Col>
                    <Col lg={6} sm={12}>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        value={profile?.lastName}
                        onChange={(e) =>
                          setProfile((prevState) => {
                            return { ...prevState, lastName: e.target.value };
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col lg={12} sm={12}>
                <Form.Group controlId="video-title">
                  <Row>
                    {/* <Col style={{ zIndex: '1' }}>
                      <Form.Label>Date of Birth</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        type="text"
                        placeholder={new Date(profile?.dob)}
                        onClick={() => setOpenDate(!openDate)}
                        style={{
                          background: 'white',
                        }}
                        x
                        // onBlur={() => setOpenDate(!openDate)}
                      />
                      {openDate && (
                        <Calendar
                          formatDay={(locale, date) =>
                            dayjs(date).format('YYYY-MM-DD')
                          }
                          date={new Date(profile?.dob) || new Date()}
                          onChange={(e) => {
                            setOpenDate(!openDate);
                            setProfile((prevState) => {
                              return { ...prevState, dob: e };
                            });
                          }}
                          className="date"
                        />
                      )}
                        </Col> */}
                    <Col lg={12} sm={12}>
                      <Form.Group controlId="video-description">
                        <Form.Label>About</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Description"
                          value={
                            profile?.description === 'undefined'
                              ? ''
                              : profile?.description
                          }
                          onChange={(e) => {
                            setProfile((prevState) => {
                              return {
                                ...prevState,
                                description: e.target.value,
                              };
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} sm={12}>
                      <Form.Label>Gender</Form.Label>
                      <Form.Control
                        as="select"
                        className="custom-select"
                        value={profile?.gender?.toLowerCase()}
                        onChange={(e) => {
                          setProfile((prevState) => {
                            return { ...prevState, gender: e.target.value };
                          });
                        }}
                      >
                        <option>select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col lg={12} sm={12}>
                <Form.Group controlId="video-title">
                  <Row>
                    <Col>
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        as="select"
                        className="custom-select"
                        value={`{"country": "${profile?.country}", "countryId": "${profile?.countryId}"}`}
                        onChange={(e) => {
                          const data = e.target.value;
                          const dataParsed = JSON.parse(`${data}`);
                          setProfile((prevState) => {
                            return {
                              ...prevState,
                              countryId: dataParsed?.countryId,
                              country: dataParsed?.country,
                            };
                          });
                        }}
                      >
                        <option>Select Country</option>
                        {Country &&
                          Country?.map((country, index) => (
                            <option
                              value={`{"country": "${country?.name}", "countryId": "${country?._id}"}`}
                              key={index}
                            >
                              {country?.name}
                            </option>
                          ))}
                      </Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        as="select"
                        className="custom-select"
                        value={`{"city": "${profile?.city}", "cityId": "${profile?.cityId}"}`}
                        onChange={(e) => {
                          const data = e.target.value;
                          const dataParsed = JSON.parse(`${data}`);
                          setProfile((prevState) => {
                            return {
                              ...prevState,
                              cityId: dataParsed?.cityId,
                              city: dataParsed?.city,
                            };
                          });
                        }}
                      >
                        {City?.length ? (
                          <>
                            <option>Select City</option>
                            {City?.map((city, index) => (
                              <option
                                value={`{"city": "${city?.name}", "cityId": "${city?._id}"}`}
                                key={index}
                              >
                                {city?.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option>Select Country first</option>
                        )}
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group controlId="video-title">
                  <Row>
                    <Col lg={4} sm={12}>
                      <Form.Label>Facebook</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="/Facebook URL"
                        value={
                          profile?.facebook === 'undefined'
                            ? ''
                            : profile?.facebook
                        }
                        onChange={(e) => {
                          setProfile((prevState) => {
                            return { ...prevState, facebook: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col lg={4} sm={12}>
                      <Form.Label>Twitter</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="/Twitter URL"
                        value={
                          profile?.twitter === 'undefined'
                            ? ''
                            : profile?.twitter
                        }
                        onChange={(e) => {
                          setProfile((prevState) => {
                            return { ...prevState, twitter: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col lg={4} sm={12}>
                      <Form.Label>Instagram</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="/Instagram URL"
                        value={
                          profile?.insta === 'undefined' ? '' : profile?.insta
                        }
                        onChange={(e) => {
                          setProfile((prevState) => {
                            return { ...prevState, insta: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              {/* <Col lg={12}>
                <Form.Group controlId="video-title">
                  <Row>
                    <Col>
                      <Form.Label>Profile Picture</Form.Label>
                      <Form.File
                        type="file"
                        onChange={(e) => {
                          setProfile((prevState) => {
                            return {
                              ...prevState,
                              profilePicture: e.target.files[0],
                            };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group controlId="video-title">
                  <Row>
                    <Col>
                      <Form.Label>Cover Picture</Form.Label>
                      <Form.File
                        type="file"
                        onChange={(e) => {
                          setProfile((prevState) => {
                            return {
                              ...prevState,
                              coverPhoto: e.target.files[0],
                            };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col> */}
            </Row>
          </div>
          <div className="osahan-area text-center mt-3">
            <Button variant="outline-primary" onClick={submissionHandler}>
              Save Changes
            </Button>
          </div>
        </Col>
      </Row>
      {/* <Terms /> */}
    </React.Fragment>
  );
}
