import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SectionHeader from '../Core/SectionHeader';
import TopCategoriesSlider from './TopCategoriesSlider';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import ContentPlaceHolderList from 'components/ContentLoader';

function CategorySlider({
  sortCondition,
  setSortCondition,
  list,
  isLoading,
  isSingleCategory = false,
}) {
  return (
    <div className="top-category section-padding mb-4">
      <Row>
        <Col md={12}>
          <SectionHeader
            sortCondition={sortCondition}
            setSortCondition={setSortCondition}
            heading="Sub Categories"
            noIconLabel
            icon={faEllipsisH}
            noSortFilter
          />
        </Col>

        <Col md={12}>
          {isLoading ? (
            <ContentPlaceHolderList type="User" />
          ) : (
            <TopCategoriesSlider
              list={list}
              isSingleCategory={isSingleCategory}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default CategorySlider;
