/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import userEngagementActions from 'redux/middleware/userEngagement';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getProfilePicture } from 'utils/helpers';

export default function AuthorBox({
  subscriberCount,
  imgAlt = '',
  channelHref,
  channelName,
  userId,
  isFollow,
  setFollowUnFollowToggle,
}) {
  const loginDetails = JSON.parse(localStorage.getItem('__tmw_user'));

  const followHandler = async () => {
    if (loginDetails?.token) {
      try {
        const apiResponse = await userEngagementActions.followUser(
          loginDetails?.token,
          channelHref
        );
        if (apiResponse.status) {
          const { data } = apiResponse;
          setFollowUnFollowToggle();
        } else {
        }
      } catch (error) {}
    } else {
      // toast.error('Please Sign in to follow');
    }
  };
  const unFollowHandler = async () => {
    try {
      const apiResponse = await userEngagementActions.removeFollowing(
        loginDetails?.token,
        channelHref
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setFollowUnFollowToggle();
        // setOnCloseModal(true);
        // setRegisterOpen(false);
      } else {
      }
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <div className="single-video-author box mb-3">
        <div className="float-right">
          {loginDetails?.userInfo?._id !== channelHref &&
            loginDetails?.token && (
              <Button
                variant="danger"
                onClick={isFollow ? unFollowHandler : followHandler}
              >
                {isFollow ? `Following` : 'Follow'}{' '}
                {/* <strong>{subscriberCount?.length}</strong> */}
              </Button>
            )}

          {/* notification */}
          {/* <Button variant="outline-danger">
            <FontAwesomeIcon icon={faBell} />
          </Button> */}
        </div>
        <img
          className="img-fluid"
          // src={imgSrc}
          src={getProfilePicture('')}
          alt={imgAlt}
        />
        <p>
          <Link to={`/profile/${userId}`}>
            <a href={channelHref} className="text-danger-custom">
              <strong>{channelName}</strong>
            </a>{' '}
          </Link>
          {/* verified icon */}
          {/* {verified ? <VerifiedTooltip /> : ''} */}
        </p>
        <small>{subscriberCount?.length} followers</small>
      </div>
    </React.Fragment>
  );
}
