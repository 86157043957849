import React from 'react';
import Slider from 'react-slick';
import Slide from '../Core/Slide';
// import categoryImage from 'assets/img/catIcon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faCircle,
  faIcons,
  faMusic,
  faTrophy,
  faTheaterMasks,
  faPallet,
} from '@fortawesome/free-solid-svg-icons';

function SampleNextArrow(props) {
  return (
    <button
      className="custom-slick-btn custom-slick-next"
      onClick={props.onClick}
    >
      <span>
        <FontAwesomeIcon
          icon={faAngleRight}
          mask={faCircle}
          transform="shrink-7"
        />
      </span>
    </button>
  );
}

function SamplePrevArrow(props) {
  return (
    <button
      className="custom-slick-btn custom-slick-prev"
      onClick={props.onClick}
    >
      <FontAwesomeIcon
        icon={faAngleLeft}
        mask={faCircle}
        transform="shrink-7"
      />
    </button>
  );
}

export default function TopCategoriesSlider({ list }) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const [subCategories, setSubCategories] = React.useState([]);

  React.useEffect(() => {
    let categoryList = [];
    let tempList = [];
    tempList =
      list &&
      list?.map(({ subCategories, name }) =>
        subCategories?.map((subCat) => ({
          name: subCat.name,
          categoryName: name,
          id: subCat?._id,
        }))
      );

    if (tempList && tempList.length) {
      for (let index = 0; index < tempList.length; index++) {
        const elements = tempList[index];
        categoryList = [...categoryList, ...elements];
      }

      setSubCategories(
        categoryList.sort((a, b) => (a?.name > b?.name ? 1 : -1))
      );
    }
  }, [list]);

  const getCategoryIcon = (category) => {
    if (category === 'Performing Arts') {
      return faTheaterMasks;
    }
    if (category === 'Visual Arts') {
      return faPallet;
    }
    if (category === 'Music') {
      return faMusic;
    }
    if (category === 'Sports') {
      return faTrophy;
    }
    return faIcons;
  };

  return (
    <Slider {...settings}>
      {subCategories &&
        subCategories.length &&
        subCategories.map((item, index) => (
          <Slide
            key={index}
            href={`/search?sub_cat=${item?.name?.replaceAll(
              ' / ',
              '-'
            )}&sub_cat_id=${item?.id}`}
            imgSrc={getCategoryIcon(item.categoryName)}
            label={item.name}
            categoryName={String(item.categoryName).replace(' ', '')}
          />
        ))}
    </Slider>
  );
}
