import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './styles.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import FourZeroFour from '../../components/FourZeroFour/FourZeroFour';
import Login from './Login';
import Signup from './Signup';
import ForgetPassword from './ForgetPassword';
import Invitation from './Invitation';
import ResetPassword from './ResetPasword';
import Carousel from './components/Carousel';
import { ToastContainer } from 'react-toastify';

const Auth = () => {
  return (
    <Switch>
      <Route path="/auth/:link">
        <section className="login-main-wrapper">
          <ToastContainer />
          <Container fluid className="pl-0 pr-0">
            <Row noGutters>
              <Carousel />
              <Switch>
                <Route path="/auth/login" component={Login} />
                {/* <Route path="/auth/register/:id/:id2" component={Signup} /> */}
                <Route path="/auth/register/:id" component={Signup} />
                <Route path="/auth/register" component={Signup} />
                <Route
                  path="/auth/forget-password"
                  component={ForgetPassword}
                />
                <Route
                  path="/auth/reset-password/:id/:token"
                  component={ResetPassword}
                />
                <Route path="/auth/invitation" component={Invitation} />
                <Redirect to="/auth/login" />
              </Switch>
            </Row>
          </Container>
        </section>
      </Route>
      <Route path="*" component={FourZeroFour} />
    </Switch>
  );
};

export default Auth;
