/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import './Sidebar.scss';
import NavItem from './NavItem';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  faHome,
  faIcons,
  faMusic,
  faTrophy,
  faTheaterMasks,
  faPallet,
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import resourceConfiguration from 'redux/middleware/resourceConfiguration';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThinFooter from 'components/Footer/ThinFooter';
import { Facebook, Instagram, Twitter } from '@mui/icons-material';

const { width } = window.screen;

const Sidebar = ({ props }) => {
  const location = useLocation();
  const paths = location.pathname.split('/');

  const sidebarClass = props.showMenu
    ? 'sidebar navbar-nav toggled'
    : 'sidebar navbar-nav non-toggled';

  const dispatch = useDispatch();

  const [allCategories, setAllCategory] = React.useState();
  const apiCall = async () => {
    try {
      const apiResponse = await resourceConfiguration.getAllCategories();
      if (apiResponse.status) {
        const { data } = apiResponse;
        dispatch(setAllCategory(data));
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };

  useEffect(() => {
    apiCall();
  }, []);

  const getSideBarIcon = (category) => {
    if (category === 'Performing Arts') {
      return faTheaterMasks;
    }
    if (category === 'Visual Arts') {
      return faPallet;
    }
    if (category === 'Music') {
      return faMusic;
    }
    if (category === 'Sports') {
      return faTrophy;
    }
    return faIcons;
  };

  return (
    <>
      {width < 700 && (
        <>
          <div className="bottomCatTabs">
            {allCategories
              ?.sort((a, b) => (a?.sequenceNo > b?.sequenceNo ? 1 : -1))
              ?.map((post) => (
                <div
                  className={`bt_item navItem-${post.name.replace(' ', '')}`}
                  key={post?.id}
                >
                  <Link to={`/search?cat=${post.name}&cat_id=${post._id}`}>
                    <FontAwesomeIcon
                      icon={getSideBarIcon(post.name)}
                      fixedWidth
                    />
                    <p>{post?.name}</p>
                  </Link>
                </div>
              ))}
          </div>
          <div
            className={`${sidebarClass} ${
              width < 700 ? 'mobile_sidebar ' : ''
            }`}
          >
            <div className="mb_headerInfo">
              <img className="mb_logo" src="/img/logo.png" alt="" />
              <p className="followText">Follow Us</p>
              <div className="social_btn">
                <a
                  href="https://www.facebook.com/talentmyway"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook className="mb_fb" />
                </a>
                <a
                  href="https://www.instagram.com/talentmyway"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram className="mb_instagram" />
                </a>
                <a
                  href="https://twitter.com/talentmyway"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Twitter className="mb_twitter" />
                </a>
              </div>
            </div>
            <div className="otherLinks">
              <Link>About Us</Link>
              <Link>Contact Us</Link>
              <Link>Community Guidelines</Link>
              <Link>Privacy Policy</Link>
              <Link>Terms & Conditions</Link>
            </div>
            <ThinFooter />
          </div>
        </>
      )}

      {width > 700 && (
        <ul className={sidebarClass}>
          {/* <NavItem
        href="/"
        faIcon={faHome}
        label="Home"
        active={location.pathname === '/'}
        /> */}

          {allCategories
            ?.sort((a, b) => (a?.sequenceNo > b?.sequenceNo ? 1 : -1))
            ?.map((post) => (
              <NavItem
                // href={`/talent/${post.name}/${post._id}`}
                href={`/search?cat=${post.name}&cat_id=${post._id}`}
                faIcon={getSideBarIcon(post.name)}
                key={post._id}
                label={post?.name}
                active={paths[2] === post.name}
              />
            ))}
        </ul>
      )}
    </>
  );
};

export default Sidebar;
