/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { AutoComplete, Input } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './styles.scss';

const AutoCompleteInput = (props) => {
  const { onChangeText, searchResult, searchRef, dataSearch } = props;
  const history = useHistory();
  const [searchText, setSearchText] = useState(dataSearch);

  React.useEffect(() => {
    setSearchText(dataSearch);
  }, [dataSearch]);

  const onSelectItem = (value) => {
    handleChange(value);
    history.push(`/search?keyword=${String(value).replace('#', '')}`);
  };

  const handleChange = (e) => {
    setSearchText(e);
    onChangeText(e);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      history.push(`/search?keyword=${String(dataSearch).replace('#', '')}`);
    }
  };

  const renderTitle = (title) => (
    <span>
      {title}
      {/* <a
        style={{ float: 'right' }}
        href="https://www.google.com/search?q=antd"
        target="_blank"
        rel="noopener noreferrer"
      >
        more
  </a> */}
    </span>
  );

  const renderItem = (title, id, type) => ({
    // value: `${id}*${type}`,
    value: title,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
        {/* <span>
          <UserOutlined />
      </span> */}
      </div>
    ),
  });

  const getOptions = () => {
    if (!searchResult) return [];
    const { hashtags, posts, users } = searchResult;
    let optionList = [];

    /* if (hashtags) {
      const label = renderTitle('HashTags');
      const options = hashtags.map((item) =>
        renderItem(`#${item?.name}`, item?._id, 'HashTags')
      );
      optionList.push({ label, options });
    } */

    if (users) {
      const label = renderTitle('Users');
      const options = users.map((item) =>
        renderItem(item?.Authentication?.UserName, item?._id, 'User')
      );
      optionList.push({ label, options });
    }

    if (posts) {
      const label = renderTitle('Recent Posts');
      const options = posts.map((item) =>
        renderItem(item?.title, item?._id, 'Posts')
      );
      optionList.push({ label, options });
    }

    return optionList;
  };

  return (
    <AutoComplete
      popupClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={500}
      className={'searchBarField'}
      options={getOptions()}
      onSelect={onSelectItem}
      onSearch={handleChange}
      onKeyDown={handleKeyDown}
      value={searchText}
      allowClear
    >
      <Input
        size="large"
        placeholder="Search User, Post, #Hashtags etc"
        style={{ border: 'none' }}
        value={searchText}
      />
    </AutoComplete>
  );
};

export default AutoCompleteInput;
