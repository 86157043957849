import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  userPost: null,
  error: '',
};

const userPostSlice = createSlice({
  name: 'userPost',
  initialState,
  reducers: {
    userPostPending: (state) => {
      state.isLoading = true;
    },
    userPostSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.userPost = payload;
    },
    userPostFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.message;
    },
  },
});

const { reducer, actions } = userPostSlice;

export const { userPostPending, userPostSuccess, userPostFailed } = actions;

export default reducer;
