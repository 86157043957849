import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import CommentBody from './CommentBody';

const CommentBox = (userSinglePost, userDetailsVisited) => {
  return (
    <div className="box  single-video-comment-tabs">
      <Tabs defaultActiveKey="vidoe">
        <Tab eventKey="vidoe" title="vidoe Comments">
          <CommentBody
            userSinglePost={userSinglePost}
            userDetailsVisited={userDetailsVisited}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default CommentBox;
