import { Link } from 'react-router-dom';
import { Tag, TagCloud } from '../Blog/TagCloud';

export default function VideoDescription({ userSinglePost }) {
  return (
    <>
      <div className="single-video-info-content box mb-3">
        <DescriptionBlock heading="About" body={userSinglePost?.desc} />
        <Link
          to={`/search?cat=${
            userSinglePost?.talentCategory[0] &&
            userSinglePost?.talentCategory[0]?.name
          }&cat_id${
            userSinglePost?.talentCategory[0] &&
            userSinglePost?.talentCategory[0]?._id
          }`}
        >
          <DescriptionBlock
            heading="Category"
            body={
              userSinglePost?.talentCategory[0] &&
              userSinglePost?.talentCategory[0]?.name
            }
          />
        </Link>
        <Link
          // to={`/subcategory/all-subcategory-posts/${userSinglePost?.talentSubCategory[0]._id}`}
          to={`/search?sub_cat=${userSinglePost?.talentSubCategory[0]?.name?.replaceAll(
            ' / ',
            '-'
          )}&sub_cat_id=${userSinglePost?.talentSubCategory[0]?._id}`}
        >
          <DescriptionBlock
            heading="Sub Category"
            body={
              userSinglePost?.talentSubCategory[0] &&
              userSinglePost?.talentSubCategory[0]?.name
            }
          />
        </Link>

        <TagCloud heading="Tags">
          {userSinglePost?.hashTags.map((hashTag, i) => (
            <Tag name={hashTag} key={i} />
          ))}
        </TagCloud>
      </div>
    </>
  );
}

function DescriptionBlock({ heading, body }) {
  return (
    <>
      <h6>{heading}</h6>
      <p>{body}</p>
    </>
  );
}
