import React, { useState, useEffect } from 'react';
import { Button, Container, Col, Row } from 'react-bootstrap';
import SectionHeader from 'components/Core/SectionHeader';
import VideoCard from 'components/Core/VideoCard';
import ContentWrapper from 'components/Core/ContentWrapper';
import postActions from 'redux/middleware/posts';
import ThinFooter from '../../components/Footer/ThinFooter';
import TopMobileSearch from '../home/components/TopMobileSearch';
// import { toast } from 'react-toastify';
import moment from 'moment';
import {
  getAge,
  getContentThumbnailURL,
  numFormatter,
  toTime,
} from 'utils/helpers';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import ContentPlaceHolderList from 'components/ContentLoader';

function ViewAll() {
  const [sortCondition, setSortCondition] = useState('date');
  const loginDetails = JSON.parse(localStorage.getItem('__tmw_user'));
  const [limit, setLimit] = useState(12);
  const [posts, setPosts] = useState([]);
  const { search } = useLocation();
  const [isLoading, setLoading] = useState(false);
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const [type, setType] = useState('');
  const [contentType, setContentType] = useState('');

  useEffect(() => {
    setType(searchParams.get('type'));
    setContentType(searchParams.get('content').toLowerCase());
  }, [searchParams]);

  const loadMore = () => {
    setLimit(limit + 12);
  };

  const fetchPosts = async () => {
    try {
      let apiResponse;
      setLoading(true);
      switch (contentType) {
        case 'recent':
          apiResponse = await postActions.getRecentPost(sortCondition, limit);
          break;
        case 'featured':
          apiResponse = await postActions.getFeaturedPost(sortCondition, limit);
          break;
        case 'ambassador':
          apiResponse = await postActions.getAmbassadorPost(
            sortCondition,
            limit
          );
          break;
        case 'followings':
          apiResponse = await postActions.getUserFollowingPost(
            loginDetails?.token,
            sortCondition,
            limit
          );
          break;
        case 'country':
          apiResponse = await postActions.getPostByCountry(
            sortCondition,
            limit
          );
          break;
        default:
          apiResponse = await postActions.getAllPosts(sortCondition, limit);
          break;
      }

      if (apiResponse.status) {
        const { data } = apiResponse;
        setPosts(data);
        setLoading(false);
      } else {
        setLoading(false);
        // toast.error(apiResponse?.message);
      }
    } catch (error) {
      setLoading(false);
      // toast.error('Something went wrong, please try again later');
    }
  };

  useEffect(() => {
    if (type != '' && type === 'post') {
      fetchPosts();
    }
  }, [limit, sortCondition, type, contentType]);

  return (
    <ContentWrapper>
      <Container fluid className="pb-0">
        <TopMobileSearch />
        <Row>
          <Col md={12}>
            <SectionHeader
              heading={name}
              setSortCondition={setSortCondition}
              sortCondition={sortCondition}
              noSortFilter={posts && posts?.length > 0 ? false : true}
            />
          </Col>
          <Col md={12}>
            <Row>
              {posts &&
                posts?.length > 0 &&
                posts?.slice(0, limit).map((post) => {
                  const { userId = null } = post;
                  const {
                    Authentication = null,
                    IsAmbassador = false,
                    DOB = '',
                  } = userId || {};
                  return (
                    <Col
                      xl={2}
                      lg={4}
                      md={5}
                      className="mb-3 contentCard"
                      key={post?._id}
                    >
                      <VideoCard
                        iconOfType={post?.type}
                        postRef={`/watch/${post?._id}`}
                        imgSrc={getContentThumbnailURL(
                          post?.type,
                          Authentication?.UserName,
                          post
                        )}
                        time={
                          post?.type === '63778f613e6ea7415d76345e'
                            ? post?.images?.length
                            : toTime(post?.duration)
                        }
                        flag_id={post?.country}
                        videoUserNameId={post?.userId?._id}
                        videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                        videoTitle={post?.title}
                        videoCategory={
                          post?.talentCategory && post?.talentCategory[0]?.name
                        }
                        videoCategoryId={
                          post?.talentCategory && post?.talentCategory[0]?._id
                        }
                        videoSubCategory={
                          post?.talentSubCategory &&
                          post?.talentSubCategory[0]?.name
                        }
                        videoSubCategoryId={
                          post?.talentSubCategory &&
                          post?.talentSubCategory[0]?._id
                        }
                        views={numFormatter(post?.views)}
                        likes={numFormatter(post?.likeCount)}
                        timeAgo={moment(post.createdAt)?.fromNow()}
                        verified={IsAmbassador}
                        isChild={getAge(DOB) <= 13}
                      />
                    </Col>
                  );
                })}
              {isLoading && <ContentPlaceHolderList />}
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-md-offset-4 text-center">
                    {posts?.length >= 6 && (
                      <div className=" text-center">
                        {limit <= posts.length && (
                          <Button className="text-center" onClick={loadMore}>
                            See more
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <ThinFooter />
    </ContentWrapper>
  );
}
export default ViewAll;
