import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PostViewer from 'components/ContentSlider';

function TalentPost({ heading, userPost, sortCondition, setSortCondition }) {
  const posts = userPost?.filter(
    (singlePost) => singlePost?.talentSubCategory[0]?.name === heading
  );

  if (posts && posts.length < 1) return null;
  return (
    <div className="top-category section-padding mb-4">
      <hr />
      <Row>
        <Col md={12}>
          <PostViewer
            title={heading}
            posts={posts}
            sortCondition={sortCondition}
            setSortCondition={setSortCondition}
            noSortFilter={false}
          />
        </Col>
      </Row>
    </div>
  );
}

export default TalentPost;
