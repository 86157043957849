import React from 'react';
import Row from 'react-bootstrap/Row';
import ContentWrapper from 'components/Core/ContentWrapper';
import ThinFooter from '../../components/Footer/ThinFooter';
import TopMobileSearch from '../home/components/TopMobileSearch';
import Container from 'react-bootstrap/Container';
import ChatApp from 'components/chatApp';

function Messages() {
  return (
    <ContentWrapper>
      <Container className="pb-0 tmw_messageContainer">
        <TopMobileSearch />
        <Row>
          <ChatApp />
        </Row>
      </Container>
      <ThinFooter />
    </ContentWrapper>
  );
}
export default Messages;
