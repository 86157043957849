import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  userInfo: null,
  token: '',
  error: '',
};

const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    loginPending: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.userInfo = payload.userInfo;
      state.token = payload.token;
    },
    loginFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.message;
    },
    signupPending: (state) => {
      state.isLoading = true;
    },
    signupSuccess: (state) => {
      state.isLoading = false;
    },
    signupFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.message;
    },
    invitationPending: (state) => {
      state.isLoading = true;
    },
    invitationSuccess: (state) => {
      state.isLoading = false;
    },
    invitationFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.message;
    },
  },
});

const { reducer, actions } = AuthSlice;

export const {
  loginPending,
  loginSuccess,
  loginFailed,
  invitationPending,
  invitationSuccess,
  invitationFailed,
  signupPending,
  signupSuccess,
  signupFailed,
} = actions;

export default reducer;
