/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import React, { useEffect, useState } from 'react';
import { Mentions } from 'antd';
import searchActions from 'redux/middleware/search';

const MentionCommentMedia = ({
  mentionData,
  hashData,
  setCommentData,
  commentData,
  setMentionData,
  setHashData,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [sortCondition, setSortCondition] = useState('date');
  const loginDetails = JSON.parse(localStorage.getItem('__tmw_user'));
  const [mentions, setMentions] = useState([]);
  const [hashTags, setHashTags] = useState([]);
  const [isIt, setIsIt] = useState();

  const [searchAllByKeywords, setSearchResult] = useState();

  const MOCK_DATA = {
    // '@': ['afc163', 'zombiej', 'yesmeck'],
    '@': searchAllByKeywords?.users?.map((s) => {
      return {
        val: `${s.FirstName} ${s.LastName}`,
        id: s._id,
      };
    }),
    '#': searchAllByKeywords?.hashtags?.map((s) => {
      return {
        val: `${s.name}`,
        id: `${s.name}`,
      };
    }),
    // '#': ['1.0', '2.0', '3.0'],
  };

  const apiCall = async (from) => {
    try {
      const apiResponse = await searchActions.searchContent(
        sortCondition,
        loginDetails?.token,
        10,
        { keyword: from }
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setSearchResult(data);
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };

  const onChange = (value) => {
    setCommentData(value);
  };

  const [prefix, setPrefix] = useState('@');
  const onSearch = (searching, newPrefix) => {
    setIsIt(newPrefix);

    if (searching) {
      apiCall(searching);
    } else {
      apiCall(newPrefix);
    }
    setPrefix(newPrefix);
  };

  useEffect(() => {}, [hashTags, mentions]);

  return (
    <Mentions
      style={{
        width: '100%',
      }}
      onChange={onChange}
      placeholder="input @ to mention people, # to mention tag"
      prefix={['@', '#']}
      onSearch={onSearch}
      value={commentData}
      disabled={!loginDetails?.token}
      rows={3}
      options={(MOCK_DATA[prefix] || []).map((value) => ({
        key: value?.id,
        value: value?.val,
        label: value?.val,
      }))}
      onSelect={(values) => {
        if (isIt === '@') {
          let a = [...mentionData];
          a.push({
            mentionedd: values?.label,
            idOfMentionedd: values?.key,
          });
          setMentions(a);
          setMentionData(a);
        } else if (isIt === '#') {
          let a = [...hashData];
          a.push(values?.key);
          setHashTags(a);
          setHashData(a);
        }
      }}
    />
  );
};
export default MentionCommentMedia;
