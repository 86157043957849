/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  faEye,
  faThumbsDown,
  faThumbsUp,
  faShare,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import userEngagementActions from 'redux/middleware/userEngagement';
import disLikeActions from 'redux/middleware/userEngagement';
import postActions from 'redux/middleware/posts';
import ShareModal from './ShareModal';
import moment from 'moment';
import { numFormatter } from 'utils/helpers';

export default function VideoTitle({
  title,
  videoHref = '#',
  views,
  likes,
  disLikes,
  postId,
  isLike,
  isDisLike,
  publishedOn,
  setPostDetail,
  onPressReport,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [thumbAnimation, setthumbAnimation] = useState(false);
  const loginDetails = JSON.parse(localStorage.getItem('__tmw_user'));
  const singlePostApiCall = async () => {
    try {
      const apiResponse = await postActions.getPostDetail(
        postId,
        loginDetails?.token
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setPostDetail(data);
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };
  const likeHandler = async () => {
    try {
      const apiResponse = await userEngagementActions.likeContent(
        loginDetails?.token,
        postId
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setthumbAnimation(true);
        setTimeout(() => setthumbAnimation(false), 1500);
        singlePostApiCall();
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };
  const disLikeHandler = async () => {
    try {
      const apiResponse = await userEngagementActions.dislikeContent(
        loginDetails?.token,
        postId
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        singlePostApiCall();
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {
      // FIXME
    }
  };

  useEffect(() => {
    // setLikeCount(likes)
    // setdisLikeCount(disLikes)
  }, []);

  return (
    <React.Fragment>
      <ShareModal
        show={show}
        handleClose={handleClose}
        // handleLogout={handleShare}
      />
      <div className="single-video-title box mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h2>
            <a href={videoHref}>{title}</a>
          </h2>
          <div className="d-flex justify-content-center">
            <p className="mb-0">
              <FontAwesomeIcon icon={faEye} /> {numFormatter(views)} views
            </p>
            <p className="mb-0 ml-3">
              Published on {moment(publishedOn).format('DD MMM YYYY')}
            </p>
          </div>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{
            backgroundColor: '#f2f2f2',
            borderRadius: '10px',
            padding: 10,
            color: '#1c1c1c',
            gap: 20,
          }}
        >
          {loginDetails?.token && (
            <p className="mb-0 d-flex align-items-center">
              <button
                className="buttonPanel"
                onClick={isLike ? disLikeHandler : likeHandler}
                style={{
                  color: isLike ? 'blue' : 'black',
                  position: 'relative',
                }}
              >
                <FontAwesomeIcon icon={faThumbsUp} />
              </button>
              <div className="ml-3">{numFormatter(likes)}</div>
            </p>
          )}
          {/* <p className="mb-0 d-flex align-items-center">
            <button
              className="buttonPanel"
              onClick={disLikeHandler}
              style={{ color: isDisLike ? 'red' : 'black' }}
            >
              <FontAwesomeIcon icon={faThumbsDown} />
            </button>
            <div className="ml-3">{disLikes}</div>
        </p> */}
          {loginDetails?.token && (
            <p className="mb-0 d-flex align-items-center">
              <button
                className="buttonPanel"
                onClick={() => onPressReport(true)}
                style={{ color: 'red' }}
              >
                <FontAwesomeIcon icon={faExclamationCircle} />
              </button>
              <div className="ml-3" style={{ color: 'red' }}>
                Report
              </div>
            </p>
          )}
          <p
            className="mb-0 d-flex align-items-center"
            onClick={handleShow}
            style={{ cursor: 'pointer' }}
          >
            <button className="buttonPanel">
              <FontAwesomeIcon icon={faShare} />
            </button>
            <div className="ml-3">Share</div>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
