import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import ThinFooter from 'components/Footer/ThinFooter';
import ContentWrapper from 'components/Core/ContentWrapper';
import resourceConfiguration from 'redux/middleware/resourceConfiguration';
import postActions from 'redux/middleware/posts';
import PostViewer from 'components/ContentSlider';
import UserViewer from 'components/UserSlider';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CategorySlider from 'components/CategorySlider';
// import { toast } from 'react-toastify';
import {
  getAge,
  getContentThumbnailURL,
  getSignedInUser,
  numFormatter,
  toTime,
} from 'utils/helpers';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import PostTypeSlider from 'components/PostTypeSlider';
import SectionHeader from 'components/Core/SectionHeader';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import VideoCard from 'components/Core/VideoCard';
import moment from 'moment';
import ChannelCard from 'components/Core/ChannelCard';

const { width } = window.screen;

const PostTypes = {
  Featured: 'Featured',
  UserFollowing: 'Following',
  SimilarInterest: 'Similar',
  Recent: 'Recent',
  Ambassador: 'Ambassador',
  Country: 'Country',
  Users: 'Users',
};

const Homepage = () => {
  const recordToShow = 20; // width < 700 ? 20 : 7;
  // Logged In User
  const loggedInUser = getSignedInUser();
  const { userInfo = {}, token = null } = loggedInUser || {};

  // Sort Variable
  const [sortCondition, setSortByCondition] = useState('date');
  const [postType, setPostType] = useState(PostTypes.Featured);

  // Follow UnFollow Toggle Variable
  const [followUnFollowToggle, setFollowUnFollowToggle] = useState(true);

  // On Change Sort By Event
  const onHandleSortCondition = (PostType) => (e) => {
    setSortByCondition(e);
    setPostType(PostType);
  };

  // Data Handling Variables
  const [subCategoryList, setSubCategoryList] = React.useState();
  const [recentPosts, setRecentPost] = React.useState();
  const [postsByCountry, setCountryPost] = React.useState();
  const [featuredPost, setFeaturePost] = React.useState([]);
  const [userFollowingPosts, setUserFollowingPost] = React.useState();
  const [allUsers, setAllUsers] = React.useState();
  const [similarPosts, setSimilarPost] = React.useState();
  const [ambassadorPosts, setAmbassadorPosts] = React.useState();

  // Loading Variables For Placeholders
  const [followingLoading, setFollowingLoading] = useState(false);
  const [similarLoading, setSimilarLoading] = useState(false);
  const [ambassadorLoading, setAmbassadorLoading] = useState(false);
  const [featureLoading, setFeatureLoading] = useState(false);
  const [recentLoading, setRecentLoading] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [usersLoading, setUserLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [postTypeList, setPostTypeList] = useState(PostTypes);

  // API Call Functions
  const fetchSubCategoryList = async () => {
    setCategoryLoading(true);
    try {
      const apiResponse = await postActions.getSubCategoryList();
      if (apiResponse.status) {
        const { data } = apiResponse;
        setSubCategoryList(data);
        setCategoryLoading(false);
      } else {
        // toast.error('Something went wrong');
      }
    } catch (error) {
      // toast.error(`Something went wrong - ${error}`);
    }
  };

  const fetchRecentPost = async () => {
    setRecentLoading(true);
    try {
      const apiResponse = await postActions.getRecentPost(
        sortCondition,
        recordToShow
      );

      if (apiResponse?.status) {
        const { data } = apiResponse;
        setRecentPost(data);
        setRecentLoading(false);
      } else {
        // toast.error('Something went wrong');
      }
    } catch (error) {
      // toast.error(`Something went wrong - ${error}`);
    }
  };

  const fetchPostsByCountry = async () => {
    setCountryLoading(true);
    try {
      const apiResponse = await postActions.getPostByCountry(
        sortCondition,
        recordToShow
      );
      if (apiResponse?.status) {
        const { data } = apiResponse;
        setCountryPost(data);
        setCountryLoading(false);
      } else {
        // toast.error('Something went wrong');
      }
    } catch (error) {
      // toast.error(`Something went wrong - ${error}`);
    }
  };

  const fetchFeaturedPost = async () => {
    setFeatureLoading(true);
    try {
      const apiResponse = await postActions.getFeaturedPost(
        sortCondition,
        recordToShow
      );
      if (apiResponse?.status) {
        const { data } = apiResponse;
        setFeaturePost(data);
        setFeatureLoading(false);
      } else {
        // toast.error('Something went wrong');
      }
    } catch (error) {
      // toast.error(`Something went wrong - ${error}`);
    }
  };

  const fetchUserFollowingPosts = async () => {
    setFollowingLoading(true);
    try {
      const apiResponse = await postActions.getUserFollowingPost(
        token,
        sortCondition,
        recordToShow,
        true
      );
      if (apiResponse?.status) {
        const { data } = apiResponse;
        setUserFollowingPost(data);
        setFollowingLoading(false);
      } else {
        setFollowingLoading(false);
        // toast.error('Something went wrong');
      }
    } catch (error) {
      setFollowingLoading(false);
      // toast.error(`Something went wrong - ${error}`);
    }
  };

  const fetchAllUsers = async () => {
    setUserLoading(true);
    try {
      const apiResponse = await resourceConfiguration.getAllUsers(
        token,
        recordToShow
      );
      if (apiResponse?.status) {
        const { data } = apiResponse;
        setAllUsers(data);
        setUserLoading(false);
      } else {
        // toast.error('Something went wrong');
      }
    } catch (error) {
      // toast.error(`Something went wrong - ${error}`);
    }
  };

  const fetchSimilarPosts = async () => {
    setSimilarLoading(true);
    try {
      const apiResponse = await postActions.getSimilarPost(
        sortCondition,
        userInfo?.Talent[0]?.toString(),
        token,
        recordToShow
      );
      if (apiResponse?.status) {
        const { data } = apiResponse;
        setSimilarPost(data);
        setSimilarLoading(false);
      } else {
        setSimilarLoading(false);
        // toast.error('Something went wrong');
      }
    } catch (error) {
      setSimilarLoading(false);
      // toast.error(`Something went wrong - ${error}`);
    }
  };

  const fetchAmbassadorPosts = async () => {
    setAmbassadorLoading(true);
    try {
      const apiResponse = await postActions.getAmbassadorPost(
        sortCondition,
        recordToShow
      );
      if (apiResponse?.status) {
        const { data } = apiResponse;
        setAmbassadorPosts(data);
        setAmbassadorLoading(false);
      } else {
        setAmbassadorLoading(false);
        // toast.error('Something went wrong');
      }
    } catch (error) {
      // toast.error(`Something went wrong - ${error}`);
    }
  };
  // End API Call
  useEffect(() => {
    fetchSubCategoryList();
    fetchFeaturedPost();
    if (width > 700) {
      fetchRecentPost();
      fetchPostsByCountry();

      fetchAmbassadorPosts();
      fetchAllUsers();

      if (loggedInUser) {
        fetchSimilarPosts();
        fetchUserFollowingPosts();
      }
    } else {
      if (!loggedInUser) {
        const { Featured, Recent, Country, Users } = PostTypes;
        const unAuthUser = { Featured, Recent, Country, Users };
        setPostTypeList(unAuthUser);
      }
    }
  }, []);

  /* useEffect(() => {
    fetchAllUsers();
  }, [followUnFollowToggle]); */

  useEffect(() => {
    switch (postType) {
      case PostTypes.UserFollowing:
        fetchUserFollowingPosts();
        break;
      case PostTypes.Recent:
        fetchRecentPost();
        break;
      case PostTypes.Users:
        fetchAllUsers();
        break;
      case PostTypes.Ambassador:
        fetchAmbassadorPosts();
        break;
      case PostTypes.SimilarInterest:
        fetchSimilarPosts();
        break;
      case PostTypes.Country:
        fetchPostsByCountry();
        break;
      default: // Featured Post
        fetchFeaturedPost();
    }
  }, [sortCondition, postType]);

  const renderDesktopView = () => {
    return (
      <React.Fragment>
        <Container fluid className="pb-0">
          <CategorySlider list={subCategoryList} isLoading={categoryLoading} />
          <hr />
          {loggedInUser && (
            <>
              {userFollowingPosts && userFollowingPosts.length > 0 && (
                <React.Fragment>
                  <PostViewer
                    title={'Followings'}
                    isLoading={followingLoading}
                    posts={userFollowingPosts}
                    sortCondition={sortCondition}
                    setSortCondition={onHandleSortCondition(
                      PostTypes.UserFollowing
                    )}
                    viewBy={'followings'}
                  />
                  <hr />
                </React.Fragment>
              )}
              {similarPosts && similarPosts.length > 0 && (
                <React.Fragment>
                  <PostViewer
                    title={'Similar Interest'}
                    isLoading={similarLoading}
                    posts={similarPosts}
                    sortCondition={sortCondition}
                    setSortCondition={onHandleSortCondition(
                      PostTypes.SimilarInterest
                    )}
                  />
                  <hr />
                </React.Fragment>
              )}
            </>
          )}
          <PostViewer
            title={'Featured'}
            posts={featuredPost}
            sortCondition={sortCondition}
            isLoading={featureLoading}
            setSortCondition={onHandleSortCondition(PostTypes.Featured)}
            viewBy={'featured'}
          />
          <hr />
          {(!ambassadorLoading &&
            ambassadorPosts &&
            ambassadorPosts.length > 0) ||
            (ambassadorLoading && (
              <>
                <PostViewer
                  title={'Ambassador Uploads'}
                  posts={ambassadorPosts}
                  sortCondition={sortCondition}
                  isLoading={ambassadorLoading}
                  setSortCondition={onHandleSortCondition(PostTypes.Ambassador)}
                  viewBy={'ambassador'}
                />
                <hr />
              </>
            ))}
          <PostViewer
            title={'Recent'}
            posts={recentPosts}
            sortCondition={sortCondition}
            setSortCondition={onHandleSortCondition(PostTypes.Recent)}
            isLoading={recentLoading}
            viewBy={'recent'}
          />
          <hr />
          <PostViewer
            title={'Country - Most Viewed'}
            posts={postsByCountry}
            sortCondition={sortCondition}
            setSortCondition={onHandleSortCondition(PostTypes.Country)}
            isLoading={countryLoading}
            viewBy={'country'}
          />
          <hr />
          <UserViewer
            sortCondition={sortCondition}
            setSortCondition={onHandleSortCondition(PostTypes.Users)}
            users={allUsers}
            isLoading={usersLoading}
            setFollowUnFollowToggle={() =>
              setFollowUnFollowToggle(!followUnFollowToggle)
            }
          />
        </Container>
        <ThinFooter />
      </React.Fragment>
    );
  };

  const renderMobileView = () => {
    // eslint-disable-next-line no-unused-vars
    const renderPosts = (post) => {
      const { userId = null } = post;
      const {
        Authentication = null,
        IsAmbassador = false,
        DOB = '',
      } = userId || {};
      return (
        <Col className="mb-3 contentCard" key={post?._id}>
          <VideoCard
            iconOfType={post?.type}
            postRef={`/watch/${post?._id}`}
            imgSrc={getContentThumbnailURL(
              post?.type,
              Authentication?.UserName,
              post
            )}
            time={
              post?.type === '63778f613e6ea7415d76345e'
                ? post?.images?.length
                : toTime(post?.duration)
            }
            flag_id={post?.country}
            videoUserNameId={post?.userId?._id}
            videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
            videoTitle={post?.title}
            videoCategory={
              post?.talentCategory && post?.talentCategory[0]?.name
            }
            videoCategoryId={
              post?.talentCategory && post?.talentCategory[0]?._id
            }
            videoSubCategory={
              post?.talentSubCategory && post?.talentSubCategory[0]?.name
            }
            videoSubCategoryId={
              post?.talentSubCategory && post?.talentSubCategory[0]?._id
            }
            views={numFormatter(post?.views)}
            likes={numFormatter(post?.likeCount)}
            timeAgo={moment(post.createdAt)?.fromNow()}
            verified={IsAmbassador}
            isChild={getAge(DOB) <= 13}
          />
        </Col>
      );
    };

    const renderUsers = (item) => {
      return (
        <ChannelCard
          key={item?._id}
          channelHref={item?._id}
          setFollowUnFollowToggle={() =>
            setFollowUnFollowToggle(!followUnFollowToggle)
          }
          isFollow={item?.isFollow}
          imgSrc={getContentThumbnailURL(
            'photo',
            item?.Authentication?.UserName,
            item?.Config?.ProfilePic
          )}
          views={item?.views}
          channelName={item?.FirstName + ' ' + item?.LastName}
          subscriberCount={numFormatter(item?.followers)}
          postCount={numFormatter(item?.posts_count)}
          outline
        />
      );
    };

    // eslint-disable-next-line no-unused-vars
    const getPostData = (postType) => {
      let postList = [];
      switch (postType) {
        case PostTypes.UserFollowing:
          postList = userFollowingPosts || [];
          break;
        case PostTypes.Recent:
          postList = recentPosts || [];
          break;
        case PostTypes.Users:
          postList = allUsers || [];
          break;
        case PostTypes.Ambassador:
          postList = ambassadorPosts || [];
          break;
        case PostTypes.SimilarInterest:
          postList = similarPosts || [];
          break;
        case PostTypes.Country:
          postList = postsByCountry || [];
          break;
        default: // Featured Post
          postList = featuredPost || [];
      }
      return postList;
    };

    const dataList = getPostData(postType);

    return (
      <>
        <Container fluid className="pb-0">
          <CategorySlider list={subCategoryList} isLoading={categoryLoading} />
          <PostTypeSlider
            list={Object.values(postTypeList)}
            isLoading={categoryLoading}
            onPressType={setPostType}
            selectedPostType={postType}
          />
          {/* Content Below */}
          <Row>
            <Col md={12}>
              <SectionHeader
                sortCondition={sortCondition}
                setSortCondition={setSortByCondition}
                heading={postType}
                noIconLabel
                icon={faEllipsisH}
              />
            </Col>
            <Col md={12} className="mb-5">
              {postType !== postTypeList.Users &&
                dataList.length > 0 &&
                dataList.map((item) => renderPosts(item))}
              {postType === postTypeList.Users &&
                dataList.length > 0 &&
                dataList.map((item) => renderUsers(item))}
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  return (
    <ContentWrapper>
      {width < 700 && renderMobileView()}
      {width > 700 && renderDesktopView()}
    </ContentWrapper>
  );
};

export default Homepage;
