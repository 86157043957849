import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Col, Row } from 'react-bootstrap';
import ThinFooter from 'components/Footer/ThinFooter';
import ContentWrapper from 'components/Core/ContentWrapper';
import './styles.scss';
import searchActions from 'redux/middleware/search';
import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Slider, Checkbox, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
import { somethingWrongMessage } from 'config/constant';
import PostViewer from 'components/ContentSlider';
// import HashTagsViewer from 'components/HashTagSlider';
import UserViewer from 'components/UserSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import {
  getAge,
  getContentThumbnailURL,
  numFormatter,
  toTime,
} from 'utils/helpers';
import moment from 'moment';
import VideoCard from 'components/Core/VideoCard';
import {
  HideProcessLoader,
  ShowProcessLoader,
} from 'redux/reducers/resourceSlice';

export default function SearchPostByKeyword() {
  const { width } = window.screen;

  const [followUnFollowToggle, setFollowUnFollowToggle] = useState(true);
  const [sortCondition, setSortCondition] = useState('date');

  const loginDetails = JSON.parse(localStorage.getItem('__tmw_user'));
  const { Country, Talents } = useSelector((state) => state.Profile.Resources);
  const [countryList, setCountryList] = useState([]);
  const [gender, setGender] = useState([]);
  const [ageRange, setAgeRange] = useState([20, 60]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategory] = useState([]);
  const [country, setCountry] = useState(null);
  const { search } = useLocation();
  const [searchAllByKeywords, setSearchResult] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(width < 700 ? false : true);
  const dispatch = useDispatch();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const cat = searchParams.get('cat');
  const cat_id = searchParams.get('cat_id');
  const sub_cat = searchParams.get('sub_cat');
  const sub_cat_id = searchParams.get('sub_cat_id');
  const keyword = searchParams.get('keyword');

  useEffect(() => {
    if (cat) {
      const catList = [cat_id];
      setCategories(catList);
    }
    if (sub_cat) {
      const subCatList = [sub_cat_id];
      setSubCategory(subCatList);
    }
  }, [searchParams]);

  useEffect(() => {
    if (Country?.length) {
      const list = Country.map((item) => ({
        value: item?._id,
        label: item.name,
      }));
      setCountryList(list);
    }
  }, [Country]);

  React.useEffect(() => {
    OnFilterChange();
  }, [gender, categories, subcategories, country, ageRange, keyword]);

  React.useEffect(() => {
    resetFilters();
  }, [keyword]);

  const apiCall = async (payload = null) => {
    dispatch(ShowProcessLoader());
    setSearchLoading(true);
    try {
      const apiResponse = await searchActions.searchContent(
        sortCondition,
        loginDetails?.token,
        30,
        payload
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setSearchResult(data);
        setSearchLoading(false);
        dispatch(HideProcessLoader());
      } else {
        dispatch(HideProcessLoader());
        // toast.error(apiResponse.message);
      }
    } catch (error) {
      dispatch(HideProcessLoader());
      // toast.error(somethingWrongMessage);
    }
  };

  const handleChange = (type) => (event) => {
    switch (type) {
      case 'Gender': {
        let list = [...gender];
        if (event.target?.checked) {
          list.push(event.target.value);
        } else {
          const filterList = list.filter((x) => x !== event.target.value);
          list = filterList;
        }
        setGender(list);
        break;
      }
      case 'Age':
        setAgeRange(event);
        break;
      case 'Category': {
        let list = [...categories];
        list = list.filter((x) => x != null);
        if (event.target?.checked) {
          list.push(event.target.value);
        } else {
          const filterList = list.filter((x) => x !== event.target.value);
          list = filterList;
        }
        setCategories(list);
        break;
      }
      case 'SubCategory': {
        let list = [...subcategories];
        if (event.target?.checked) {
          list.push(event.target.value);
        } else {
          const filterList = list.filter((x) => x !== event.target.value);
          list = filterList;
        }
        setSubCategory(list);
        break;
      }
      default:
        setCountry(event);
        break;
    }
  };

  const OnFilterChange = () => {
    const payload = {
      age: ageRange.toString(),
      country,
      categoryId: categories.toString() || cat_id,
      subCategoryId: subcategories.toString() || sub_cat_id,
      gender: gender.toString(),
      keyword,
    };

    apiCall(payload);
  };

  const resetFilters = () => {
    setGender([]);
    setCategories([]);
    setSubCategory([]);
    setAgeRange([20, 60]);
    setCountry('');
  };

  const getIsChecked = (list, value) => {
    switch (list) {
      case 'Gender':
        return gender.findIndex((x) => x === value) > -1;
      case 'Category':
        return categories.findIndex((x) => x === value) > -1;
      case 'SubCategory':
        return subcategories.findIndex((x) => x === value) > -1;
      default:
        break;
    }
  };

  const renderDesktopSearch = () => {
    const renderPosts = (post) => {
      const { userId = null } = post;
      const {
        Authentication = null,
        IsAmbassador = false,
        DOB = '',
      } = userId || {};
      return (
        <Col md={4} lg={3} className="mb-3 contentCard" key={post?._id}>
          <VideoCard
            iconOfType={post?.type}
            postRef={`/watch/${post?._id}`}
            imgSrc={getContentThumbnailURL(
              post?.type,
              Authentication?.UserName,
              post
            )}
            time={
              post?.type === '63778f613e6ea7415d76345e'
                ? post?.images?.length
                : toTime(post?.duration)
            }
            flag_id={post?.country}
            videoUserNameId={post?.userId?._id}
            videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
            videoTitle={post?.title}
            videoCategory={
              post?.talentCategory && post?.talentCategory[0]?.name
            }
            videoCategoryId={
              post?.talentCategory && post?.talentCategory[0]?._id
            }
            videoSubCategory={
              post?.talentSubCategory && post?.talentSubCategory[0]?.name
            }
            videoSubCategoryId={
              post?.talentSubCategory && post?.talentSubCategory[0]?._id
            }
            views={numFormatter(post?.views)}
            likes={numFormatter(post?.likeCount)}
            timeAgo={moment(post.createdAt)?.fromNow()}
            verified={IsAmbassador}
            isChild={getAge(DOB) <= 13}
          />
        </Col>
      );
    };

    return (
      <Col lg={showSearch ? 10 : 12}>
        <div>
          {/* <HashTagsViewer
          HashTags={searchAllByKeywords?.hashtags}
          sortCondition={sortCondition}
          setSortCondition={setSortCondition}
          isLoading={searchLoading}
                /> */}
        </div>
        <div>
          {searchAllByKeywords?.users &&
            searchAllByKeywords?.users?.length > 0 && (
              <>
                <UserViewer
                  users={searchAllByKeywords?.users}
                  setFollowUnFollowToggle={setFollowUnFollowToggle}
                  followUnFollowToggle={followUnFollowToggle}
                  isLoading={searchLoading}
                />
                <hr />
              </>
            )}
        </div>
        <Row className="m-1">
          {searchAllByKeywords?.posts?.length > 0 &&
            searchAllByKeywords.posts.map((item) => renderPosts(item))}
        </Row>
        <hr />
        <div>
          {searchLoading &&
            searchAllByKeywords?.hashTagsPosts &&
            searchAllByKeywords?.hashTagsPosts.length > 0 && (
              <>
                <PostViewer
                  // from={from}
                  title={'HashTagPosts'}
                  posts={searchAllByKeywords?.hashTagsPosts}
                  sortCondition={sortCondition}
                  setSortCondition={setSortCondition}
                  isLoading={searchLoading}
                />
                <hr />
              </>
            )}
        </div>
        {/* <PostViewer
            // from={from}
            title={'Posts'}
            posts={searchAllByKeywords?.posts}
            sortCondition={sortCondition}
            setSortCondition={setSortCondition}
            isLoading={searchLoading}
            /> */}
      </Col>
    );
  };

  const renderMobileSearch = () => {
    const renderPosts = (post) => {
      const { userId = null } = post;
      const {
        Authentication = null,
        IsAmbassador = false,
        DOB = '',
      } = userId || {};
      return (
        <Col className="mb-3 contentCard" key={post?._id}>
          <VideoCard
            iconOfType={post?.type}
            postRef={`/watch/${post?._id}`}
            imgSrc={getContentThumbnailURL(
              post?.type,
              Authentication?.UserName,
              post
            )}
            time={
              post?.type === '63778f613e6ea7415d76345e'
                ? post?.images?.length
                : toTime(post?.duration)
            }
            flag_id={post?.country}
            videoUserNameId={post?.userId?._id}
            videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
            videoTitle={post?.title}
            videoCategory={
              post?.talentCategory && post?.talentCategory[0]?.name
            }
            videoCategoryId={
              post?.talentCategory && post?.talentCategory[0]?._id
            }
            videoSubCategory={
              post?.talentSubCategory && post?.talentSubCategory[0]?.name
            }
            videoSubCategoryId={
              post?.talentSubCategory && post?.talentSubCategory[0]?._id
            }
            views={numFormatter(post?.views)}
            likes={numFormatter(post?.likeCount)}
            timeAgo={moment(post.createdAt)?.fromNow()}
            verified={IsAmbassador}
            isChild={getAge(DOB) <= 13}
          />
        </Col>
      );
    };

    return (
      <Col lg={showSearch ? 10 : 12}>
        {searchAllByKeywords?.users &&
          searchAllByKeywords?.users?.length > 0 && (
            <div>
              <UserViewer
                users={searchAllByKeywords?.users}
                setFollowUnFollowToggle={setFollowUnFollowToggle}
                followUnFollowToggle={followUnFollowToggle}
                isLoading={searchLoading}
              />
            </div>
          )}
        <div className="mb-5">
          <h6>Media</h6>
          {searchAllByKeywords?.posts?.length > 0 &&
            searchAllByKeywords.posts.map((item) => renderPosts(item))}
        </div>
      </Col>
    );
  };

  return (
    <ContentWrapper>
      <Container fluid className="pb-0">
        {keyword && (
          <Row>
            <Col>
              <p>{` Search Result For "${keyword}"`}</p>
            </Col>
          </Row>
        )}
        {width < 700 && (
          <>
            <div className="mobileFilter">
              <Button size="sm" onClick={() => setShowSearch(!showSearch)}>
                {`${showSearch ? 'Hide' : 'Show'}  Filters`}
                <span>
                  <FontAwesomeIcon icon={faFilter} size="1x" className="pl-2" />
                </span>
              </Button>
            </div>
            <hr />
          </>
        )}
        <Row>
          <Col
            lg={2}
            className={`filterContainer ${showSearch ? '' : 'd-none'}`}
          >
            <div className="filterHeader">
              <p>Filters</p>
              <span onClick={resetFilters} style={{ cursor: 'pointer' }}>
                Clear Filters
              </span>
            </div>
            <div className="FilterOptionContainer">
              <p>Gender</p>
              <Checkbox
                value={'Male'}
                onChange={handleChange('Gender')}
                checked={getIsChecked('Gender', 'Male')}
              >
                Male
              </Checkbox>
              <Checkbox
                value={'Female'}
                onChange={handleChange('Gender')}
                checked={getIsChecked('Gender', 'Female')}
              >
                Female
              </Checkbox>
            </div>
            <div className="FilterOptionContainer">
              <p>Age</p>
              <Slider
                // tooltip={{ open: true }}
                range
                step={1}
                defaultValue={ageRange}
                onAfterChange={handleChange('Age')}
              />
            </div>
            <div className="FilterOptionContainer">
              <p>Country</p>
              <Select
                showSearch
                placeholder="Country"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label.toUpperCase() ?? '').includes(
                    input.toUpperCase()
                  )
                }
                onChange={handleChange('Country')}
                value={country}
                style={{ width: '100%' }}
                allowClear
                options={countryList}
              />
            </div>
            <div className="FilterOptionContainer">
              <div className="filterOptionHeader">
                <p>Talent Category</p>
                {/* <span>See More..</span> */}
              </div>
              {Talents &&
                !cat_id &&
                Talents.map((item) => (
                  <div key={item?._id}>
                    <Checkbox
                      value={item?._id}
                      onChange={handleChange('Category')}
                      checked={getIsChecked('Category', item?._id)}
                    >
                      {item?.name}
                    </Checkbox>
                  </div>
                ))}

              {Talents &&
                cat_id &&
                Talents.filter((x) => x._id === cat_id).map((item) => (
                  <div key={item?._id}>
                    <Checkbox
                      value={item?._id}
                      onChange={handleChange('Category')}
                      checked={getIsChecked('Category', item?._id)}
                    >
                      {item?.name}
                    </Checkbox>
                  </div>
                ))}
            </div>
            <div className="FilterOptionContainer">
              <div className="filterOptionHeader">
                <p>Sub Talent</p>
                {/* <span>See More..</span> */}
              </div>
              {Talents &&
                Talents.map((item) => (
                  <>
                    {!cat_id && <p className="mt-2 mb-2">{item?.name}</p>}
                    {item &&
                      cat_id &&
                      item.subCategories
                        ?.filter((x) => x.categoryId === cat_id)
                        ?.map((subItem) => (
                          <div key={subItem?._id}>
                            <Checkbox
                              value={subItem?._id}
                              onChange={handleChange('SubCategory')}
                              checked={getIsChecked(
                                'SubCategory',
                                subItem?._id
                              )}
                            >
                              {subItem?.name}
                            </Checkbox>
                          </div>
                        ))}

                    {item &&
                      !cat_id &&
                      item.subCategories.map((subItem) => (
                        <div key={subItem?._id}>
                          <Checkbox
                            value={subItem?._id}
                            onChange={handleChange('SubCategory')}
                            checked={getIsChecked('SubCategory', subItem?._id)}
                          >
                            {subItem?.name}
                          </Checkbox>
                        </div>
                      ))}
                  </>
                ))}
            </div>
          </Col>
          {width > 700 && renderDesktopSearch()}
          {width < 700 && renderMobileSearch()}
        </Row>
      </Container>
      {width > 700 && <ThinFooter />}
    </ContentWrapper>
  );
}
