/* eslint-disable no-useless-escape */
import { Row, Col, Form, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthActions from 'redux/middleware/auth';
import { useHistory } from 'react-router-dom';
import resourceConfiguration from 'redux/middleware/resourceConfiguration';

const regex =
  /^(([^()[\]\.,;:\s@\"]+(\.[^()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^()[\]\.,;:\s@\"]+\.)+[^()[\]\.,;:\s@\"]{2,})$/i;

function Invitation() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [purpose, setPurpose] = useState('');
  const [category, setCategory] = useState('');
  const [talent, setTalent] = React.useState([]);

  const history = useHistory();

  // API Call Function To Fetch List
  const fetchTalentList = async () => {
    try {
      const apiResponse = await resourceConfiguration.getTalentList({
        subCategories: 'true',
      });
      if (apiResponse.status) {
        const { data } = apiResponse;
        setTalent(data);
      } else {
        const payload = {
          message: apiResponse.message,
        };
        toast.error(payload.message);
      }
    } catch (error) {
      // toast.error('Something went wrong please try again later.');
    }
  };

  useEffect(() => {
    // Get List of Talents
    fetchTalentList();
  }, []);

  // Submit Invitation
  const submitHandler = async () => {
    if (
      name === '' ||
      email === '' ||
      description === '' ||
      purpose === '' ||
      category === ''
    ) {
      alert('All fields are required to request invitation');
    } else {
      if (regex.test(email)) {
        try {
          const apiResponse = await AuthActions.UserInvitation({
            name,
            email: email.toLowerCase(),
            description,
            purpose,
            category,
          });
          if (apiResponse.status) {
            toast.success(apiResponse.message);
            setTimeout(() => {
              history.push('/');
            }, 5000);
          } else {
            const payload = {
              message: apiResponse.message,
            };
            toast.error(payload.message);
          }
        } catch (error) {
          // toast.error(error.response);
        }
      } else {
        // toast.error('Something went wrong please try again later.');
      }
    }
  };

  return (
    <React.Fragment>
      <Col
        xl={6}
        lg={12}
        md={12}
        sm={12}
        className=" p-5 bg-white full-height leftContainer"
      >
        <div className="signUp-main-left">
          <div className="text-center mb-5 login-main-left-header pt-4">
            <img
              src="/img/favicon.png"
              className="img-fluid favLogo"
              alt="LOGO"
              onClick={() => history.push('/')}
            />
            <h5 className="mt-3 mb-3">Request To Join</h5>
          </div>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. 'James'"
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="e.g. 'james@gmail.com'"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Enter Category</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <option>Select Category</option>
                {talent
                  ?.map(({ subCategories }) => subCategories)
                  ?.flat(1)
                  ?.map((item, i) => (
                    <option value={item._id} key={i}>
                      {item.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Select Purpose</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. 'To express my talent to the world'"
                onChange={(e) => setPurpose(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="e.g 'I want to join this platform to spread my talent to the world'"
                onChange={(e) => setDescription(e.target.value)}
              />
              {/* <textArea /> */}
            </Form.Group>
            <div className="mt-4">
              <Row>
                <Col>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    block
                    onClick={submitHandler}
                  >
                    Request For Invitation
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </Col>
    </React.Fragment>
  );
}

export default Invitation;
