/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import Media from 'react-bootstrap/Media';
import Button from 'react-bootstrap/Button';
import { getContentThumbnailURL, getProfilePicture } from 'utils/helpers';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import userEngagementActions from 'redux/middleware/userEngagement';
import MentionCommentMedia from './MentionCommentMedia';
import reactStringReplace from 'react-string-replace';
// import { toast } from 'react-toastify';

export default function CommentMedia({
  commentAuthor,
  commentAuthorId,
  timeAgo,
  commentBody,
  commentAuthorPP,
  commentMention,
  commentHashTags,
}) {
  // need to fix it..

  const history = useHistory();

  const routeHandler = (routeId) => {
    history.push(`/profile/${routeId}`);
  };

  const checkMention = () => {
    let myComment = commentBody;
    // for (let i = 0; i < commentMention.length; i++) {
    //   if (myComment?.includes(commentMention[i]?.mentionedd)) {
    //     let a = (
    //       <a href={`/profile/${commentMention[i]?.idOfMentionedd}`}>test</a>
    //     );
    //     // myComment = String(myComment).replace(/@Black  Adam/g, a);
    //   }
    // }
    commentHashTags.map((obj) => {
      myComment = reactStringReplace(myComment, `#${obj}`, (match, i) => (
        <span style={{ cursor: 'pointer' }}>{match}</span>
      ));
    });
    commentMention.map((obj) => {
      myComment = reactStringReplace(
        myComment,
        `@${obj.mentionedd}`,
        (match, i) => (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => routeHandler(obj.idOfMentionedd)}
          >
            {match}
          </span>
        )
      );
    });
    return <div>{myComment}</div>;
    // return <div>{myComment}</div>;
  };

  checkMention();

  return (
    <div className="reviews-members">
      <Media>
        <Link to={`/profile/${commentAuthorId}`}>
          <a href="#">
            <img
              className="mr-3"
              src={commentAuthorPP}
              alt="Generic placeholder"
            />
          </a>
        </Link>
        <Media.Body>
          <div className="media-body">
            <div className="reviews-members-header">
              <h6 className="mb-1">
                <Link to={`/profile/${commentAuthorId}`}>
                  <a className="text-black" href="#">
                    {commentAuthor}{' '}
                  </a>{' '}
                </Link>
                <small className="text-gray">{timeAgo}</small>
              </h6>
            </div>
            <div className="reviews-members-body">
              <p>
                {/* {commentBody.slice(0, position)}
                <Link to={`/profile/${commentMention[0]?.idOfMentionedd}`}>
                  {commentBody.slice(
                    position,
                    commentMention[0]?.mentionedd?.length + position
                  )}
                </Link>
                &nbsp;
                {commentBody.slice(
                  commentMention[0]?.mentionedd?.length + position
                )} */}
                {checkMention()}
              </p>
            </div>
            {/* <div className="reviews-members-footer">
                <a className="total-like" href="#">
                  <FontAwesomeIcon icon={faThumbsUp} /> {likesCount}
                </a>{' '}
                <a className="total-like" href="#">
                  <FontAwesomeIcon icon={faThumbsDown} /> {unlikesCount}
                </a>
                <UsersWhoLiked />
              </div> */}
          </div>
        </Media.Body>
      </Media>
    </div>
  );
}

function CustomMedia({ commentCount, userDetails, onSubmitSuccess }) {
  const loginDetails = JSON.parse(localStorage.getItem('__tmw_user'));
  const { id } = useParams();
  const [commentData, setCommentData] = useState('');
  const [mentionData, setMentionData] = useState([]);
  const [hashData, setHashData] = useState('');

  const apiCallComments = async () => {
    try {
      const apiResponse = await userEngagementActions.getComments(id);
      if (apiResponse.status) {
        const { data } = apiResponse;
        // FIXNE: Add Logic Here
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };

  const commentHandler = async () => {
    if (!loginDetails) return; // toast.error('Please login first before commenting');
    try {
      const apiResponse = await userEngagementActions.addComment(
        commentData,
        mentionData,
        hashData.toString(),
        id,
        loginDetails?.token
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        // FIXME: Add Logic Here
        setCommentData('');
        apiCallComments();
        setMentionData([]);
        setHashData([]);
        onSubmitSuccess();
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };

  useEffect(() => {}, [mentionData]);

  return (
    <div className="reviews-members pt-0">
      <Media>
        <a href="#">
          <img
            className="mr-3"
            src={getContentThumbnailURL(
              'photo',
              userDetails?.Authentication?.UserName,
              userDetails?.Config?.ProfilePic
            )}
            alt=""
          />
        </a>
        <Media.Body>
          <div className="form-members-body">
            {/* <Form.Control
              onChange={(e) => setCommentData(e.target.value)}
              as="textarea"
              rows={1}
              placeholder="Add a public comment..."
              value={commentData}
            /> */}
            <MentionCommentMedia
              setCommentData={setCommentData}
              commentData={commentData}
              setMentionData={setMentionData}
              setHashData={setHashData}
              mentionData={mentionData}
              hashData={hashData}
            />
          </div>

          <div className="form-members-footer text-right mt-2">
            <b className="float-left">{commentCount} Comments</b>
            {/* <Button variant="outline-danger" size="sm">
                CANCEL
              </Button> */}
            {/* &nbsp; */}
            {loginDetails?.token && (
              <Button variant="danger" size="sm" onClick={commentHandler}>
                COMMENT
              </Button>
            )}
          </div>
        </Media.Body>
      </Media>
    </div>
  );
}

export { CustomMedia, CommentMedia };
