import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SectionHeader from '../../components/Core/SectionHeader';
import VideoCard from '../../components/Core/VideoCard';
import moment from 'moment';
import {
  getAge,
  getContentThumbnailURL,
  numFormatter,
  toTime,
} from 'utils/helpers';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

const ChannelVideos = ({
  userPost,
  setSortCondition,
  sortCondition,
  userDetails,
  onPressDelete,
}) => {
  const [visible, setVisible] = useState(6);

  // eslint-disable-next-line no-unused-vars
  const [cardDataFeatured, setCardDataFeatured] = useState(userPost);

  const loadMore = () => {
    setVisible(visible + 6);
  };
  const seeLess = () => {
    setVisible(6);
  };

  return (
    <div className="video-block section-padding ">
      <Row>
        <Col md={12}>
          <SectionHeader
            heading="Featured Videos"
            setSortCondition={setSortCondition}
            sortCondition={sortCondition}
          />
        </Col>
        {userPost?.slice(0, visible).map((post) => {
          const { userId = null } = post;
          const {
            Authentication = null,
            IsAmbassador = false,
            DOB = '',
          } = userId || {};
          const isAuthor = userDetails?.userInfo?._id === post?.userId?._id;
          return (
            <Col lg={2} className="mb-3 contentCard" key={post?._id}>
              <VideoCard
                id={post?._id}
                iconOfType={post?.type}
                postRef={`/watch/${post?._id}`}
                imgSrc={getContentThumbnailURL(
                  post?.type,
                  Authentication?.UserName,
                  post
                )}
                time={
                  post?.type === '63778f613e6ea7415d76345e'
                    ? post?.images?.length
                    : toTime(post?.duration)
                }
                flag_id={post?.country}
                videoUserNameId={post?.userId?._id}
                videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                videoTitle={post?.title}
                videoCategory={
                  post?.talentCategory && post?.talentCategory[0]?.name
                }
                videoCategoryId={
                  post?.talentCategory && post?.talentCategory[0]?._id
                }
                videoSubCategory={
                  post?.talentSubCategory && post?.talentSubCategory[0]?.name
                }
                videoSubCategoryId={
                  post?.talentSubCategory && post?.talentSubCategory[0]?._id
                }
                views={numFormatter(post?.views)}
                likes={numFormatter(post?.likeCount)}
                timeAgo={moment(post.createdAt)?.fromNow()}
                verified={IsAmbassador}
                isChild={getAge(DOB) <= 13}
                onDelete={userDetails?.token && isAuthor ? onPressDelete : null}
              />
            </Col>
          );
        })}

        <Col md={12}>
          {cardDataFeatured.length >= 6 && (
            <div className="d-flex justify-content-center text-center">
              {visible < cardDataFeatured.length ? (
                <div>
                  <Button className="text-center" onClick={loadMore}>
                    See more
                  </Button>
                </div>
              ) : (
                visible > cardDataFeatured.length && (
                  <div>
                    <Button className="text-center text-gray" onClick={seeLess}>
                      See Less
                    </Button>
                  </div>
                )
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ChannelVideos;
