import { baseURL, youtube_v3_api_key } from 'config/constant';
import Utils from 'redux/utils';

const getAllCategories = async () => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/category`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getAllSubCategories = async (id) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/subCategory/all/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getAllUsers = async (token, limit) => {
  try {
    const apiOptions = token
      ? {
          endpoint: `${baseURL}/users/?limit=${
            limit ? limit : 10
          }&pageNo=1&noOfPosts=true`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          method: 'GET',
        }
      : {
          endpoint: `${baseURL}/users/?limit=${limit ? limit : 10}&pageNo=1`,
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
        };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getCountries = async () => {
  try {
    const apiOptions = {
      endpoint: `https://commonapi.prime-logics.com/api/country/getCountries`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getCities = async (id) => {
  try {
    const apiOptions = {
      endpoint: `https://commonapi.prime-logics.com/api/city/getCities/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getTalentList = async (payload) => {
  try {
    const { subCategories } = payload;
    const apiOptions = {
      endpoint: `${baseURL}/category${
        subCategories ? '?subCategories=true' : ''
      }`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getYoutubeVideoInfo = async (videoId) => {
  try {
    const apiOptions = {
      endpoint: `https://www.googleapis.com/youtube/v3/videos`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
      params: {
        part: 'snippet',
        key: youtube_v3_api_key,
        id: videoId,
      },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getYoutubeThumbnailBlob = async (Url) => {
  try {
    const apiOptions = {
      endpoint: Url,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const resourceConfiguration = {
  getAllCategories,
  getAllSubCategories,
  getAllUsers,
  getCities,
  getCountries,
  getTalentList,
  getYoutubeVideoInfo,
  getYoutubeThumbnailBlob,
};

export default resourceConfiguration;
