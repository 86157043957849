import './UploadVideo.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Pace from 'react-pace-progress';
import ThinFooter from '../Footer/ThinFooter';
import ContentWrapper from '../Core/ContentWrapper';
import { HeadingWrapper } from '../Core/SectionHeader';
import VideoUploadForm from './VideoUploadForm';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { toTime, youtubeThumbnailURL } from 'utils/helpers';
import { useLocation } from 'react-router-dom';
import { getVideoThumbnail } from 'utils/helpers';
import { Button, Form } from 'react-bootstrap';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';

function SampleNextArrow(props) {
  return (
    <button
      className="custom-slick-btn custom-slick-next"
      onClick={props.onClick}
    >
      <span>
        <FontAwesomeIcon
          icon={faAngleRight}
          mask={faCircle}
          transform="shrink-7"
        />
      </span>
    </button>
  );
}

function SamplePrevArrow(props) {
  return (
    <button
      className="custom-slick-btn custom-slick-prev"
      onClick={props.onClick}
    >
      <FontAwesomeIcon
        icon={faAngleLeft}
        mask={faCircle}
        transform="shrink-7"
      />
    </button>
  );
}

const UploadVideo = () => {
  const location = useLocation();
  const detail = location.state.detail[0];
  const isYoutube = location.state.isYoutube;
  const [videoLength, setVideoLength] = useState(null);
  const [canvasImage, setCanvasImage] = useState('');
  const [videoThumbFile, setVideoThumbFile] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setLoading] = useState(false);
  const [dur, setDur] = useState();
  const [youtubeThumbnail, setYoutubeThumbnail] = useState('');

  // eslint-disable-next-line no-unused-vars
  const resetThumbnail = () => {
    setCanvasImage('');
    setYoutubeThumbnail('');
  };

  const getLength = (video) => {
    const audio = new Audio();
    audio.src = video;
    audio.onloadedmetadata = () => {
      setDur(audio.duration);
      if (audio.duration / 60 > 1) {
        setVideoLength(`${(audio.duration / 60).toFixed(2)} min`);
      } else {
        setVideoLength(`${audio.duration.toFixed(0)} sec`);
      }
    };
    audio.onerror = () => {
      console.log('failed');
    };
  };

  useEffect(async () => {
    if (!isYoutube) {
      if (detail && !isYoutube) {
        console.log('___upload video changed');
        generateThumbnail(detail);
      }
      getLength(URL.createObjectURL(detail));
    }
  }, [detail]);

  useEffect(async () => {
    if (isYoutube) {
      const yt_url = location.state.detail;
      setYoutubeThumbnail(youtubeThumbnailURL(yt_url));
    }
  }, [isYoutube]);

  const generateThumbnail = (file) => {
    /* var _VIDEO = document.querySelector('.react-player video'),
        _CANVAS = document.querySelector('#canvas-element'),
        _CANVAS_CTX = _CANVAS.getContext('2d');

      _VIDEO.addEventListener(
        'loadedmetadata',
        function () {
          this.currentTime = 2;
        },
        false
      );
      _VIDEO.addEventListener('timeupdate', function () {
        if (this.currentTime === 2) {
          _CANVAS_CTX.drawImage(_VIDEO, 0, 0, 640, 480);
          setCanvasImage(_CANVAS.toDataURL());
        }
      }); */

    // resetThumbnail();
    var fileReader = new FileReader();
    fileReader.onload = function () {
      var blob = new Blob([fileReader.result], { type: file.type });
      var url = URL.createObjectURL(blob);
      var video = document.createElement('video');
      var timeupdate = function () {
        if (snapImage()) {
          video.removeEventListener('timeupdate', timeupdate);
          video.pause();
        }
      };
      video.addEventListener('loadeddata', function () {
        if (snapImage()) {
          video.removeEventListener('timeupdate', timeupdate);
        }
      });
      var snapImage = function () {
        var canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
          .getContext('2d')
          .drawImage(video, 0, 0, canvas.width, canvas.height);
        var image = canvas.toDataURL();
        var success = image.length > 100000;
        if (success) {
          setCanvasImage(image);
          // var img = document.createElement('img');
          // img.src = image;
          // document.getElementsByTagName('div')[0].appendChild(img);
          URL.revokeObjectURL(url);
        }
        return success;
      };
      video.addEventListener('timeupdate', timeupdate);
      video.preload = 'metadata';
      video.src = url;
      // Load video in Safari / IE11
      video.muted = true;
      video.playsInline = true;
      video.play();
    };
    fileReader.readAsArrayBuffer(file);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    autoplay: false,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <ContentWrapper>
      <Container fluid className="upload-details">
        <Row>
          <Col lg={12}>
            <HeadingWrapper heading="Upload Details" />
          </Col>

          <Col lg={4}>
            {location?.state?.isYoutube ? (
              <ReactPlayer
                className="react-player"
                url={location?.state?.detail}
                onDuration={(e) => {
                  setVideoLength(e);
                  setDur(e);
                }}
                width="100%"
                height="100%"
                controls={true}
                // playing={true}
              />
            ) : location.state.stateOfFile === 'image' ? (
              <>
                <Slider {...settings} className="sliderImagePosts">
                  {location?.state &&
                    location?.state?.detail &&
                    Array.from(location?.state?.detail).map((item, index) => {
                      return (
                        <img
                          key={index}
                          src={URL.createObjectURL(item)}
                          alt="preview"
                          width="100%"
                          height={94}
                        />
                      );
                    })}
                </Slider>
              </>
            ) : (
              <ReactPlayer
                className="react-player"
                // url={audio}
                url={URL.createObjectURL(location?.state?.detail[0])}
                width="100%"
                height="100%"
                controls={true}
                // playing={true}
                style={{
                  backgroundImage: `url(
                          ${getVideoThumbnail('')}
                        )`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  borderBottomRightRadius: '30px',
                  borderBottomLeftRadius: '30px',
                }}
              />
            )}
          </Col>

          <Col lg={8}>
            <div className="osahan-title">{detail?.name}</div>
            {!location?.state?.isYoutube ? (
              <div className="osahan-size">{`File Size ${
                location?.state?.stateOfFile === 'image'
                  ? `${(detail?.size / 1024).toFixed(2)} KB`
                  : `${(detail?.size / 1024 / 1024).toFixed(
                      2
                    )} MB . Duration ${videoLength}`
              }`}</div>
            ) : (
              videoLength && (
                <div className="osahan-size">{`Duration ${
                  videoLength > 0 && toTime(videoLength)
                }`}</div>
              )
            )}

            <div className="osahan-progress mb-2">
              {/* <div className="paceLoader">
                <Pace />
              </div> */}
            </div>
            <div className="osahan-desc">
              Your{' '}
              {location?.state?.stateOfFile.charAt(0).toUpperCase() +
                location?.state?.stateOfFile.slice(1)}{' '}
              {`is ready, Please fill the required detail to proceed.`}
            </div>
            <hr />
            {location.state.stateOfFile !== 'image' && (
              <Row>
                <Col lg={5}>
                  <p>
                    <b>Auto Generated Thumbnail</b>
                  </p>
                  <canvas
                    id="canvas-element"
                    width={'640px'}
                    height={'480px'}
                    style={{ display: 'none' }}
                  ></canvas>
                  {canvasImage !== '' || isYoutube ? (
                    <img
                      src={
                        !isYoutube
                          ? URL.createObjectURL(
                              dataURLtoFile(canvasImage, 'test')
                            )
                          : youtubeThumbnail
                      }
                      style={{ width: '250px', height: '150px' }}
                    />
                  ) : (
                    <>
                      <p>No Preview Generated For This Content</p>
                    </>
                  )}
                </Col>
                <Col>
                  <span style={{ fontSize: '1.5rem', color: '#d5d5d5' }}>
                    OR
                  </span>
                </Col>
                <Col lg={4}>
                  {location?.state?.stateOfFile !== 'image' && (
                    <Form.Group controlId="formFile" className="mb-3">
                      <div className="upload-btn-wrapper">
                        <Button variant="outline-danger" size="sm">
                          Select Custom Thumbnail
                        </Button>
                        <Form.File
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            setVideoThumbFile(e.target.files[0]);
                          }}
                        />
                      </div>
                    </Form.Group>
                  )}
                  {videoThumbFile && (
                    <div>
                      <img
                        style={{
                          maxHeight: '100px',
                          width: '150px',
                          borderRadius: '3px',
                        }}
                        className="img-fluid"
                        alt=""
                        src={URL.createObjectURL(videoThumbFile)}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <hr />

        <VideoUploadForm
          detail={detail}
          location={location}
          duration={dur}
          autoThumbnail={
            isYoutube
              ? youtubeThumbnail
              : canvasImage !== ''
              ? dataURLtoFile(canvasImage, 'test.png')
              : ''
          }
          isYoutube={isYoutube}
          videoThumbFile={videoThumbFile}
          setVideoThumbFile={setVideoThumbFile}
          setLoading={setLoading}
        />
      </Container>
      <ThinFooter />
    </ContentWrapper>
  );
};

export default UploadVideo;
