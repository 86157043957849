/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { AutoComplete, Input } from 'antd';
import './styles.scss';
import { getContentThumbnailURL } from 'utils/helpers';

const AutoCompleteInput = (props) => {
  const { onChangeText, searchResult, dataSearch, onSelectUser } = props;
  const [searchText, setSearchText] = useState(dataSearch);

  React.useEffect(() => {
    setSearchText(dataSearch);
  }, [dataSearch]);

  const onSelectItem = (e) => {
    const info = e.split('*');
    const user = {
      userId: info[0],
      userName: info[1],
      thumbnail: info[2],
      status: '',
    };
    onSelectUser(user);
    handleChange(info[1]);
  };

  const handleChange = (e) => {
    setSearchText(e);
    onChangeText(e);
  };

  const renderTitle = (title) => <span>{title}</span>;

  const renderItem = (title, id, profile) => ({
    value: `${id}*${title}*${profile}`,
    key: id,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
        {/* <span>
          <UserOutlined />
      </span> */}
      </div>
    ),
  });

  const getOptions = () => {
    if (!searchResult) return [];
    const { users } = searchResult;
    let optionList = [];

    if (users) {
      const label = renderTitle('Users');
      const options = users.map((item) =>
        renderItem(
          item?.Authentication?.UserName,
          item?._id,
          item?.Config?.ProfilePic
        )
      );
      optionList.push({ label, options });
    }

    return optionList;
  };

  return (
    <AutoComplete
      popupClassName="certain-category-search-dropdown chatUserSearch"
      dropdownMatchSelectWidth={500}
      className={'userSearchBarField'}
      options={getOptions()}
      onSelect={onSelectItem}
      onSearch={handleChange}
      // onKeyDown={handleKeyDown}
      value={searchText}
      allowClear
    >
      <Input
        size="large"
        placeholder="New Conversation - Type Username"
        value={searchText}
      />
    </AutoComplete>
  );
};

export default AutoCompleteInput;
