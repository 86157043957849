/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import userEngagementActions from 'redux/middleware/userEngagement';

function UnFollowModal({ show, handleClose, setFollowUnFollowToggle }) {
  const loginDetails = JSON.parse(localStorage.getItem('__tmw_user'));
  const { id } = useParams();
  const unFollowHandler = async () => {
    try {
      const apiResponse = await userEngagementActions.removeFollowing(
        loginDetails?.token,
        id
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        handleClose();
        setFollowUnFollowToggle();
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <Modal.Body>Are you sure to Unfollow?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={unFollowHandler}>
          Remove follow
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UnFollowModal;
