import { baseURL } from 'config/constant';
import Utils from 'redux/utils';

const defaultRecordLimit = 7;

const getAllPosts = async (sortCondition, limit = defaultRecordLimit) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/all?page=1&limit=${limit}&${sortCondition}=true`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getAmbassadorPost = async (sortCondition, limit = defaultRecordLimit) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/ambassadorPosts?pageNo=1&limit=${limit}&${sortCondition}=true`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getRecentPost = async (sortCondition, limit = defaultRecordLimit) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/recentPosts?count=${limit}&${sortCondition}=true`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getFeaturedPost = async (sortCondition, limit = defaultRecordLimit) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/all?page=1&limit=${limit}&${sortCondition}=true`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getSimilarPost = async (
  sorting,
  talentIds,
  token,
  limit = defaultRecordLimit
) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/subCategory?count=${limit}&subCategoryId=${talentIds}&${sorting}=true&mergeAll=true`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getUserFollowingPost = async (
  token,
  sortCondition,
  limit = defaultRecordLimit,
  isUnique = false
) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/userFollowingPosts?limit=${limit}&pageNo=1&${sortCondition}=true${
        isUnique && `&isUnique=${isUnique}`
      }`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getPostByCountry = async (sortCondition, limit = 10) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/country?pageNo=1&limit=${limit}&${sortCondition}=true`,

      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };

    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getSubCategoryList = async () => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/category?subCategories=true`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getPostByCountryId = async (payload, limit = defaultRecordLimit) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/country?pageNo=1&limit=${limit}&country=${payload}`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getPostByCategory = async (
  category,
  sortCondition,
  limit = defaultRecordLimit
) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/category?count=${limit}&categoryId=${category}&${sortCondition}=true`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getPostBySubCategory = async (
  id,
  sortCondition,
  limit = defaultRecordLimit
) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/subCategory?count=${limit}&subCategoryId=${id}&${sortCondition}=true`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getTalentPost = async (
  payload,
  subCategoryId,
  limit = defaultRecordLimit
) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/subCategory?count=${limit}&subCategoryId=${subCategoryId}&userId=${payload}`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getPostDetail = async (payload, token) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/${payload}`,
      headers: token
        ? {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        : {
            'Content-Type': 'application/json',
          },
      method: 'GET',
      data: payload,
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const postActions = {
  getAllPosts,
  getFeaturedPost,
  getSimilarPost,
  getUserFollowingPost,
  getRecentPost,
  getPostByCountry,
  getSubCategoryList,
  getPostByCountryId,
  getPostByCategory,
  getPostBySubCategory,
  getTalentPost,
  getPostDetail,
  getAmbassadorPost,
};

export default postActions;
