import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faCalendarAlt,
  faImage,
  faVideo,
  faMusic,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { getVideoThumbnail } from 'utils/helpers';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const VideoCardList = ({
  iconOfType,
  imgAlt = '',
  videoTitle,
  videoCategory,
  videoCategoryId,
  userName,
  videoSubCategory,
  videoSubCategoryId,
  views,
  // timeAgo,
  time,
  postHref,
  likes,
  imgSrc,
  videoUserNameId,
  active = null,
  verified = false,
}) => {
  const activeStatus = active
    ? 'video-card video-card-list active'
    : 'video-card video-card-list';
  const categoryClass = verified
    ? 'video-page text-success-custom'
    : 'video-page text-danger-custom';

  const addDefaultSrc = (ev) => {
    ev.target.src = getVideoThumbnail('');
  };

  return (
    <div className={activeStatus}>
      <Link to={postHref}>
        <div className="video-card-image forCardListImg">
          {/* <a className="play-icon" href="#">
            <FontAwesomeIcon icon={faPlayCircle} />
          </a> */}
          <LazyLoadImage
            className="img-fluid"
            src={imgSrc}
            // src={getVideoThumbnail('')}
            alt={imgAlt}
            onError={addDefaultSrc}
          />

          {time > 0 && <div className="time">{time}</div>}
        </div>
      </Link>

      <div className="video-card-body userNameVideoCard">
        {/* <SectionHeader dropdownOnly icon={faEllipsisV} noIconLabel /> */}
        <Link to={postHref}>
          <div className="video-title" style={{ color: 'black' }}>
            {videoTitle}
          </div>
        </Link>
        <Link to={`/profile/${videoUserNameId}`}>
          <div className={categoryClass}>{userName}</div>
        </Link>
        <div className="video-view">
          <div>
            <Link
              to={`/search?cat=${videoCategory?.replaceAll(
                ' / ',
                '-'
              )}&cat_id=${videoCategoryId}`}
            >
              {/* adsad, */}
              {videoCategory?.toLowerCase()},
            </Link>
            <Link
              // to={`/subcategory/all-subcategory-posts/${videoSubCategoryId}`}
              to={`/search?sub_cat=${videoSubCategory?.replaceAll(
                ' / ',
                '-'
              )}&sub_cat_id=${videoSubCategoryId}`}
            >
              {videoSubCategory?.toLowerCase()}
            </Link>
          </div>
          {/* {verified ? <VerifiedTooltip /> : <UnverifiedTooltip />} */}
          {/* <div className="iconOfType">
            {iconOfType === '63778f613e6ea7415d76345e' ? (
              <FontAwesomeIcon icon={faImage} fixedWidth />
            ) : iconOfType === '63778f703e6ea7415d763461' ? (
              <FontAwesomeIcon icon={faVideo} fixedWidth />
            ) : (
              <FontAwesomeIcon icon={faMusic} fixedWidth />
            )}
            </div> */}
        </div>
        <div className="video-view">
          <div>
            {views} views, &nbsp;
            {likes} likes &nbsp;
          </div>
          <div className="iconOfType">
            {iconOfType === '63778f613e6ea7415d76345e' ? (
              <FontAwesomeIcon icon={faImage} fixedWidth />
            ) : iconOfType === '63778f703e6ea7415d763461' ? (
              <FontAwesomeIcon icon={faVideo} fixedWidth />
            ) : (
              <FontAwesomeIcon icon={faMusic} fixedWidth />
            )}
          </div>
          {/* <div>
            <FontAwesomeIcon icon={faCalendarAlt} /> {timeAgo} ago
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default VideoCardList;
