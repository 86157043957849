/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import React from 'react';
import './Profile.scss';
import Container from 'react-bootstrap/Container';
import 'react-responsive-modal/styles.css';
import ThinFooter from '../../components/Footer/ThinFooter';
import ContentWrapper from '../../components/Core/ContentWrapper';
import 'react-toastify/dist/ReactToastify.css';
import ProfileHero from './ProfileHero';
import ProfileNav from './ProfileNav';
import ChannelVideos from './ChannelVideos';
import { Modal } from 'react-responsive-modal';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faIcons,
  faIdBadge,
  faMapMarkerAlt,
  faMars,
  faMedal,
  faMusic,
  faPallet,
  faTheaterMasks,
  faTrophy,
  faUsers,
  faVenus,
} from '@fortawesome/free-solid-svg-icons';
import resourceConfiguration from 'redux/middleware/resourceConfiguration';
import postActions from 'redux/middleware/posts';
import { useDispatch, useSelector } from 'react-redux';
import profileActions from 'redux/middleware/profile';
// import { toast } from 'react-toastify';
import userTalentActions from 'redux/middleware/profile';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Followers from './Followers';
import Following from './Following';
import AboutVideos from './AboutVideos';
import { Link } from 'react-router-dom';
import { getAge } from 'utils/helpers';
import ContentUnavailable from '../../assets/img/ContentUnavailable.png';
import userEngagementActions from 'redux/middleware/userEngagement';
import { Modal as BtModal } from 'react-bootstrap';

const Profile = () => {
  const [selectionTab, setSelectionTab] = useState('about');
  const [userDetailsVisited, setUserDetailVisited] = React.useState();
  const { userDetails } = useSelector((state) => state.Profile.UserDetails);
  const [userPost, setUserPost] = React.useState([]);
  const [talentPost, setTalentPost] = React.useState();
  const [userTalentById, setUserTalentId] = React.useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  const loginUserDetails = JSON.parse(localStorage.getItem('__tmw_user'));

  const [talent, setTalent] = React.useState([]);
  const [registerOpen, setRegisterOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [onCloseModal, setOnCloseModal] = useState(false);
  const [talentArray, setTalentArray] = useState([]);
  const [talentArrayId, setTalentArrayId] = useState([]);
  const [sortCondition, setSortByCondition] = useState('date');
  const [followUnFollowToggle, setFollowUnFollowToggle] = useState(true);

  const [reportTypes, setReportTypes] = React.useState(null);
  const [openReportModal, setReportModal] = React.useState(false);
  const [reportReason, setReason] = React.useState(null);
  const [reportDesc, setReportDesc] = React.useState(null);
  const [reportError, setReportError] = React.useState(null);
  const [profileId, setProfileId] = useState(null);
  const [deleteModelVisible, setDeleteModal] = useState(false);
  const [contentId, setContentId] = useState(null);

  const setSortCondition = (e) => {
    setSortByCondition(e);
  };

  const fetchUserPosts = async () => {
    try {
      const apiResponse = await profileActions.getUserPosts(id, sortCondition);
      if (apiResponse.status) {
        const { data } = apiResponse;
        setUserPost(data);
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {
      // FIXME
    }
  };
  const fetchUserDetailsVisited = async () => {
    try {
      const apiResponse = await profileActions.getVisitedUserDetails(
        id,
        loginUserDetails?.token
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setUserDetailVisited(data);
        if (!data?.Talent.length) {
          setRegisterOpen(true);
        }
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };

  const fetchTalent = async () => {
    try {
      const apiResponse = await resourceConfiguration.getTalentList({
        subCategories: 'true',
      });
      if (apiResponse.status) {
        const { data } = apiResponse;
        setTalent(data);
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };

  const apiTalentPost = async () => {
    try {
      const apiResponse = await postActions.getTalentPost(
        id,
        userDetailsVisited?.Talent?.toString()
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setTalentPost(data);
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };

  const selectionHandler = (e, idx) => {
    if (e.className === 'btn btn-secondary btn-block') {
      if (talentArray.length >= 3) {
        // toast.error('You can select only 3 talents');
      } else {
        talentArray.push(e.innerHTML);
        talentArrayId.push(idx);
        setTalentArray([...talentArray]);
        setTalentArrayId([...talentArrayId]);

        e.classList.add('selected');
      }
    } else {
      e.classList.remove('selected');
      var check = talentArray.filter((talen) => talen !== e.innerHTML);
      var checkId = talentArrayId.filter((id) => id !== idx);
      setTalentArray([...check]);
      setTalentArrayId([...checkId]);
    }
  };

  const fetchSpecificUserTalent = async () => {
    try {
      const apiResponse = await profileActions.getUserTalentById(
        talentArrayId,
        id
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        dispatch(setUserTalentId(data));
      } else {
        const payload = {
          message: apiResponse.message,
        };
        // toast.error(payload.message);
      }
    } catch (error) {}
  };

  const submitHandler = async () => {
    try {
      const apiResponse = await userTalentActions.updateUserTalent(
        talentArrayId,
        loginUserDetails?.token
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        const payload = {
          talent: data,
        };
        setOnCloseModal(true);
        setRegisterOpen(false);
      } else {
        const payload = {
          message: apiResponse.message,
        };
        // toast.error(payload.message);
      }
    } catch (error) {}
  };

  const onPressDelete = async () => {
    if (!contentId) return;

    const response = await userEngagementActions.removeContent(
      loginUserDetails?.token,
      contentId
    );
    if (response?.status) {
      toast.success('Content remove successfully');
      handleClose();
      apiTalentPost();
      fetchUserPosts();
    }
  };

  const onDelete = (postId) => {
    console.log('clicked');
    setDeleteModal(true);
    setContentId(postId);
  };

  useEffect(() => {
    fetchUserDetailsVisited();
    fetchTalent();
    fetchSpecificUserTalent();
    if (loginUserDetails?.token) {
      getReportTypes();
    }
  }, [id]);

  // registerOpen, followUnFollowToggle,

  useEffect(() => {
    fetchUserPosts();
  }, [sortCondition, userTalentById]);

  useEffect(() => {
    if (userDetailsVisited?.Talent?.length) {
      apiTalentPost();
    }
  }, [userDetailsVisited]);

  const getCategoryIcon = (category) => {
    if (category === 'Performing Arts') {
      return faTheaterMasks;
    }
    if (category === 'Visual Arts') {
      return faPallet;
    }
    if (category === 'Music') {
      return faMusic;
    }
    if (category === 'Sports') {
      return faTrophy;
    }
    return faIcons;
  };

  const getReportTypes = async () => {
    try {
      const apiResponse = await userEngagementActions.getReportTypes(
        loginUserDetails?.token,
        'user'
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setReportTypes(data);
      } else {
        const payload = {
          message: apiResponse.message,
        };
        console.log(payload);
      }
    } catch (error) {}
  };

  const reportContent = async (payload) => {
    try {
      const apiResponse = await userEngagementActions.submitUserReport(
        loginUserDetails?.token,
        payload
      );
      if (apiResponse.status) {
        toast.success(
          'Reported. Administrator will review it and take action against it.'
        );
        setReportModal(false);
      } else {
        const payload = {
          message: apiResponse.message,
        };
        console.log(payload);
      }
    } catch (error) {}
  };

  const onCloseReportModal = () => setReportModal(!openReportModal);

  const validateReport = () => {
    if (!reportReason || reportReason === 'Select') {
      setReportError('Add Reason');
      return false;
    }
    if (!reportDesc || reportDesc === '') {
      setReportError('Add Description');
      return false;
    }
    setReportError(null);
    return true;
  };

  const onSubmitReport = () => {
    const isValid = validateReport();
    if (isValid) {
      const payload = {
        id,
        type: reportReason,
        desc: reportDesc,
      };
      reportContent(payload);
    }
  };

  const isContentVisible = !userDetailsVisited?.IsBlocked;

  const handleClose = () => {
    setDeleteModal(false);
  };

  return (
    <React.Fragment>
      {!userDetailsVisited?.Talent?.length &&
        loginUserDetails?.userInfo._id === id && (
          <>
            <Modal
              open={registerOpen}
              closeOnOverlayClick={false}
              // onClose={onCloseModal}
              classNames={{ modal: 'talent-wrapper-modal' }}
            >
              <div className="talentheadings-wrappers">
                <h5 className="talentheading">
                  Select your talent - ({talentArrayId.length} out of 3)
                </h5>
                {/* <button className="remove-btns">
            <i className="fas fa-remove " onClick={onCloseModal}></i>
          </button> */}
              </div>
              <div className="talent-popwrap">
                <div
                  className="input-wrapper"
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {talent?.map((t, index) => (
                    <React.Fragment key={index}>
                      <p
                        style={{
                          color: '#666',
                          marginTop: '20px',
                          fontSize: '18px',
                        }}
                      >
                        {t?.name}
                      </p>
                      <div className="btnList">
                        {t?.subCategories.map((subCat, index) => (
                          <React.Fragment key={index}>
                            <Button
                              key={subCat._id}
                              variant={`secondary`}
                              style={{
                                width: 'max-content',
                                padding: '5px 10px',
                              }}
                              block
                              onClick={(e) =>
                                selectionHandler(e.target, subCat._id)
                              }
                            >
                              {subCat.name.slice(0, 1).toUpperCase() +
                                subCat.name.slice(1).toLowerCase()}
                            </Button>
                          </React.Fragment>
                        ))}
                      </div>
                    </React.Fragment>
                  ))}
                  <br />
                </div>

                <div className="savebtnwrappp">
                  <Button variant="primary" size="lg" onClick={submitHandler}>
                    Save
                  </Button>
                </div>
              </div>
            </Modal>
          </>
        )}

      <Modal
        open={openReportModal}
        // closeOnOverlayClick={false}
        onClose={onCloseReportModal}
        classNames={{ modal: 'talent-wrapper-modal' }}
      >
        <div className="talentheadings-wrappers">
          <h5 className="talentheading">Report Content</h5>
          <button className="remove-btns">
            <i className="fas fa-remove " onClick={onCloseModal} />
          </button>
        </div>
        <div className="talent-popwrap">
          <div className="input-wrapper">
            <Row className="mt-4 justify-content-center">
              <Col lg={10} sm={10} className="mb-4">
                <Form.Label>Report Reason</Form.Label>
                <Form.Control
                  as="Select"
                  className="custom-select"
                  // value={reportReason}
                  onChange={(e) => setReason(e.target.value)}
                >
                  <option value={null}>Select</option>
                  {reportTypes &&
                    reportTypes.map((item) => (
                      <option key={item?._id} value={item?._id}>
                        {item?.name}
                      </option>
                    ))}
                </Form.Control>
              </Col>
              <Col lg={10} sm={10}>
                <Form.Group controlId="video-description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    placeholder="Description"
                    value={reportDesc}
                    onChange={(e) => {
                      setReportDesc(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
          {reportError && reportError !== '' && (
            <div className="errorContainer">
              <p>{reportError}</p>
            </div>
          )}
          <div className="savebtnwrappp">
            <Button variant="primary" size="lg" onClick={onSubmitReport}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>

      <BtModal
        show={deleteModelVisible}
        onHide={handleClose}
        centered
        size="md"
      >
        <BtModal.Header closeButton>
          <BtModal.Title>Confirmation</BtModal.Title>
        </BtModal.Header>
        <BtModal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ textAlign: 'center' }}>
            Are you sure to remove content ?{' '}
          </p>
          <Button variant="primary" onClick={onPressDelete}>
            Remove Content
          </Button>
        </BtModal.Body>
      </BtModal>

      <ContentWrapper className=" single-channel-page">
        {isContentVisible ? (
          <>
            <ProfileHero
              coverImg={''}
              profileImg={''}
              userInfo={userDetailsVisited}
            />
            <ProfileNav
              userInfo={userDetailsVisited}
              loggedInUserInfo={userDetails}
              selectionTab={selectionTab}
              setSelectionTab={setSelectionTab}
              setFollowUnFollowToggle={() =>
                setFollowUnFollowToggle(!followUnFollowToggle)
              }
              onPressReport={setReportModal}
            />
            <Container fluid>
              {selectionTab === 'about' && (
                <Row>
                  <Col lg={3} md={3}>
                    <Card className="profileIntro">
                      <Card.Body>
                        {userDetailsVisited?.Description && (
                          <div style={{ display: 'flex' }}>
                            <h6 className="text-dark icon">
                              <FontAwesomeIcon icon={faIdBadge} fixedWidth />
                            </h6>
                            <div>
                              <p>
                                {userDetailsVisited?.Description !== 'undefined'
                                  ? userDetailsVisited?.Description
                                  : 'N/A'}
                              </p>
                            </div>
                          </div>
                        )}

                        <div style={{ display: 'flex' }}>
                          <h6 className="text-dark icon">
                            <FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth />
                          </h6>
                          <p>
                            {userDetailsVisited?.Country} ,{' '}
                            {userDetailsVisited?.City}
                          </p>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <h6 className="text-dark icon">
                            <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                          </h6>
                          <p>{userDetailsVisited?.Authentication?.Email}</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <h6 className="text-dark icon">
                            {userDetailsVisited?.Gender === 'Male' ? (
                              <FontAwesomeIcon icon={faVenus} fixedWidth />
                            ) : (
                              <FontAwesomeIcon icon={faMars} fixedWidth />
                            )}
                          </h6>
                          <p>
                            {userDetailsVisited?.Gender} -
                            {getAge(userDetailsVisited?.DOB)} years old
                          </p>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <h6 className="text-dark icon">
                            <FontAwesomeIcon icon={faUsers} fixedWidth />
                          </h6>
                          <p>{userDetailsVisited?.followers} Followers</p>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <h6 className="text-dark icon">
                            <FontAwesomeIcon icon={faUsers} fixedWidth />
                          </h6>
                          <p>{userDetailsVisited?.followings} Following</p>
                        </div>
                        {userTalentById?.length > 0 && (
                          <div style={{ display: 'flex' }}>
                            <h6 className="text-dark icon">
                              <FontAwesomeIcon icon={faMedal} fixedWidth />
                            </h6>
                            <p>
                              {userTalentById?.map((singleTalent, index) => (
                                <React.Fragment key={index}>
                                  <Link
                                    to={`/sub-talent/${singleTalent?.name?.replaceAll(
                                      ' / ',
                                      '-'
                                    )}/${singleTalent?._id}`}
                                  >
                                    <Button
                                      variant="light"
                                      className="talentTags"
                                    >
                                      {singleTalent?.name}

                                      <FontAwesomeIcon
                                        icon={getCategoryIcon(
                                          singleTalent?.categoryId?.name
                                        )}
                                        fixedWidth
                                        className={`categoryIcon-${singleTalent?.categoryId?.name}`}
                                        size="1x"
                                      />
                                    </Button>
                                  </Link>
                                </React.Fragment>
                              ))}
                            </p>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    {talentPost?.map((item, index) => (
                      <React.Fragment key={index}>
                        <Card className="mb-3">
                          <Card.Body>
                            <AboutVideos
                              // heading={Object.getOwnPropertyNames(item)[0]}
                              heading={
                                userTalentById?.filter(
                                  (talentSingle) =>
                                    talentSingle?._id ===
                                    Object.getOwnPropertyNames(item)[0]
                                )[0] &&
                                userTalentById?.filter(
                                  (talentSingle) =>
                                    talentSingle?._id ===
                                    Object.getOwnPropertyNames(item)[0]
                                )[0].name
                              }
                              userPost={Object.values(item)[0].posts}
                              userDetails={loginUserDetails}
                              onPressDelete={onDelete}
                            />
                          </Card.Body>
                        </Card>
                      </React.Fragment>
                    ))}
                  </Col>
                </Row>
              )}
              {selectionTab === 'videos' && (
                <ChannelVideos
                  userPost={userPost}
                  setSortCondition={setSortCondition}
                  sortCondition={sortCondition}
                  userDetails={loginUserDetails}
                  onPressDelete={onDelete}
                />
              )}
              {selectionTab === 'followers' && (
                <Followers
                  setFollowUnFollowToggle={setFollowUnFollowToggle}
                  followUnFollowToggle={followUnFollowToggle}
                  userDetails={userDetailsVisited}
                />
              )}
              {selectionTab === 'following' && (
                <Following
                  setFollowUnFollowToggle={setFollowUnFollowToggle}
                  userDetails={userDetailsVisited}
                />
              )}
            </Container>
          </>
        ) : (
          <Row>
            <div className="ContentValidationContainer">
              <img src={ContentUnavailable} alt={'unavailable'} />
            </div>
          </Row>
        )}
      </ContentWrapper>

      <ThinFooter />
    </React.Fragment>
  );
};

export default Profile;
