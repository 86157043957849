/* eslint-disable no-unused-vars */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import './styles.scss';

const VideoContentPlaceholder = (props) => (
  <ContentLoader
    width={450}
    height={150}
    viewBox="0 0 450 400"
    backgroundColor="#f0f0f0"
    foregroundColor="#dedede"
    {...props}
  >
    <rect x="43" y="304" rx="4" ry="4" width="271" height="9" />
    <rect x="44" y="323" rx="3" ry="3" width="119" height="6" />
    <rect x="42" y="77" rx="10" ry="10" width="388" height="217" />
  </ContentLoader>
);

const UserContentPlaceholder = (props) => {
  const { width } = window.screen;
  if (width < 700) return null;
  return (
    <ContentLoader
      speed={2}
      width={300}
      height={170}
      viewBox="0 0 400 170"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <circle cx="248" cy="59" r="49" />
      <circle cx="263" cy="66" r="8" />
      <rect x="175" y="120" rx="0" ry="0" width="156" height="8" />
      <rect x="204" y="137" rx="0" ry="0" width="100" height="8" />
      <rect x="248" y="128" rx="0" ry="0" width="0" height="1" />
      <rect x="247" y="126" rx="0" ry="0" width="1" height="8" />
      <rect x="252" y="166" rx="0" ry="0" width="1" height="0" />
    </ContentLoader>
  );
};

const CategoryContentPlaceholder = (props) => (
  <ContentLoader
    width={500}
    height={100}
    viewBox="0 0 500 100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="46" cy="38" r="38" />
  </ContentLoader>
);

const ContentPlaceHolderList = (props) => {
  const { itemCounts = 6, type = 'Video' } = props;
  const list = new Array(itemCounts).fill(false);
  return (
    <Row className="PlaceholderContainer">
      {list.map((item, index) => (
        <Col lg={2} key={index}>
          {type === 'Video' && <VideoContentPlaceholder />}
          {type === 'User' && <UserContentPlaceholder />}
        </Col>
      ))}
    </Row>
  );
};
export default ContentPlaceHolderList;
