import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  processLoading: false,
  isLoading: false,
  Country: null,
  City: null,
  Talents: null,
  error: '',
};

const ResourceSlice = createSlice({
  name: 'Resources',
  initialState,
  reducers: {
    ShowProcessLoader: (state) => {
      state.processLoading = true;
    },
    HideProcessLoader: (state) => {
      state.processLoading = false;
    },
    CountryPending: (state) => {
      state.isLoading = true;
    },
    CountrySuccess: (state, { payload }) => {
      state.isLoading = false;
      state.Country = payload.Country;
    },
    CountryFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.message;
    },
    CityPending: (state) => {
      state.isLoading = true;
    },
    CitySuccess: (state, { payload }) => {
      state.isLoading = false;
      state.City = payload.City;
    },
    CityFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.message;
    },
    TalentPending: (state) => {
      state.isLoading = true;
    },
    TalentSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.Talents = payload.Talents;
    },
    TalentFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.message;
    },
  },
});

const { reducer, actions } = ResourceSlice;

export const {
  CountryPending,
  CountrySuccess,
  CountryFailed,
  CityPending,
  CitySuccess,
  CityFailed,
  TalentPending,
  TalentFailed,
  TalentSuccess,
  ShowProcessLoader,
  HideProcessLoader,
} = actions;

export default reducer;
