import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Col from 'react-bootstrap/Col';
import VideoCard from '../Core/VideoCard';
import Slider from 'react-slick';
import {
  getAge,
  getContentThumbnailURL,
  numFormatter,
  toTime,
} from 'utils/helpers';
import SeeMore from 'pages/home/components/SeeMore';

function SampleNextArrow(props) {
  return (
    <button
      className="custom-slick-btn custom-slick-next"
      onClick={props.onClick}
    >
      <span>
        <FontAwesomeIcon
          icon={faAngleRight}
          mask={faCircle}
          transform="shrink-7"
        />
      </span>
    </button>
  );
}

function SamplePrevArrow(props) {
  return (
    <button
      className="custom-slick-btn custom-slick-prev"
      onClick={props.onClick}
    >
      <FontAwesomeIcon
        icon={faAngleLeft}
        mask={faCircle}
        transform="shrink-7"
      />
    </button>
  );
}
export default function ContentSlider({ posts, viewBy }) {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    autoplay: false,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <Slider {...settings}>
        {posts?.map((post) => {
          const { userId = null } = post;
          const {
            Authentication = null,
            IsAmbassador = false,
            DOB = '',
          } = userId || {};
          return (
            <Col className="mb-3 contentCard" key={post?._id}>
              <VideoCard
                iconOfType={post?.type}
                postRef={`/watch/${post?._id}`}
                imgSrc={getContentThumbnailURL(
                  post?.type,
                  Authentication?.UserName,
                  post
                )}
                time={
                  post?.type === '63778f613e6ea7415d76345e'
                    ? post?.images?.length
                    : toTime(post?.duration)
                }
                flag_id={post?.country}
                videoUserNameId={post?.userId?._id}
                videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                videoTitle={post?.title}
                videoCategory={
                  post?.talentCategory && post?.talentCategory[0]?.name
                }
                videoCategoryId={
                  post?.talentCategory && post?.talentCategory[0]?._id
                }
                videoSubCategory={
                  post?.talentSubCategory && post?.talentSubCategory[0]?.name
                }
                videoSubCategoryId={
                  post?.talentSubCategory && post?.talentSubCategory[0]?._id
                }
                views={numFormatter(post?.views)}
                likes={numFormatter(post?.likeCount)}
                timeAgo={moment(post.createdAt)?.fromNow()}
                verified={IsAmbassador}
                isChild={getAge(DOB) <= 13}
              />
            </Col>
          );
        })}
        <Col className="mb-3 contentCard">
          <SeeMore viewBy={viewBy} />
        </Col>
      </Slider>
    </React.Fragment>
  );
}
