import { defaultPath } from 'config/constant';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  FacebookIcon,
  FacebookShareButton,
  // LinkedinIcon,
  // LinkedinShareButton,
  // PinterestIcon,
  // PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

function ShareModal({ show, handleClose }) {
  const { id } = useParams();
  const handleCopy = () => {
    navigator.clipboard.writeText(`${defaultPath}/watch/${id}`).then(
      function () {},
      function (err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };
  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>Ready to Share?</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', justifyContent: 'space-around' }}>
        <TwitterShareButton
          url={`${defaultPath}/watch/${id}`}
          quote="Share this here"
          hashtag="#portfolio"
        >
          <TwitterIcon size={50} round={true} />
        </TwitterShareButton>
        <FacebookShareButton
          url={`${defaultPath}/watch/${id}`}
          quote="Share this here"
          hashtag="#portfolio"
        >
          <FacebookIcon size={50} round={true} />
        </FacebookShareButton>
        {/* <PinterestShareButton
          url={`${defaultPath}/watch/${id}`}
          quote="Share this here"
          hashtag="#portfolio"
        >
          <PinterestIcon size={50} round={true} />
        </PinterestShareButton> 
        <LinkedinShareButton
          url={`${defaultPath}/watch/${id}`}
          quote="Share this here"
          hashtag="#portfolio"
        >
          <LinkedinIcon size={50} round={true} />
        </LinkedinShareButton>*/}
        <WhatsappShareButton
          url={`${defaultPath}/watch/${id}`}
          quote="Share this here"
          hashtag="#portfolio"
        >
          <WhatsappIcon size={50} round={true} />
        </WhatsappShareButton>
      </Modal.Body>
      <Modal.Body style={{ display: 'flex' }}>
        <Form.Control
          as="textarea"
          rows={2}
          disabled
          value={`${defaultPath}/watch/${id}`}
        />
        <Button variant="primary" onClick={handleCopy}>
          Copy
        </Button>
      </Modal.Body>
      {/* <Modal.Footer>
          <Button variant="primary" onClick={handleCopy}>
            Copy the Link
          </Button>
        </Modal.Footer> */}
    </Modal>
  );
}

export default ShareModal;
