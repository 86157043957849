import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ThinFooter from '../../components/Footer/ThinFooter';
import TopMobileSearch from '../home/components/TopMobileSearch';
import Container from 'react-bootstrap/Container';
import TalentPost from '../home/components/TalentPost';
import postActions from 'redux/middleware/posts';
import ContentWrapper from '../../components/Core/ContentWrapper';
import resourceConfiguration from 'redux/middleware/resourceConfiguration';
import AllSubCategoriesOfCategory from './component/subCategory';
import ContentPlaceHolderList from 'components/ContentLoader';
// import { toast } from 'react-toastify';

export default function CategoryDetails() {
  const [subCategories, setSubCategories] = React.useState([]);
  const [posts, setPosts] = React.useState([]);
  const [sliderLoading, setSliderLoading] = React.useState(false);
  const [sortCondition, setSortCondition] = useState('date');
  const { id } = useParams();

  const getSubCategories = async () => {
    setSliderLoading(true);
    try {
      const apiResponse = await resourceConfiguration?.getAllSubCategories(id);
      if (apiResponse.status) {
        const { data } = apiResponse;
        setSubCategories(data);
        setSliderLoading(false);
      } else {
        setSliderLoading(false);
        // toast.error('Unable to load data, Please refresh your page.');
      }
    } catch (error) {
      // toast.error('Unable to load data, Please refresh your page.');
      console.log(error);
    }
  };

  const getPosts = async () => {
    try {
      const apiResponse = await postActions.getPostByCategory(
        id,
        sortCondition
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setPosts(data);
      } else {
        // toast.error('Unable to load data, Please refresh your page.');
      }
    } catch (error) {
      // toast.error('Unable to load data, Please refresh your page.');
      console.log(error);
    }
  };

  useEffect(() => {
    getPosts();
    getSubCategories();
  }, [id, sortCondition]);

  return (
    <ContentWrapper>
      <Container fluid className="pb-0">
        <TopMobileSearch />
        {sliderLoading ? (
          <ContentPlaceHolderList type="User" />
        ) : (
          <AllSubCategoriesOfCategory allSubCategories={subCategories} />
        )}
        {subCategories?.map((singleSubCategory, index) => (
          <React.Fragment key={index}>
            <TalentPost
              sortCondition={sortCondition}
              setSortCondition={setSortCondition}
              heading={singleSubCategory?.name}
              userPost={
                posts && posts[0][Object.getOwnPropertyNames(posts[0])].posts
              }
            />
          </React.Fragment>
        ))}
      </Container>
      <ThinFooter />
    </ContentWrapper>
  );
}
