import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { HeadingWrapper } from '../../components/Core/SectionHeader';
import VideoCard from '../../components/Core/VideoCard';
import moment from 'moment';
import {
  getAge,
  getContentThumbnailURL,
  numFormatter,
  toTime,
} from 'utils/helpers';

const AboutVideos = ({ userPost, heading, userDetails, onPressDelete }) => {
  const sortLatest =
    userPost &&
    userPost
      .sort((objA, objB) => {
        const d1 = new Date(objA.createdAt).getTime();
        const d2 = new Date(objB.createdAt).getTime();

        return d1 - d2;
      })
      .reverse();
  return (
    <React.Fragment>
      <div className="video-block section-padding ">
        <Row>
          <Col md={12}>
            <HeadingWrapper heading={heading}></HeadingWrapper>
          </Col>
          {sortLatest.length ? (
            sortLatest.map((post) => {
              const { userId = null } = post;
              const {
                Authentication = null,
                IsAmbassador = false,
                DOB = '',
              } = userId || {};
              const isAuthor = userDetails?.userInfo?._id === post?.userId?._id;
              return (
                <Col lg={3} className="mb-3 contentCard" key={post?._id}>
                  <VideoCard
                    iconOfType={post?.type}
                    postRef={`/watch/${post?._id}`}
                    imgSrc={getContentThumbnailURL(
                      post?.type,
                      Authentication?.UserName,
                      post
                    )}
                    time={
                      post?.type === '63778f613e6ea7415d76345e'
                        ? post?.images?.length
                        : toTime(post?.duration)
                    }
                    flag_id={post?.country}
                    videoUserNameId={post?.userId?._id}
                    videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                    videoTitle={post?.title}
                    videoCategory={
                      post?.talentCategory && post?.talentCategory[0]?.name
                    }
                    videoCategoryId={
                      post?.talentCategory && post?.talentCategory[0]?._id
                    }
                    videoSubCategory={
                      post?.talentSubCategory &&
                      post?.talentSubCategory[0]?.name
                    }
                    videoSubCategoryId={
                      post?.talentSubCategory && post?.talentSubCategory[0]?._id
                    }
                    views={numFormatter(post?.views)}
                    likes={numFormatter(post?.likeCount)}
                    timeAgo={moment(post.createdAt)?.fromNow()}
                    verified={IsAmbassador}
                    isChild={getAge(DOB) <= 13}
                    onDelete={
                      userDetails?.token && isAuthor ? onPressDelete : null
                    }
                  />
                </Col>
              );
            })
          ) : (
            <div className="text-center col-md-12">
              <p>No record found</p>
            </div>
          )}
          <Col
            md={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            {/*<Button variant="outline-danger" size="sm">
              View All
          </Button> */}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AboutVideos;
