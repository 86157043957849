import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  userDetails: null,
  error: '',
};

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    userDetailsPending: (state) => {
      state.isLoading = true;
    },
    userDetailsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.userDetails = payload;
    },
    userDetailsFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.message;
    },
  },
});

const { reducer, actions } = userDetailsSlice;

export const { userDetailsPending, userDetailsSuccess, userDetailsFailed } =
  actions;

export default reducer;
