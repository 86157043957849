import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FileUploader } from 'react-drag-drop-files';
import {
  Navbar,
  Button,
  Form,
  InputGroup,
  NavDropdown,
  Dropdown,
  Col,
  Container,
  Modal,
  Row,
  Badge,
} from 'react-bootstrap';
import LogoutModal from './LogoutModal';
import { Modal as RSModal } from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {} from '@fortawesome/react-fontawesome';
import {
  faBars,
  // faPlusCircle,
  faHeadphonesAlt,
  faSignOutAlt,
  faUserCircle,
  faSearch,
  faFileUpload,
  faVideo,
  faImage,
  faSignInAlt,
  faBell,
  faComment,
  faUpload,
  faThumbsUp,
  faUserPlus,
  faComments,
  faCloudUploadAlt,
  faUsers,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import profileActions from 'redux/middleware/profile';
import { getContentThumbnailURL } from '../../utils/helpers';
import ContentWrapper from 'components/Core/ContentWrapper';
import './Navigation.scss';
import { toast } from 'react-toastify';
import {
  userDetailsFailed,
  userDetailsPending,
  userDetailsSuccess,
} from 'redux/reducers/userDetailsSlice';
import AutoCompleteInput from './SearchAutoComplete';
import searchActions from 'redux/middleware/search';
import {
  // somethingWrongMessage,
  supportedAudioFormats,
  supportedImagesFormats,
  supportedVideoFormats,
} from 'config/constant';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { getDatabase, onValue, ref, remove } from 'firebase/database';
import { firebaseApp } from 'firebaseInit';
import userEngagementActions from 'redux/middleware/userEngagement';
import moment from 'moment';

function MyVerticallyCenteredModal(props) {
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [fileError, setFileError] = useState(null);
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [file, setFile] = useState(null);

  const handleChange = (file) => {
    setFile(file);
    props.onHide();
    if (file.length) {
      history.push({
        pathname: '/upload-video',
        state: {
          detail: file,
          isYoutube: false,
          isImage: props?.uploadState === 'image' ? true : false,
          isAudio: props?.uploadState === 'audio' ? true : false,
          stateOfFile: props.uploadState,
        },
      });
    }
  };
  const youtubeUploadHandler = () => {
    if (!youtubeUrl) return;
    toast.error('Please add youtube link');
    if (youtubeUrl.includes('watch?v=') || youtubeUrl.includes('youtu.be/')) {
      setFileError(null);
      props.onHide();
      history.push({
        pathname: '/upload-video',
        state: {
          detail: youtubeUrl,
          isYoutube: true,
          stateOfFile: props.uploadState,
        },
      });
    } else {
      setFileError('Invalid media URL. Use given provided format to upload');
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Upload {props.uploadState}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContentWrapper>
          <Container fluid className="">
            <Row>
              <Col
                md={8}
                className="mx-auto text-center upload-video pt-5 pb-5"
              >
                {fileError && <p className="fileError">{fileError}</p>}
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  multiple={true}
                  types={
                    props.uploadState === 'video'
                      ? supportedVideoFormats
                      : props.uploadState === 'image'
                      ? supportedImagesFormats
                      : supportedAudioFormats
                  }
                >
                  <h1 className="text-primary">
                    <FontAwesomeIcon icon={faFileUpload} />
                  </h1>
                </FileUploader>
                <h4 className="mt-2">
                  Select {props.uploadState} files to upload{' '}
                </h4>
                {/* <p className="land">
                  or drag and drop {props.uploadState} files{' '}
                  {props.uploadState === 'video'
                    ? ', (format should be only mp4)'
                    : props.uploadState === 'audio'
                    ? ', (format should be only mp3)'
                    : ', (format should be only jpg / jpeg / png'}
                </p> */}
                <div className="mt-4">
                  <FileUploader
                    handleChange={handleChange}
                    name="file"
                    multiple={true}
                    types={
                      props.uploadState === 'video'
                        ? supportedVideoFormats
                        : props.uploadState === 'image'
                        ? supportedImagesFormats
                        : supportedAudioFormats
                    }
                  >
                    <div className="btn btn-outline-primary">
                      {/* <Link className="btn btn-outline-primary" to="#"> */}
                      Upload {props.uploadState}
                      {/* </Link> */}
                    </div>
                  </FileUploader>
                </div>
                {props.uploadState === 'video' && (
                  <>
                    <div className="mt-2">Or Youtube Video Url</div>
                    <div className="input-group mb-3 mt-2">
                      <input
                        style={{ height: '40px' }}
                        type="text"
                        className="form-control"
                        placeholder="https://www.youtube.com/watch?v=5_IeZe6GG3o"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setYoutubeUrl(e.target.value)}
                      />
                      <div className="input-group-append">
                        <div
                          style={{ height: '40px' }}
                          className="btn btn-outline-primary"
                          type="button"
                          onClick={() => youtubeUploadHandler()}
                        >
                          Upload
                        </div>
                      </div>
                    </div>
                    <div className="yt_instructions">
                      <p className="yt_instructions_t">
                        Supported URL for YouTube
                      </p>
                      <p className="yt_instructions_s">
                        {`https://youtu.be/<Your Video Id>`}
                      </p>
                      <p className="yt_instructions_s">
                        {`https://www.youtube.com/watch?v=<Your Video Id>`}
                      </p>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const Navigation = ({ props }) => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [uploadState, setUploadState] = useState('');
  const [show, setShow] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [dataSearch, setDataSearch] = useState('');
  const { UserDetails } = useSelector((state) => state.Profile);
  const { userDetails } = UserDetails;
  const userLoggedIn = JSON.parse(localStorage.getItem('__tmw_user'));
  const [searchResult, setSearchResult] = React.useState();
  const history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { pathname } = useLocation();
  const { width } = window.screen;

  const [notifyCount, setNotifyCount] = useState(0);
  const [msgCount, setMsgCount] = useState(0);
  const [notifyList, setNotifyList] = useState([]);
  const [updateList, setUpdateList] = useState([]);
  const [messagesList, setMessagesList] = useState([]);
  const [invitationStats, setInvitationStats] = useState(null);
  const [openInviteModal, setInviteModal] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [inviteError, setInviteError] = useState(null);
  const [email, setEmail] = useState('');

  const database = getDatabase(
    firebaseApp,
    'https://talentmw-898e5-default-rtdb.firebaseio.com/'
  );
  const todoRef = ref(
    database,
    `notify_logs/${userLoggedIn?.userInfo?._id}/notifications`
  );
  const MsgReqRef = ref(
    database,
    `messageRequest/${userLoggedIn?.userInfo?._id}`
  );
  const msgRef = ref(database, `messages/${userLoggedIn?.userInfo?._id}`);

  const searchRef = React.useRef();

  useEffect(() => {
    if (userLoggedIn) {
      onValue(todoRef, (snapshot) => {
        const notifications = snapshot.val();
        const List = [];
        for (let id in notifications) {
          List.push({ id, ...notifications[id] });
        }
        setUpdateList([...List]);
      });
      onValue(MsgReqRef, (snapshot) => {
        const notifications = snapshot.val();
        const List = [];
        for (let id in notifications) {
          List.push({ id, ...notifications[id] });
        }
        setMsgCount(List.length);
      });
      onValue(msgRef, (snapshot) => {
        const messages = snapshot.val();
        if (messages) {
          getMessageRequests(messages);
        }
      });
    }
  }, []);

  React.useEffect(() => {
    const newNotificationCount = updateList.length - notifyList.length;
    if (newNotificationCount > 0) {
      fetchNotifications();
    }
  }, [updateList]);

  useEffect(() => {
    if (userLoggedIn) {
      apiCallUser();
      fetchNotifications();
      getInviteStats();
    }
  }, []);

  useEffect(() => {
    apiCall(dataSearch);
  }, [dataSearch]);

  useEffect(() => {
    if (!pathname?.includes('/search')) {
      setDataSearch('');
    }
  }, [pathname]);

  const onCloseModal = () => {
    setInviteModal(!openInviteModal);
    setInviteError(null);
    setEmail('');
  };

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    handleClose();
    history.push('/');
  };

  const handleMenuToggle = () => {
    props.setShowMenu(!props.showMenu);
  };

  const apiCallUser = async () => {
    dispatch(userDetailsPending());
    try {
      const apiResponse = await profileActions.getUserDetails(
        userLoggedIn?.userInfo?._id
      );
      if (apiResponse?.status) {
        const { data } = apiResponse;
        dispatch(userDetailsSuccess(data));
      } else {
        const payload = {
          message: apiResponse?.message,
        };
        dispatch(userDetailsFailed(payload));
      }
    } catch (error) {
      dispatch(userDetailsFailed(error));
    }
  };

  const getInviteStats = async () => {
    try {
      const apiResponse = await userEngagementActions.getInvitationSent(
        userLoggedIn?.token
      );
      if (apiResponse?.status) {
        const { data } = apiResponse;
        setInvitationStats(data);
      } else {
        const payload = {
          message: apiResponse?.message,
        };
        console.log(payload.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendInvitation = async () => {
    try {
      const apiResponse = await userEngagementActions.invitationSent(
        userLoggedIn?.token,
        email
      );
      if (apiResponse?.status) {
        setInviteError(null);
        getInviteStats();
        setEmail('');
      } else {
        const payload = {
          message: apiResponse?.message,
        };
        setInviteError(payload.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const apiCall = async (keyword) => {
    try {
      const apiResponse = await searchActions.searchContent(
        'date',
        userLoggedIn?.token,
        5,
        { keyword }
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setSearchResult(data);
      } else {
        // toast.error(apiResponse.message);
      }
    } catch (error) {
      // toast.error(somethingWrongMessage);
    }
  };

  const getMessageRequests = async (List) => {
    try {
      const apiResponse = await userEngagementActions.getMessageRequests(
        userLoggedIn?.token
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let message = null;
          if (element?.chatId)
            message = Object.values(List[element?.chatId]).pop();
          const item = {
            ...element,
            ...message,
          };
          data[index] = item;
        }
        setMessagesList(
          data
            .sort((objA, objB) => {
              const d1 = new Date(objA.dt).getTime();
              const d2 = new Date(objB.dt).getTime();

              return d1 - d2;
            })
            .reverse()
        );
      } else {
        // toast.error(apiResponse.message);
      }
    } catch (error) {
      // toast.error(somethingWrongMessage);
    }
  };

  const fetchNotifications = async () => {
    try {
      const apiResponse = await userEngagementActions.getNotifications(
        userLoggedIn?.token,
        userLoggedIn?.userInfo?._id
      );
      if (apiResponse.status) {
        const { data } = apiResponse;

        const filterList =
          data.length && data.filter((x) => x?.isRead === false);

        if (filterList?.length) setNotifyCount(filterList.length);

        setNotifyList(data);
      } else {
        setNotifyList([]);
      }
    } catch (error) {
      setNotifyList([]);
    }
  };

  const onPressSentInvite = () => {
    if (email !== '') sendInvitation();
  };

  const readAllNotifications = async () => {
    try {
      const NotificationIds = notifyList.map((item) => item['_id']);

      const apiResponse = await userEngagementActions.readAllNotifications(
        userLoggedIn?.token,
        NotificationIds.toString()
      );
      if (apiResponse.status) {
        const database = getDatabase(
          firebaseApp,
          'https://talentmw-898e5-default-rtdb.firebaseio.com/'
        );
        const todoRef = ref(
          database,
          `notify_logs/${userLoggedIn?.userInfo?._id}/notifications`
        );
        remove(todoRef);
        setNotifyCount(0);
      }
    } catch (error) {
      console.log('Read notification failed');
    }
  };

  const renderNotificationsIcon = (eventId) => {
    let Icon = null;
    switch (eventId) {
      case '003': // Add New Comment
        Icon = faComment;
        break;
      case '002': // Add New Post
        Icon = faUpload;
        break;
      case '004': // Like
        Icon = faThumbsUp;
        break;
      case '001': // FOLLOW
        Icon = faUserPlus;
        break;
      default:
        break;
    }
    return Icon;
  };

  const redirectNotification = (data) => {
    const { eventId, postId = null, userId = null } = data;
    let Url = null;

    switch (eventId) {
      case '003': // Add New Comment
        Url = `watch/${postId}`;
        break;
      case '002': // Add New Post
        Url = `watch/${postId}`;
        break;
      case '004': // Like
        Url = `watch/${postId}`;
        break;
      case '001': // FOLLOW
        Url = `profile/${userId?._id}`;
        break;
      default:
        break;
    }

    if (Url) {
      Url = `${window.location.origin}/${Url}`;
      window.location.href = Url;
    }
  };

  const renderDesktopHeader = () => {
    return (
      <Navbar sticky="top" className="osahan-nav topHeader-bg">
        <Button
          variant="link"
          size="sm"
          className="order-1 order-sm-0 text-secondary"
          id="sidebarToggle"
          onClick={handleMenuToggle}
        >
          <FontAwesomeIcon icon={faBars} />
        </Button>
        <Navbar.Brand className="mr-1">
          <Link to="/">
            <img className="img-fluid themeLogo" src="/img/logo.png" alt="" />
          </Link>
        </Navbar.Brand>
        <Form
          inline
          className={`d-md-inline ml-auto  
          my-2 my-md-0 osahan-navbar-search ${
            showSearch ? 'searchBarMobile' : 'd-none'
          }`}
        >
          <InputGroup className="searchBarInputGroup">
            <AutoCompleteInput
              searchResult={searchResult}
              onChangeText={setDataSearch}
              dataSearch={dataSearch}
              searchRef={searchRef}
            />
            <InputGroup.Append>
              <Button variant="light">
                <Link ref={searchRef} to={`/search?keyword=${dataSearch}`}>
                  <FontAwesomeIcon icon={faSearch} />
                </Link>
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form>
        <ul className="navbar-nav ml-auto ml-md-0 osahan-right-navbar right-nav-tmw">
          <Button
            variant="link"
            size="sm"
            className="text-secondary"
            onClick={() => setShowSearch(!showSearch)}
          >
            <FontAwesomeIcon
              icon={faSearch}
              size="2x"
              className="pl-2 pb-1 mobileSearchIcon"
            />
          </Button>
          {userLoggedIn ? (
            <>
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon
                      icon={faCloudUploadAlt}
                      fixedWidth
                      title="Upload"
                    />{' '}
                    {/* <span className="d-md-inline uploadText">Upload</span> */}
                  </>
                }
                id=""
                className="mx-1 no-arrow uploaderDropdown"
              >
                <NavDropdown.Item
                  href="#"
                  onClick={() => {
                    setModalShow(true);
                    setUploadState('video');
                  }}
                >
                  <FontAwesomeIcon icon={faVideo} fixedWidth /> Video
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#"
                  onClick={() => {
                    setModalShow(true);
                    setUploadState('image');
                  }}
                >
                  <FontAwesomeIcon icon={faImage} fixedWidth /> Image
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#"
                  onClick={() => {
                    setModalShow(true);
                    setUploadState('audio');
                  }}
                >
                  <FontAwesomeIcon icon={faHeadphonesAlt} fixedWidth /> Audio
                </NavDropdown.Item>
              </NavDropdown>

              {/*
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon
                      icon={faComments}
                      size="1x"
                      title="Messages"
                    />
                    <Badge variant="danger" style={{ borderRadius: '10px' }}>
                      {msgCount > 0 ? msgCount : ''}
                    </Badge>
                  </>
                }
                onClick={() => {
                  remove(MsgReqRef);
                  history.push('/messages');
                }}
                // disabled={notifyList.length < 1}
                id=""
                className="mx-1 no-arrow msg_dpdwn"
              />
               <div className="notificationContainer">
                  <>
                    <NavDropdown.Item href="#" onClick={() => null}>
                      <div>
                        <span className="nt_title">
                          <FontAwesomeIcon icon={faComment} fixedWidth />{' '}
                          {'Hissam Hyder'}
                        </span>
                        <p> {'Test Message'}</p>
                        <p className="nt_time">{moment().fromNow()}</p>
                      </div>
                    </NavDropdown.Item>
                    <Dropdown.Divider />
                  </>
              </div> 
              </NavDropdown> */}
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon
                      icon={faComments}
                      size="1x"
                      title="Messages"
                    />
                    <Badge variant="danger" style={{ borderRadius: '10px' }}>
                      {msgCount > 0 ? msgCount : ''}
                    </Badge>
                  </>
                }
                // onClick={readAllNotifications}
                id=""
                className="mx-1 no-arrow"
              >
                <div className="notificationContainer">
                  {messagesList.length > 0 &&
                    messagesList.map((item, index) => (
                      <React.Fragment key={index}>
                        <NavDropdown.Item
                          href="#"
                          onClick={() => {
                            remove(MsgReqRef);
                            history.push(`/messages/${item?.msgId}`);
                          }}
                        >
                          <div>
                            <span className="nt_title">
                              <FontAwesomeIcon icon={faComment} fixedWidth />
                              {` `}
                              {item?.userName}
                            </span>
                            <p>
                              {item?.to ? `~ ${item?.userName}:` : `~ You:`}
                              {item?.text || 'Send message'}
                            </p>
                            <p className="nt_time">
                              {moment(item?.dt).fromNow(false)}
                            </p>
                          </div>
                        </NavDropdown.Item>
                        <Dropdown.Divider />
                      </React.Fragment>
                    ))}
                  <NavDropdown.Item
                    href="#"
                    onClick={() => {
                      remove(MsgReqRef);
                      history.push('/messages');
                    }}
                  >
                    <div>
                      <p className="chat_nav_text">View All Messages</p>
                    </div>
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon
                      icon={faBell}
                      size="1x"
                      title="Notifications"
                    />
                    <Badge variant="danger" style={{ borderRadius: '10px' }}>
                      {notifyCount > 0 ? notifyCount : ''}
                    </Badge>
                  </>
                }
                onClick={readAllNotifications}
                disabled={notifyList.length < 1}
                id=""
                className="mx-1 no-arrow"
              >
                <div className="notificationContainer">
                  {notifyList.length > 0 &&
                    notifyList.map((item, index) => (
                      <React.Fragment key={index}>
                        <NavDropdown.Item
                          href="#"
                          onClick={() => redirectNotification(item)}
                        >
                          <div>
                            <span className="nt_title">
                              <FontAwesomeIcon
                                icon={renderNotificationsIcon(item?.eventId)}
                                fixedWidth
                              />{' '}
                              {` `}
                              {item?.type}
                            </span>
                            <p> {item?.message}</p>
                            <p className="nt_time">
                              {moment(item?.createdAt).fromNow()}
                            </p>
                          </div>
                        </NavDropdown.Item>
                        <Dropdown.Divider />
                      </React.Fragment>
                    ))}
                </div>
              </NavDropdown>
              {/*
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                    <Badge variant="success">7</Badge>
                  </>
                }
                id=""
                className="mx-1 no-arrow"
              >
                <NavDropdown.Item href="#">
                  <FontAwesomeIcon icon={faEdit} fixedWidth /> Action
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  <FontAwesomeIcon icon={faHeadphonesAlt} fixedWidth /> Another
                  Action
                </NavDropdown.Item>
                <Dropdown.Divider />
                <NavDropdown.Item href="#">
                  <FontAwesomeIcon icon={faStar} fixedWidth /> Something else
                  here
                </NavDropdown.Item>
              </NavDropdown> */}

              <NavDropdown
                title={
                  <>
                    <img
                      // src={getProfilePicture(userDetails?.Config?.ProfilePic)}
                      //state sey url daaal do ==>> do the same for video also
                      src={getContentThumbnailURL(
                        'photo',
                        userDetails?.Authentication.UserName,
                        userDetails?.Config?.ProfilePic
                      )}
                      // onError={(event) => {
                      //   event.target.src = { profilePicture };
                      //   event.onerror = null;
                      // }}
                      // onError={() => setImgSrc({ profilePicture })}
                      alt="Avatar"
                      title="Profile"
                    />
                    <span className="d-none d-md-inline">
                      {' '}
                      {userDetails?.Authentication?.UserName}
                      {/* userDetails?.FirstName} {userDetails?.LastName */}
                    </span>
                  </>
                }
                id=""
                className="no-arrow osahan-right-navbar-user user-dropdown-link"
              >
                <Link
                  to={
                    userLoggedIn
                      ? `/profile/${userLoggedIn?.userInfo?._id}`
                      : '#'
                  }
                  className="dropdown-item"
                  role="button"
                >
                  <FontAwesomeIcon icon={faUserCircle} fixedWidth /> My Profile
                </Link>
                <Link
                  to={
                    userLoggedIn
                      ? `/updateprofile/${userLoggedIn?.userInfo?._id}`
                      : '#'
                  }
                  className="dropdown-item"
                  role="button"
                >
                  <FontAwesomeIcon icon={faUserCircle} fixedWidth /> Update
                  Profile
                </Link>
                <Link
                  to={'#'}
                  onClick={onCloseModal}
                  className="dropdown-item"
                  role="button"
                >
                  <FontAwesomeIcon icon={faUsers} fixedWidth /> Invite Friends
                </Link>
                <Dropdown.Divider />
                <Link
                  to="#"
                  onClick={handleShow}
                  className="dropdown-item"
                  rolw="button"
                >
                  <FontAwesomeIcon icon={faSignOutAlt} fixedWidth /> Log out
                </Link>
              </NavDropdown>
            </>
          ) : (
            <>
              <li className="nav-item mx-1 authLink">
                <Link to="/auth/login" className="nav-link">
                  <FontAwesomeIcon icon={faSignInAlt} size="lg" />
                  <span> Sign Up / Login</span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </Navbar>
    );
  };

  const renderMobileHeader = () => {
    return (
      <Navbar sticky="top" className="osahan-nav topHeader-bg">
        <Navbar.Brand className="mr-1 mobile-logo">
          <Link to="/">
            <img className="img-fluid themeLogo" src="/img/logo.png" alt="" />
          </Link>
        </Navbar.Brand>
        <Button
          variant="link"
          size="sm"
          className="order-sm-0 text-secondary"
          id="sidebarToggle"
          onClick={handleMenuToggle}
        >
          <FontAwesomeIcon icon={faBars} size="lg" />
        </Button>
        <ul className="navbar-nav ml-auto ml-md-0 osahan-right-navbar right-nav-tmw">
          {/* <Button
            variant="link"
            size="sm"
            className="text-secondary"
            onClick={() => setShowSearch(!showSearch)}
          >
            <FontAwesomeIcon
              icon={faSearch}
              size="lg"
              className="pl-2 mobileSearchIcon"
            />
    </Button> */}
          {userLoggedIn ? (
            <>
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon
                      icon={faCloudUploadAlt}
                      fixedWidth
                      title="Upload"
                    />{' '}
                    {/* <span className="d-md-inline uploadText">Upload</span> */}
                  </>
                }
                id=""
                className="mx-1 no-arrow"
              >
                <NavDropdown.Item
                  href="#"
                  onClick={() => {
                    setModalShow(true);
                    setUploadState('video');
                  }}
                >
                  <FontAwesomeIcon icon={faVideo} fixedWidth /> Video
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#"
                  onClick={() => {
                    setModalShow(true);
                    setUploadState('image');
                  }}
                >
                  <FontAwesomeIcon icon={faImage} fixedWidth /> Image
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#"
                  onClick={() => {
                    setModalShow(true);
                    setUploadState('audio');
                  }}
                >
                  <FontAwesomeIcon icon={faHeadphonesAlt} fixedWidth /> Audio
                  here
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon
                      icon={faComments}
                      size="1x"
                      title="Messages"
                    />
                    <Badge variant="danger" style={{ borderRadius: '10px' }}>
                      {msgCount > 0 ? msgCount : ''}
                    </Badge>
                  </>
                }
                // onClick={readAllNotifications}
                id=""
                className="mx-1 no-arrow"
              >
                <div className="notificationContainer">
                  {messagesList.length > 0 &&
                    messagesList.map((item, index) => (
                      <React.Fragment key={index}>
                        <NavDropdown.Item
                          href="#"
                          onClick={() => {
                            remove(MsgReqRef);
                            history.push(`/messages/${item?.msgId}`);
                          }}
                        >
                          <div>
                            <span className="nt_title">
                              <FontAwesomeIcon icon={faComment} fixedWidth />
                              {` `}
                              {item?.userName}
                            </span>
                            <p>
                              {item?.from ? `~ ${item?.userName}:` : `~ You:`}
                              {item?.text || 'Send message'}
                            </p>
                            <p className="nt_time">
                              {moment(item?.dt).fromNow()}
                            </p>
                          </div>
                        </NavDropdown.Item>
                        <Dropdown.Divider />
                      </React.Fragment>
                    ))}
                  <NavDropdown.Item
                    href="#"
                    onClick={() => {
                      remove(MsgReqRef);
                      history.push('/messages');
                    }}
                  >
                    <div>
                      <p className="chat_nav_text">View All Messages</p>
                    </div>
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon={faBell} size="1x" />
                    <Badge variant="danger" style={{ borderRadius: '10px' }}>
                      {notifyCount > 0 ? notifyCount : ''}
                    </Badge>
                  </>
                }
                onClick={readAllNotifications}
                disabled={notifyList.length < 1}
                id=""
                className="mx-1 no-arrow"
              >
                <div className="notificationContainer">
                  {notifyList.length > 0 &&
                    notifyList.map((item, index) => (
                      <React.Fragment key={index}>
                        <NavDropdown.Item
                          href="#"
                          onClick={() => redirectNotification(item)}
                        >
                          <div>
                            <span className="nt_title">
                              <FontAwesomeIcon
                                icon={renderNotificationsIcon(item?.eventId)}
                                fixedWidth
                              />{' '}
                              {` `}
                              {item?.type}
                            </span>
                            <p> {item?.message}</p>
                            <p className="nt_time">
                              {moment(item?.createdAt).fromNow()}
                            </p>
                          </div>
                        </NavDropdown.Item>
                        <Dropdown.Divider />
                      </React.Fragment>
                    ))}
                </div>
              </NavDropdown>

              {/* <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                    <Badge variant="danger">9+</Badge>
                  </>
                }
                id=""
                className="mx-1 no-arrow"
              >
                <NavDropdown.Item href="#">
                  <FontAwesomeIcon icon={faEdit} fixedWidth /> Action
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  <FontAwesomeIcon icon={faHeadphonesAlt} fixedWidth /> Another
                  Action
                </NavDropdown.Item>
                <Dropdown.Divider />
                <NavDropdown.Item href="#">
                  <FontAwesomeIcon icon={faStar} fixedWidth /> Something else
                  here
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                    <Badge variant="success">7</Badge>
                  </>
                }
                id=""
                className="mx-1 no-arrow"
              >
                <NavDropdown.Item href="#">
                  <FontAwesomeIcon icon={faEdit} fixedWidth /> Action
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  <FontAwesomeIcon icon={faHeadphonesAlt} fixedWidth /> Another
                  Action
                </NavDropdown.Item>
                <Dropdown.Divider />
                <NavDropdown.Item href="#">
                  <FontAwesomeIcon icon={faStar} fixedWidth /> Something else
                  here
                </NavDropdown.Item>
              </NavDropdown> */}

              <NavDropdown
                title={
                  <>
                    <img
                      // src={getProfilePicture(userDetails?.Config?.ProfilePic)}
                      //state sey url daaal do ==>> do the same for video also
                      src={getContentThumbnailURL(
                        'photo',
                        userDetails?.Authentication.UserName,
                        userDetails?.Config?.ProfilePic
                      )}
                      // onError={(event) => {
                      //   event.target.src = { profilePicture };
                      //   event.onerror = null;
                      // }}
                      // onError={() => setImgSrc({ profilePicture })}
                      alt="Avatar"
                    />
                    <span className="d-none d-md-inline">
                      {' '}
                      {userDetails?.Authentication?.UserName}
                      {/* userDetails?.FirstName} {userDetails?.LastName */}
                    </span>
                  </>
                }
                id=""
                className="no-arrow osahan-right-navbar-user user-dropdown-link"
              >
                <Link
                  to={
                    userLoggedIn
                      ? `/profile/${userLoggedIn?.userInfo?._id}`
                      : '#'
                  }
                  className="dropdown-item"
                  role="button"
                >
                  <FontAwesomeIcon icon={faUserCircle} fixedWidth /> My Profile
                </Link>
                <Link
                  to={
                    userLoggedIn
                      ? `/updateprofile/${userLoggedIn?.userInfo?._id}`
                      : '#'
                  }
                  className="dropdown-item"
                  role="button"
                >
                  <FontAwesomeIcon icon={faUserCircle} fixedWidth /> Update
                  Profile
                </Link>
                <Link
                  to={'#'}
                  onClick={onCloseModal}
                  className="dropdown-item"
                  role="button"
                >
                  <FontAwesomeIcon icon={faUsers} fixedWidth /> Invite Friends
                </Link>
                <Dropdown.Divider />
                <Link
                  to="#"
                  onClick={handleShow}
                  className="dropdown-item"
                  rolw="button"
                >
                  <FontAwesomeIcon icon={faSignOutAlt} fixedWidth /> Log out
                </Link>
              </NavDropdown>
            </>
          ) : (
            <>
              <li className="nav-item mx-1 authLink">
                <Link to="/auth/login" className="nav-link">
                  <FontAwesomeIcon icon={faSignInAlt} size="lg" />
                  <span> Sign Up / Login</span>
                </Link>
              </li>
            </>
          )}
        </ul>
        <Form
          inline
          className={`d-md-inline ml-auto  
          my-2 my-md-0 osahan-navbar-search ${
            showSearch ? 'searchBarMobile' : 'd-none'
          }`}
        >
          <InputGroup className="searchBarInputGroup">
            <AutoCompleteInput
              searchResult={searchResult}
              onChangeText={setDataSearch}
              dataSearch={dataSearch}
              searchRef={searchRef}
            />
            <InputGroup.Append>
              <Button variant="light">
                <Link ref={searchRef} to={`/search?keyword=${dataSearch}`}>
                  <FontAwesomeIcon icon={faSearch} />
                </Link>
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form>
      </Navbar>
    );
  };

  return (
    <React.Fragment>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        uploadState={uploadState}
      />
      <LogoutModal
        show={show}
        handleClose={handleClose}
        handleLogout={handleLogout}
      />
      <RSModal
        open={openInviteModal}
        onClose={onCloseModal}
        classNames={{ modal: 'talent-wrapper-modal inviteUserModal' }}
      >
        {invitationStats && (
          <React.Fragment>
            <div className="talentheadings-wrappers">
              <h5 className="talentheading">Invite User</h5>
              <button className="remove-btns">
                <i className="fas fa-remove" onClick={onCloseModal} />
              </button>
            </div>
            <div className="talent-popwrap">
              <div className="input-wrapper">
                <Row className="mt-4 justify-content-center">
                  <Col lg={12} sm={12}>
                    <Form.Group controlId="video-description">
                      <p className="invitationStatsInfo">
                        Email{' '}
                        <span>{`Remaining Invites: ${
                          invitationStats?.allowCount -
                          invitationStats?.inviteSent
                        }`}</span>
                      </p>
                      <Form.Control
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {invitationStats?.invitations && (
                  <Row className="justify-content-center">
                    <Col lg={12} sm={12}>
                      <p>Invitation Sent</p>

                      {invitationStats?.invitations?.length > 0 &&
                        invitationStats?.invitations.map((item, index) => (
                          <div className="invitationItem" key={index}>
                            <div>
                              <FontAwesomeIcon icon={faEnvelope} />
                              <span>{item?.email}</span>
                            </div>
                            <span>{moment(item?.createdAt).fromNow()}</span>
                          </div>
                        ))}
                    </Col>
                  </Row>
                )}
              </div>
              {inviteError && (
                <div className="errorContainer mt-4">
                  <p>{inviteError}</p>
                </div>
              )}
              <div className="savebtnwrappp mt-4 mb-4">
                <Button
                  variant="primary"
                  size="md"
                  className="mr-3"
                  onClick={onCloseModal}
                >
                  Close
                </Button>
                <Button variant="primary" size="md" onClick={onPressSentInvite}>
                  Send Invite
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}
      </RSModal>

      {width >= 700 && renderDesktopHeader()}
      {width < 700 && renderMobileHeader()}
    </React.Fragment>
  );
};

export default Navigation;
