import VideoTitle from './VideoTitle';
import AuthorBox from './AuthorBox';
import VideoDescription from './VideoDescription';
import CommentBox from './CommentBox';
import { getProfilePicture } from 'utils/helpers';

const SingleVideoLeft = ({
  userSinglePost,
  userDetails,
  setFollowUnFollowToggle,
  setPostDetail,
  onPressReport,
}) => {
  return (
    <div className="single-video-left">
      <VideoTitle
        title={userSinglePost?.title}
        views={userSinglePost?.views}
        likes={userSinglePost?.likeCount}
        disLikes={userSinglePost?.disLikeCount}
        postId={userSinglePost?._id}
        isLike={userSinglePost?.isLike}
        isDisLike={userSinglePost?.isDislike}
        publishedOn={userSinglePost?.createdAt}
        setPostDetail={setPostDetail}
        onPressReport={onPressReport}
      />

      <AuthorBox
        userId={userSinglePost?.userId?._id}
        isFollow={userDetails?.isFollow}
        channelHref={userDetails?._id}
        subscriberCount={userDetails?.Config?.followers}
        imgSrc={getProfilePicture(userDetails?.Config?.ProfilePic)}
        channelName={`${userSinglePost?.userId?.FirstName} ${userSinglePost?.userId?.LastName}`}
        verified
        setFollowUnFollowToggle={setFollowUnFollowToggle}
      />

      <VideoDescription userSinglePost={userSinglePost} />

      <CommentBox
        userSinglePost={userSinglePost}
        userDetailsVisited={userDetails}
        postId={userSinglePost?._id}
      />
    </div>
  );
};

export default SingleVideoLeft;
