import './SectionHeader.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faSignal,
  faCaretDown,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';

const SectionHeader = ({
  sortCondition,
  setSortCondition,
  heading,
  icon = null,
  noIconLabel = false,
  dropdownOnly = null,
  noSortFilter,
}) => {
  let dropdownIcon = icon ? icon : faCaretDown;
  let dropdownIconLabel = noIconLabel ? '' : `Sort by ${sortCondition}`;

  let dropdownBodyz;

  if (dropdownOnly & !noSortFilter) {
    dropdownBodyz = (
      <ButtonGroup
        dropdownIcon={dropdownIcon}
        dropdownIconLabel={dropdownIconLabel}
        setSortCondition={setSortCondition}
      />
    );
  } else {
    dropdownBodyz = (
      <HeadingWrapper heading={heading}>
        {!noSortFilter && (
          <ButtonGroup
            dropdownIcon={dropdownIcon}
            dropdownIconLabel={dropdownIconLabel}
            setSortCondition={setSortCondition}
          />
        )}
      </HeadingWrapper>
    );
  }

  return dropdownBodyz;
};

function ButtonGroup({ dropdownIcon, dropdownIconLabel, setSortCondition }) {
  return (
    <div className="btn-group float-right right-action">
      <Dropdown>
        <Dropdown.Toggle
          as="a"
          id=""
          className="right-action-link text-gray no-after"
        >
          {dropdownIconLabel}{' '}
          <span>
            <FontAwesomeIcon icon={dropdownIcon} />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setSortCondition('likes')}>
            <FontAwesomeIcon icon={faStar} fixedWidth />
            &nbsp;Likes
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSortCondition('views')}>
            <FontAwesomeIcon icon={faSignal} fixedWidth />
            &nbsp;Viewed
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSortCondition('date')}>
            <FontAwesomeIcon icon={faCalendar} fixedWidth />
            &nbsp;Date
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

function HeadingWrapper({ children, heading }) {
  return (
    <div className="main-title" key={heading}>
      {children ? children : ''}
      <h6>{heading}</h6>
    </div>
  );
}

export default SectionHeader;
export { HeadingWrapper };
