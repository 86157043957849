/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentWrapper from 'components/Core/ContentWrapper';
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Modal, Container } from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import profileActions from 'redux/middleware/profile';
import userEngagementActions from 'redux/middleware/userEngagement';
import Tags from './Tags';
import {
  HideProcessLoader,
  ShowProcessLoader,
} from 'redux/reducers/resourceSlice';
import { Prompt } from 'react-router-dom';
import {
  supportedAudioFormats,
  supportedImagesFormats,
  supportedVideoFormats,
} from 'config/constant';

export default function VideoUploadForm({
  detail,
  location,
  duration,
  autoThumbnail,
  videoThumbFile,
  setVideoThumbFile,
}) {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const { userDetails } = useSelector((state) => state.Profile.UserDetails);

  const chosenFiles = Array.prototype.slice.call(location?.state?.detail);

  const history = useHistory();

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    files.some((file) => {
      uploaded.push(file);
    });
    setUploadedFiles(uploaded);
  };

  const dispatch = useDispatch();
  const [switchModal, setSwitchModal] = useState(false);
  const [uploadState, setUploadState] = useState('');
  const [userTalentById, setUserTalentById] = React.useState([]);
  const loginUserDetails = JSON.parse(localStorage.getItem('__tmw_user'));
  const id = loginUserDetails?.userInfo?._id;
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [videoType, setVideoType] = useState(
    location?.state?.isYoutube ? 'youtube' : 'custom'
  );
  const [tags, setTags] = useState([]);

  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      return (event.returnValue = 'Are you sure you want to exit?');
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  const uploadHandler = async () => {
    setIsProcessing(true);
    dispatch(ShowProcessLoader());
    let videoFile;
    if (location?.state?.isYoutube) {
      videoFile = {
        CountryId: userDetails?.CountryId,
        title,
        desc,
        duration,
        talentCategory: userTalentById?.filter(
          (talent) => talent?.name === subCategory
        )[0].categoryId?._id,
        talentSubCategory: userTalentById?.filter(
          (talent) => talent?.name === subCategory
        )[0]._id,
        videoType,
        hashTags: tags,
        type: '63778f703e6ea7415d763461',
        videoUrl: location?.state?.detail,
        video_thumbnail: videoThumbFile,
      };
    } else if (
      !location?.state?.isYoutube &&
      !location?.state?.isImage &&
      !location?.state?.isAudio
    ) {
      videoFile = {
        CountryId: userDetails?.CountryId,
        title,
        desc,
        duration,
        talentCategory: userTalentById?.filter(
          (talent) => talent?.name === subCategory
        )[0].categoryId?._id,
        talentSubCategory: userTalentById?.filter(
          (talent) => talent?.name === subCategory
        )[0]._id,
        videoType,
        hashTags: tags,
        type: '63778f703e6ea7415d763461',
        video: location?.state?.detail[0],
        video_thumbnail: videoThumbFile !== '' ? videoThumbFile : autoThumbnail,
      };
    } else if (location?.state?.isImage) {
      videoFile = {
        CountryId: userDetails?.CountryId,
        title,
        desc,
        talentCategory: userTalentById?.filter(
          (talent) => talent?.name === subCategory
        )[0].categoryId?._id,
        talentSubCategory: userTalentById?.filter(
          (talent) => talent?.name === subCategory
        )[0]._id,
        hashTags: tags,
        type: '63778f613e6ea7415d76345e',
        // postImages: res,
        postImages: location?.state?.detail,
      };
    } else if (location?.state?.isAudio) {
      videoFile = {
        CountryId: userDetails?.CountryId,
        title,
        desc,
        duration,
        talentCategory: userTalentById?.filter(
          (talent) => talent?.name === subCategory
        )[0].categoryId?._id,
        talentSubCategory: userTalentById?.filter(
          (talent) => talent?.name === subCategory
        )[0]._id,
        videoType,
        hashTags: tags,
        type: '63778fad3e6ea7415d763465',
        audio: location?.state?.detail[0],
        audio_thumbnail: videoThumbFile,
        audioType: 'mp3',
      };
    }
    try {
      if (
        videoFile.type !== '63778f613e6ea7415d76345e' &&
        (videoFile.video_thumbnail === '' ||
          videoFile.video_thumbnail === undefined ||
          videoFile.audio_thumbnail === '' ||
          videoFile.audio_thumbnail === undefined)
      ) {
        toast.error(
          'Auto generated thumbnail is not available for this video. Please upload a thumbnail',
          { autoClose: 5000 }
        );
        dispatch(HideProcessLoader());
        setIsProcessing(false);
        return;
      }

      const apiResponse = await userEngagementActions.uploadContent(
        videoFile,
        loginUserDetails.token
      );
      if (apiResponse.status) {
        toast.success(apiResponse.message);
        dispatch(HideProcessLoader());
        setIsProcessing(false);
        setTimeout(() => {
          history.push('/');
        }, 3000);
      } else {
        const payload = {
          message: apiResponse.message,
        };
        toast.error(payload.message);
        dispatch(HideProcessLoader());
        setIsProcessing(false);
      }
    } catch (error) {}
  };
  const apiCallSpecificUserTalent = async () => {
    try {
      const apiResponse = await profileActions.getUserTalentById('awaeen', id);
      if (apiResponse.status) {
        const { data } = apiResponse;
        setUserTalentById(data);
      } else {
        const payload = {
          message: apiResponse.message,
        };
        // // toast.error(payload.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    apiCallSpecificUserTalent();
  }, [id]);
  useEffect(() => {
    if (userTalentById && userTalentById.length) {
      setSubCategory(userTalentById[0]?.name);
    }
  }, [userTalentById]);
  useEffect(() => {
    handleUploadFiles(chosenFiles);
  }, []);

  function MyVerticallyCenteredModal(props) {
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const history = useHistory();
    const fileTypes = ['JPG', 'PNG', 'GIF'];
    const [file, setFile] = useState(null);

    const handleChange = (file) => {
      setFile(file);
      props.onHide();
      if (file.length) {
        history.push({
          pathname: '/upload-video',
          state: {
            detail: file,
            isYoutube: false,
            isImage: props?.uploadState === 'image' ? true : false,
            isAudio: props?.uploadState === 'audio' ? true : false,
            stateOfFile: props.uploadState,
          },
        });
      }
    };
    const youtubeUploadHandler = () => {
      if (!youtubeUrl) return;
      toast.error('Please add youtube link');
      props.onHide();
      history.push({
        pathname: '/upload-video',
        state: {
          detail: youtubeUrl,
          isYoutube: true,
          stateOfFile: props.uploadState,
        },
      });
    };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Upload {props.uploadState}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContentWrapper>
            <Container fluid className="">
              <Row>
                <Col
                  md={8}
                  className="mx-auto text-center upload-video pt-5 pb-5"
                >
                  <FileUploader
                    handleChange={handleChange}
                    name="file"
                    multiple={true}
                    types={
                      props.uploadState === 'video'
                        ? supportedVideoFormats
                        : props.uploadState === 'image'
                        ? supportedImagesFormats
                        : supportedAudioFormats
                    }
                  >
                    <h1 className="text-primary">
                      <FontAwesomeIcon icon={faFileUpload} />
                    </h1>
                  </FileUploader>
                  <h4 className="mt-5">
                    Select {props.uploadState} files to upload{' '}
                  </h4>
                  <p className="land">
                    or drag and drop {props.uploadState} files{' '}
                  </p>
                  <div className="mt-4">
                    <FileUploader
                      handleChange={handleChange}
                      name="file"
                      multiple={true}
                      types={
                        props.uploadState === 'video'
                          ? supportedVideoFormats
                          : props.uploadState === 'image'
                          ? supportedImagesFormats
                          : supportedAudioFormats
                      }
                    >
                      <div className="btn btn-outline-primary">
                        {/* <Link className="btn btn-outline-primary" to="#"> */}
                        Upload {props.uploadState}
                        {/* </Link> */}
                      </div>
                    </FileUploader>
                  </div>
                  {props.uploadState === 'video' && (
                    <>
                      <div>Or Youtube Video Url</div>
                      <div className="input-group mb-3">
                        <input
                          style={{ height: '40px' }}
                          type="text"
                          className="form-control"
                          placeholder="https://www.youtube.com/watch?v=5_IeZe6GG3o"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          onChange={(e) => setYoutubeUrl(e.target.value)}
                        />
                        <div className="input-group-append">
                          <div
                            style={{ height: '40px' }}
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => youtubeUploadHandler()}
                          >
                            Upload
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </ContentWrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <MyVerticallyCenteredModal
        show={switchModal}
        onHide={() => setSwitchModal(false)}
        uploadState={uploadState}
      />
      <Prompt when={isProcessing} message="Are you sure you want to leave?" />
      <Row>
        <Col lg={12}>
          <div className="osahan-form">
            <Row>
              <Col lg={6}>
                <Form.Group controlId="video-title">
                  <Form.Label>
                    Title <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={'Enter Title'}
                    // value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {title.length < 5 && (
                    <span className="RequiredText">Title required*</span>
                  )}
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group controlId="formFile" className="mb-3 mt-4">
                  <div className="upload-btn-wrapper">
                    <Button
                      variant="outline-danger"
                      size="sm"
                      className="mr-3"
                      onClick={() => {
                        setSwitchModal(true);
                        setUploadState(location?.state?.stateOfFile);
                      }}
                    >
                      Change Current {location?.state?.stateOfFile}
                    </Button>
                  </div>
                  {/* <Form.Label>Thumbnail</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => setVideoThumbFile(e.target.files[0])}
                  /> */}
                </Form.Group>
              </Col>
              {location?.state?.isYoutube && (
                <Col lg={12}>
                  <Form.Group controlId="video-title">
                    <Form.Label>Youtube Url</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={location?.state?.detail}
                      value={location?.state?.detail}
                    />
                  </Form.Group>
                </Col>
              )}
              <Col lg={12}>
                <Form.Group controlId="video-description">
                  <Form.Label>
                    Description <span className="asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Description"
                    onChange={(e) => setDesc(e.target.value)}
                  />
                  {desc.length < 10 && (
                    <span className="RequiredText">
                      At-least 10 characters required*
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {/* <Col lg={3}>
                <Form.Group controlId="video-orientation">
                  <Form.Label>Category</Form.Label>
                  <Form.Control as="select" className="custom-select" custom>
                    <option>Straight</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
              <Col lg={3}>
                <Form.Group controlId="video-privacy">
                  <Form.Label>Sub Category</Form.Label>
                  <Form.Control
                    as="select"
                    className="custom-select"
                    custom
                    onChange={(e) => setSubCategory(e.target.value)}
                  >
                    {userTalentById?.map((talent, i) => (
                      <option key={i}>{talent?.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg={5}>
                <Form.Group controlId="video-tags">
                  <Form.Label>Tags</Form.Label>
                  {/* <Form.Control
                    type="text"
                    placeholder="Gaming, PS4"
                    onChange={(e) => setHashTags(e.target.value)}
                  /> */}
                  <div
                    style={{
                      border: '1px solid #ced4da',
                      padding: '0.35rem 0.75rem',
                      backgroundColor: 'white',
                    }}
                  >
                    <Tags tags={tags} setTags={setTags} />
                  </div>
                </Form.Group>
                {/* <MentionCommentMedia
                  setCommentData={setCommentData}
                  commentData={commentData}
                  setMentionData={setMentionData}
                  setHashData={setHashData}
                  mentionData={mentionData}
                  hashData={hashData}
                /> */}
              </Col>
              {/* <Col lg={4}>
                <Form.Group controlId="video-casting">
                  <Form.Label>Cast (Optional)</Form.Label>
                  <Form.Control type="text" placeholder="Nathan Drake," />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group controlId="video-language">
                  <Form.Label>Language inn Video (Optional)</Form.Label>
                  <Form.Control as="select" custom>
                    <option>English</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
            </Row>

            {/* <Row>
              <Col lg={12}>
                <div className="main-title">
                  <h6>Category ( you can select upto 6 categories )</h6>
                </div>
              </Col>
            </Row> */}

            {/* <Row className="category-checkbox">
              <Col lg={2} xs={4}>
                <Form.Check
                  type="checkbox"
                  custom
                  label="Abaft"
                  id="customCheck1"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Brick"
                  id="customCheck2"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Purpose"
                  id="customCheck3"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Shallow"
                  id="customCheck4"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Spray"
                  id="customCheck5"
                />
              </Col>

              <Col lg={2} xs={4}>
                <Form.Check
                  type="checkbox"
                  custom
                  label="Cemetery"
                  id="zcustomCheck1"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Trouble"
                  id="zcustomCheck2"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Pin"
                  id="zcustomCheck3"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Fall"
                  id="zcustomCheck4"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Leg"
                  id="zcustomCheck5"
                />
              </Col>

              <Col lg={2} xs={4}>
                <Form.Check
                  type="checkbox"
                  custom
                  label="Scissors"
                  id="czcustomCheck1"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Stitch"
                  id="czcustomCheck2"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Agonizing"
                  id="czcustomCheck3"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Rescue"
                  id="czcustomCheck4"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Quiet"
                  id="czcustomCheck5"
                />
              </Col>
              <Col lg={2} xs={4}>
                <Form.Check
                  type="checkbox"
                  custom
                  label="Abaft"
                  id="customCheck1"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Brick"
                  id="customCheck2"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Purpose"
                  id="customCheck3"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Shallow"
                  id="customCheck4"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Spray"
                  id="customCheck5"
                />
              </Col>

              <Col lg={2} xs={4}>
                <Form.Check
                  type="checkbox"
                  custom
                  label="Cemetery"
                  id="zcustomCheck1"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Trouble"
                  id="zcustomCheck2"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Pin"
                  id="zcustomCheck3"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Fall"
                  id="zcustomCheck4"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Leg"
                  id="zcustomCheck5"
                />
              </Col>

              <Col lg={2} xs={4}>
                <Form.Check
                  type="checkbox"
                  custom
                  label="Scissors"
                  id="czcustomCheck1"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Stitch"
                  id="czcustomCheck2"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Agonizing"
                  id="czcustomCheck3"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Rescue"
                  id="czcustomCheck4"
                />
                <Form.Check
                  type="checkbox"
                  custom
                  label="Quiet"
                  id="czcustomCheck5"
                />
              </Col>
            </Row> */}
          </div>
          <div className="osahan-area text-center mt-3">
            <Button
              variant="outline-primary"
              onClick={uploadHandler}
              disabled={title === '' || desc === ''}
            >
              Upload {location?.state?.stateOfFile}
            </Button>
          </div>
        </Col>
      </Row>
      {/* <Terms /> */}
    </React.Fragment>
  );
}

function Terms() {
  return (
    <React.Fragment>
      <hr />
      <div className="terms text-center">
        <p className="mb-0">
          There are many variations of passages of Lorem Ipsum available, but
          the majority <a href="#">Terms of Service</a> and{' '}
          <a href="#">Community Guidelines</a>.
        </p>
        <p className="hidden-xs mb-0">
          Ipsum is therefore always free from repetition, injected humour, or
          non
        </p>
      </div>
    </React.Fragment>
  );
}
