import { baseURL } from 'config/constant';
import Utils from 'redux/utils';

const dislikeContent = async (token, postId) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/dislike/${postId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'PUT',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getComments = async (postId) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/comments/${postId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const addComment = async (desc, mentioned, hashTags, postId, token) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/comments`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: {
        desc,
        mentioned,
        hashTags,
        postId,
      },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const likeContent = async (token, postId) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/like/${postId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'PUT',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const followUser = async (token, followUserId) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/userFollowing/followUser`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: {
        followUserId,
      },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const removeFollowing = async (token, unFollowUserId) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/userFollowing/unFollowUser`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: {
        unFollowUserId,
      },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const uploadContent = async (payload, token) => {
  try {
    const formData = new FormData();
    for (let key in payload) {
      if (key !== 'postImages') {
        formData.append(key, payload[key]);
      } else {
        const images = Array.from(payload[key]);
        images && images.length;
        images.map((item) => {
          formData.append('postImages', item);
        });
      }
    }
    const apiOptions = {
      endpoint: `${baseURL}/posts`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: formData,
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    if (apiResponse?.response) {
      return apiResponse.response.data;
    } else {
      return apiResponse.data;
    }
  } catch (error) {
    return error;
  }
};

const getNotifications = async (token, userID) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/notification?id=${userID}&limit=20`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const readAllNotifications = async (token, notifications) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/notification/updateAll`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'PUT',
      data: {
        notificationId: notifications,
      },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const readNotifications = async (token, unFollowUserId) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/userFollowing/unFollowUser`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: {
        unFollowUserId,
      },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const sendMessageRequest = async (token, senderId) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/users/sendMessageRequest`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: { senderId },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getMessageRequests = async (token) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/users/getMessageRequest`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const checkUserChatStatus = async (token, senderId) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/users/checkUserChatStatus`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: { senderId },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const acceptChatReq = async (token, msgId, userId, chatId) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/users/acceptMessageRequest`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: { requestId: msgId, requestUserId: userId, chatId },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const rejectChatReq = async (token, msgId, userId, chatId) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/users/rejectMessageRequest`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: { requestId: msgId, requestUserId: userId, chatId },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const sendMessageEmail = async (token, userName, userId, fromUserId) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/notification/sendMessageEmail`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: { userName, userId, fromUserId },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getReportTypes = async (token, type) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/reportTypes?type=${type}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const submitReport = async (token, payload) => {
  try {
    const { id, type, desc } = payload;
    const apiOptions = {
      endpoint: `${baseURL}/reports/post`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: { postId: id, reportType: type, description: desc },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const submitUserReport = async (token, payload) => {
  try {
    const { id, type, desc } = payload;
    const apiOptions = {
      endpoint: `${baseURL}/reports/user`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: { userId: id, reportType: type, description: desc },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const removeContent = async (token, postId) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/${postId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'DELETE',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getInvitationSent = async (token) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/invitation/getStats`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const invitationSent = async (token, email) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/invitation/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: { email },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const userEngagementActions = {
  dislikeContent,
  getComments,
  addComment,
  likeContent,
  removeContent,
  followUser,
  removeFollowing,
  uploadContent,
  getNotifications,
  readAllNotifications,
  readNotifications,
  getMessageRequests,
  sendMessageRequest,
  checkUserChatStatus,
  acceptChatReq,
  rejectChatReq,
  sendMessageEmail,
  getReportTypes,
  submitReport,
  submitUserReport,
  getInvitationSent,
  invitationSent,
};

export default userEngagementActions;
