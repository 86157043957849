// For Development ENV
// export const baseURL = 'http://localhost:5002/api';
// export const defaultPath = 'http://localhost:5002';

// For Deployment ENV
export const baseURL = 'https://tmwapi.prime-logics.com/api';
export const defaultPath = 'https://tmwapi.prime-logics.com';
export const contentBaseURL = 'http://content.talentmyway.com';
export const folderPaths = {
  audio_thumbnail: 'audios_thumbnail',
  audio: 'audios',
  video: 'videos',
  profile_photos: 'profile_photos',
  photos: 'photos',
  video_thumbnail: 'videos_thumbnail',
  profile: 'profile_photos',
  cover_photo: 'cover_photos',
};
export const somethingWrongMessage = 'Something went wrong, please try again';
export const defaultColors = {
  badgeColor: '#cc4747',
  childIconColor: '#5da92d',
};
export const VAPID_KEY =
  'BFP-ePGJtMGjUDFU2WukRo8vTexBZpDDlvKNcdgySpgRCX3M9bjFsK0jI41c93ZXS6ygmeKcK6VpJ-Hvfvevl08';

export const supportedVideoFormats = [
  'mp4',
  'mpeg',
  'mpg',
  'mpe',
  'avi',
  '3gp',
  'mov',
  'wmv',
  'webm',
  'vlc',
  'f4v',
  'qt',
];

export const supportedAudioFormats = ['mp3', 'm4a', 'ogg', 'oga', 'aac'];
export const supportedImagesFormats = [
  'bmp',
  'png',
  'jpg',
  'jpeg',
  'gif',
  'tiff',
  'tif',
  'ico',
  'jpe',
];

export const alt_youtube_img_thumbnail_path = 'https://i.ytimg.com/vi/';
export const yt_hres_default_img_path = `/hqdefault.jpg`;
export const youtube_v3_api_key = 'AIzaSyBLBM-CbY1C9CP61TNynFJQ-1LYYncdCqQ';
