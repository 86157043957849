/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import './VideoPage.scss';
import Container from 'react-bootstrap/Container';
import React from 'react';
import { Modal } from 'react-responsive-modal';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import ThinFooter from '../Footer/ThinFooter';
import ContentWrapper from '../Core/ContentWrapper';
import SectionHeader from '../Core/SectionHeader';
import Utils from '../../redux/utils';
import { baseURL } from '../../config/constant';
import VideoCardList from './VideoCardList';
import SingleVideoRight from './SingleVideoRight';
import SingleVideoLeft from './SingleVideoLeft';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import postActions from 'redux/middleware/posts';
import profileActions from 'redux/middleware/profile';
import ContentUnavailable from '../../assets/img/ContentUnavailable.png';
import moment from 'moment';
import {
  getContentThumbnailURL,
  getContentURL,
  getVideoThumbnail,
  numFormatter,
  toTime,
} from 'utils/helpers';
import PostImageSlider from 'pages/home/components/PostImageSlider';
import userEngagementActions from 'redux/middleware/userEngagement';
// import { toast } from 'react-toastify';

const VideoPage = () => {
  const [followUnFollowToggle, setFollowUnFollowToggle] = useState(true);
  const userLoggedIn = JSON.parse(localStorage.getItem('__tmw_user'));
  const [sortCondition, setSortByCondition] = useState('date');
  const [htmlResult, setHtmlResult] = useState();
  const [userDetailsVisited, setuserDetailsVisited] = React.useState(null);
  const [userSinglePost, setUserSinglePost] = React.useState();
  const [userPost, setUserPost] = React.useState(null);
  const [reportTypes, setReportTypes] = React.useState(null);
  const [openReportModal, setReportModal] = React.useState(false);
  const [reportReason, setReason] = React.useState(null);
  const [reportDesc, setReportDesc] = React.useState(null);
  const [reportError, setReportError] = React.useState(null);
  const { id } = useParams();
  const [allPostBySubCategories, setallPostBySubCategories] = React.useState(
    []
  );
  useEffect(() => {
    scrollToTop();
    userSinglePost && apiCallUserDetailsVisited();
  }, [userSinglePost, followUnFollowToggle]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const apiCall = async () => {
    try {
      const apiResponse = await postActions.getPostBySubCategory(
        userSinglePost?.talentSubCategory[0]._id,
        sortCondition
      );

      if (apiResponse.status) {
        const { data } = apiResponse;
        setallPostBySubCategories(data[0]);
      } else {
      }
    } catch (error) {
      // FIXME: Add Logic Here
    }
  };

  const apiCallUserDetailsVisited = async () => {
    try {
      const apiResponse = await profileActions.getVisitedUserDetails(
        userSinglePost?.userId?._id,
        userLoggedIn?.token
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setuserDetailsVisited(data);
      } else {
      }
    } catch (error) {
      // FIXME: Add Logic Here
    }
  };

  const apiCallUserPosts = async () => {
    try {
      const apiResponse = await profileActions.getUserPosts(
        userSinglePost?.userId?._id,
        sortCondition
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setUserPost(data);
      } else {
      }
    } catch (error) {
      // FIXME: Add Logic Here
    }
  };

  const singlePostApiCall = async () => {
    try {
      const apiResponse = await postActions.getPostDetail(
        id,
        userLoggedIn?.token
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setUserSinglePost(data);
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };

  const getReportTypes = async () => {
    try {
      const apiResponse = await userEngagementActions.getReportTypes(
        userLoggedIn?.token,
        'post'
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setReportTypes(data);
      } else {
        const payload = {
          message: apiResponse.message,
        };
        console.log(payload);
      }
    } catch (error) {}
  };

  const reportContent = async (payload) => {
    try {
      const apiResponse = await userEngagementActions.submitReport(
        userLoggedIn?.token,
        payload
      );
      if (apiResponse.status) {
        toast.success(
          'Reported. Administrator will review it and take action against it.'
        );
        setReportModal(false);
      } else {
        const payload = {
          message: apiResponse.message,
        };
        console.log(payload);
      }
    } catch (error) {}
  };

  const viewPostApiCall = async () => {
    const apiOptions = {
      endpoint: `${baseURL}/posts/view/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    };
    await Utils.CallApi(apiOptions);
  };

  const youtubeUrl = async () => {
    const apiOptions = {
      endpoint: `https://www.youtube.com/oembed?url=${userSinglePost?.videoUrl}&format=json`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    const replacement1 = apiResponse?.data?.html?.replace('200', '100%');
    const replacement2 = replacement1?.replace('150', '500');
    const replacement3 = replacement2?.replace('113', '500');
    setHtmlResult(replacement3);
  };

  useEffect(() => {
    userSinglePost && apiCallUserPosts();
    userSinglePost && apiCall();
    userSinglePost && youtubeUrl();
  }, [userSinglePost, sortCondition, htmlResult]);

  useEffect(() => {
    singlePostApiCall();
    viewPostApiCall();
    if (userLoggedIn?.token) {
      getReportTypes();
    }
  }, [id]);

  const isContentVisible =
    !userSinglePost?.isBlocked && userSinglePost?.isActive;

  const onCloseModal = () => setReportModal(!openReportModal);

  const validateReport = () => {
    if (!reportReason || reportReason === 'Select') {
      setReportError('Add Reason');
      return false;
    }
    if (!reportDesc || reportDesc === '') {
      setReportError('Add Description');
      return false;
    }
    setReportError(null);
    return true;
  };

  const onSubmitReport = () => {
    const isValid = validateReport();
    if (isValid) {
      const payload = {
        id,
        type: reportReason,
        desc: reportDesc,
      };
      reportContent(payload);
    }
  };

  return (
    <ContentWrapper>
      <Container fluid>
        <Modal
          open={openReportModal}
          // closeOnOverlayClick={false}
          onClose={onCloseModal}
          classNames={{ modal: 'talent-wrapper-modal' }}
        >
          <div className="talentheadings-wrappers">
            <h5 className="talentheading">Report Content</h5>
            <button className="remove-btns">
              <i className="fas fa-remove " onClick={onCloseModal} />
            </button>
          </div>
          <div className="talent-popwrap">
            <div className="input-wrapper">
              <Row className="mt-4 justify-content-center">
                <Col lg={10} sm={10} className="mb-4">
                  <Form.Label>Report Reason</Form.Label>
                  <Form.Control
                    as="Select"
                    className="custom-select"
                    // value={reportReason}
                    onChange={(e) => setReason(e.target.value)}
                  >
                    <option value={null}>Select</option>
                    {reportTypes &&
                      reportTypes.map((item) => (
                        <option key={item?._id} value={item?._id}>
                          {item?.name}
                        </option>
                      ))}
                  </Form.Control>
                </Col>
                <Col lg={10} sm={10}>
                  <Form.Group controlId="video-description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      placeholder="Description"
                      value={reportDesc}
                      onChange={(e) => {
                        setReportDesc(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {reportError && reportError !== '' && (
              <div className="errorContainer">
                <p>{reportError}</p>
              </div>
            )}
            <div className="savebtnwrappp">
              <Button variant="primary" size="lg" onClick={onSubmitReport}>
                Submit
              </Button>
            </div>
          </div>
        </Modal>
        <div className="video-block-right-list section-padding">
          {isContentVisible && (
            <Row className="mb-4">
              <Col md={8}>
                {userSinglePost?.type === '63778f613e6ea7415d76345e' ? (
                  <Col md={12} className="previewScreen">
                    <PostImageSlider userSinglePost={userSinglePost} />
                  </Col>
                ) : (
                  <div className="single-video">
                    {userSinglePost?.videoType === 'youtube' ? (
                      <ReactPlayer
                        className="react-player"
                        url={userSinglePost?.videoUrl}
                        width="100%"
                        // height="100%"
                        controls={true}
                        light={
                          <img
                            src={getContentThumbnailURL(
                              '63778f703e6ea7415d763461',
                              userDetailsVisited?.Authentication?.UserName,
                              userSinglePost
                            )}
                            alt="Thumbnail"
                            className="videoThumbnail"
                          />
                        }
                        playing={true}
                      />
                    ) : (
                      <ReactPlayer
                        style={{
                          backgroundImage:
                            userSinglePost?.type === '63778fad3e6ea7415d763465'
                              ? `url(
                                  ${getContentThumbnailURL(
                                    userSinglePost?.type,
                                    userDetailsVisited?.Authentication
                                      ?.UserName,
                                    userSinglePost
                                  )}
                                )`
                              : '',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                        }}
                        className="react-player"
                        url={getContentURL(
                          userSinglePost?.type,
                          userDetailsVisited?.Authentication?.UserName,
                          userSinglePost
                        )}
                        width="100%"
                        height="100%"
                        controls={true}
                        playing={true}
                        light={
                          <img
                            src={getContentThumbnailURL(
                              userSinglePost?.type,
                              userDetailsVisited?.Authentication?.UserName,
                              userSinglePost
                            )}
                            alt="Thumbnail"
                            className="videoThumbnail"
                          />
                        }
                      />
                    )}
                  </div>
                )}

                <div className="rightBarView">
                  <SingleVideoLeft
                    userSinglePost={userSinglePost}
                    userDetails={userDetailsVisited}
                    setFollowUnFollowToggle={() =>
                      setFollowUnFollowToggle(!followUnFollowToggle)
                    }
                    setPostDetail={setUserSinglePost}
                    onPressReport={setReportModal}
                  />
                </div>
              </Col>

              <Col md={4} className="rightOptionView">
                <Col md={12} className="mt-4 rightOptionList">
                  <SectionHeader
                    heading="Related Videos"
                    setSortCondition={setSortByCondition}
                    sortCondition={sortCondition}
                  />
                  <div className="video-slider-right-list">
                    {allPostBySubCategories &&
                      allPostBySubCategories[
                        userSinglePost?.talentSubCategory[0]?._id
                      ] &&
                      allPostBySubCategories[
                        userSinglePost?.talentSubCategory[0]?._id
                      ].posts?.map((item) => (
                        <VideoCardList
                          postHref={`/watch/${item?._id}`}
                          key={item?._id}
                          imgSrc={getContentThumbnailURL(
                            item?.type,
                            item?.userId?.Authentication?.UserName,
                            item
                          )}
                          time={
                            item?.type === '63778f613e6ea7415d76345e'
                              ? item?.images.length
                              : toTime(item?.duration)
                          }
                          flag_id={item?.country}
                          iconOfType={item?.type}
                          videoTitle={item?.title}
                          userName={`${item?.userId?.FirstName} ${item?.userId?.LastName}`}
                          views={numFormatter(item?.views)}
                          likes={numFormatter(item?.likeCount)}
                          videoUserNameId={item?.userId?._id}
                          timeAgo={moment(item.createdAt).fromNow()}
                          videoCategory={
                            item?.talentCategory[0] &&
                            item?.talentCategory[0]?.name
                          }
                          videoCategoryId={
                            item?.talentCategory[0] &&
                            item?.talentCategory[0]?._id
                          }
                          videoSubCategory={
                            item?.talentSubCategory[0] &&
                            item?.talentSubCategory[0]?.name
                          }
                          videoSubCategoryId={
                            item?.talentSubCategory[0] &&
                            item?.talentSubCategory[0]?._id
                          }
                          verified
                        />
                      ))}
                  </div>
                </Col>
                <Col md={12} className="mt-5">
                  <SingleVideoRight userSinglePost={userSinglePost} />
                </Col>
              </Col>
            </Row>
          )}
          {userSinglePost && !isContentVisible && (
            <Row>
              <div className="ContentValidationContainer">
                <img src={ContentUnavailable} alt={'unavailable'} />
              </div>
            </Row>
          )}
        </div>
      </Container>
      <ThinFooter />
    </ContentWrapper>
  );
};

export default VideoPage;
