import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export default function UpdateProfileNav({
  setCoverImageFile,
  setProfileImageFile,
  setCoverImageState,
  setprofileImageState,
  setCoverImageSelect,
  setprofileImageSelect,
}) {
  // useEffect(() => {}, [coverImageFile, profileImageFile]);
  return (
    <div className="single-channel-nav">
      <Navbar expand="lg">
        <Navbar.Toggle aria-controls="navbarSupportedContent" />

        <Navbar.Collapse id="navbarSupportedContent">
          <Nav as="ul" className="mr-auto" />
          <Form inline className="mt-2 mb-2">
            <div className="upload-btn-wrapper">
              <Button variant="outline-danger" size="sm" className="mr-3">
                Change Cover Photo
              </Button>
              <Form.File
                accept="image/png, image/jpeg"
                type="file"
                onChange={(e) => {
                  setCoverImageFile(e.target.files[0]);
                  setCoverImageState(false);
                  setCoverImageSelect(e.target.files[0]);
                }}
              />
            </div>
            <div className="upload-btn-wrapper">
              <Button variant="outline-danger" size="sm">
                Change Profile Picture
              </Button>
              <Form.File
                accept="image/png, image/jpeg"
                type="file"
                onChange={(e) => {
                  setProfileImageFile(e.target.files[0]);
                  setprofileImageState(false);
                  setprofileImageSelect(e.target.files[0]);
                }}
              />
            </div>
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
