import {
  faChild,
  faImage,
  faMusic,
  faTrash,
  faUniversalAccess,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getVideoThumbnail } from 'utils/helpers';
import './VideoCard.scss';
import { defaultColors } from 'config/constant';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function VideoCard({
  imgAlt = '',
  time = '-:-',
  videoTitle,
  imgSrc,
  views,
  likes,
  // timeAgo,
  videoCategory,
  videoCategoryId,
  videoSubCategory,
  videoSubCategoryId,
  verified = null,
  isChild = null,
  iconOfType,
  videoUserNameId,
  videoUserName,
  postRef,
  flag_id,
  onDelete = null,
  id = null,
}) {
  const Country = useSelector((state) => state.Profile.Resources);

  //Your handler Component
  const addDefaultSrc = (ev) => {
    ev.target.src = getVideoThumbnail('');
  };

  return (
    <div className="video-card">
      <Link to={postRef || '#'}>
        <div className="video-card-image">
          {onDelete && (
            <div
              className="iconDelete"
              onClick={(e) => {
                e.preventDefault();
                onDelete(id);
              }}
              title="Delete Content"
            >
              <FontAwesomeIcon icon={faTrash} fixedWidth />
            </div>
          )}
          <LazyLoadImage
            style={{ height: '200px', objectFit: 'cover' }}
            src={imgSrc}
            // src={getVideoThumbnail('')}
            alt={imgAlt}
            onError={addDefaultSrc}
          />
          {time === 0 || <div className="time">{time}</div>}

          <div className="iconOfTypee">
            {iconOfType === '63778f613e6ea7415d76345e' ? (
              <FontAwesomeIcon icon={faImage} fixedWidth />
            ) : iconOfType === '63778f703e6ea7415d763461' ? (
              <FontAwesomeIcon icon={faVideo} fixedWidth />
            ) : (
              <FontAwesomeIcon icon={faMusic} fixedWidth />
            )}
          </div>
        </div>
      </Link>
      <div className="video-card-body">
        <div className="video-title">
          <Link to={postRef || '#'}>{videoTitle}</Link>
        </div>
        <div
          className={
            verified
              ? 'video-page text-success-custom userNameVideoCard'
              : 'video-page text-danger-custom userNameVideoCard'
          }
        >
          <Link to={`/profile/${videoUserNameId}`}>{videoUserName} </Link>
          {verified && (
            <span>
              <FontAwesomeIcon
                icon={faUniversalAccess}
                color={defaultColors.badgeColor}
                title="Ambassador"
                fixedWidth
              />
            </span>
          )}
          {isChild && (
            <span title="Children Account">
              <FontAwesomeIcon
                icon={faChild}
                color={defaultColors.childIconColor}
                fixedWidth
              />
            </span>
          )}
          {/* {verified ? <VerifiedTooltip /> : <UnverifiedTooltip />} */}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'cneter',
          }}
        >
          <div
            style={{ margin: 'auto 0' }}
            className={
              verified
                ? 'video-page text-success-custom userNameVideoCard'
                : 'video-page text-danger-custom userNameVideoCard'
            }
          >
            <Link
              to={`/search?cat=${videoCategory?.replaceAll(
                ' / ',
                '-'
              )}&cat_id=${videoCategoryId}`}
            >
              {videoCategory}
            </Link>{' '}
            ,{' '}
            <Link
              to={`/search?sub_cat=${videoSubCategory?.replaceAll(
                ' / ',
                '-'
              )}&sub_cat_id=${videoSubCategoryId}`}
            >
              {videoSubCategory}
            </Link>{' '}
            {/* {verified ? <VerifiedTooltip /> : <UnverifiedTooltip />} */}
          </div>
          {/* <Link
            to={{
              pathname: `/view/country/${flag_id}`,
              state: {
                flag_id: flag_id,
                callSpecificApi: 'postsByCountryFlag',
              },
            }}
          >
            <div className="flagContainer">
              {!Country?.Country?.filter(({ _id }) => _id === flag_id)
                .length || (
                <img
                  style={{ objectFit: 'contain' }}
                  alt="flagPicture"
                  width="25px"
                  height="25px"
                  src={
                    Country?.Country?.filter(({ _id }) => _id === flag_id) &&
                    Country?.Country?.filter(({ _id }) => _id === flag_id)[0] &&
                    Country?.Country?.filter(({ _id }) => _id === flag_id)[0]
                      .flag
                  }
                />
              )}
            </div>
                </Link> */}
        </div>
        <div className="video-view">
          <div>
            {views} views &nbsp;
            {likes} likes &nbsp;
          </div>
          {/* <div>
            <FontAwesomeIcon icon={faCalendarAlt} /> {timeAgo}
                </div> */}
          <Link
            to={{
              pathname: `/view/country/${flag_id}`,
              state: {
                flag_id: flag_id,
                callSpecificApi: 'postsByCountryFlag',
              },
            }}
          >
            <div className="flagContainer">
              {!Country?.Country?.filter(({ _id }) => _id === flag_id)
                .length || (
                <img
                  style={{ objectFit: 'contain' }}
                  alt="flagPicture"
                  width="25px"
                  height="25px"
                  src={
                    Country?.Country?.filter(({ _id }) => _id === flag_id) &&
                    Country?.Country?.filter(({ _id }) => _id === flag_id)[0] &&
                    Country?.Country?.filter(({ _id }) => _id === flag_id)[0]
                      .flag
                  }
                />
              )}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default VideoCard;
