import { baseURL } from 'config/constant';
import Utils from 'redux/utils';

const searchContent = async (
  sortCondition,
  token,
  limit,
  sortFilters = null
) => {
  try {
    const apiOptions = token
      ? {
          endpoint: `${baseURL}/posts/searchAll?limit=${limit ? limit : 10}`,

          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          params: sortFilters,
          method: 'GET',
        }
      : {
          endpoint: `${baseURL}/posts/searchAll?limit=${limit ? limit : 10}`,

          headers: {
            'Content-Type': 'application/json',
          },
          params: sortFilters,
          method: 'GET',
        };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const searchUsers = async (sortCondition, token, limit, sortFilters = null) => {
  try {
    const apiOptions = token
      ? {
          endpoint: `${baseURL}/posts/searchUsers?limit=${
            limit ? limit : 10
          }&pageNo=1`,

          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          params: sortFilters,
          method: 'GET',
        }
      : {
          endpoint: `${baseURL}/posts/searchAll?limit=${limit ? limit : 10}`,

          headers: {
            'Content-Type': 'application/json',
          },
          params: sortFilters,
          method: 'GET',
        };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const searchActions = {
  searchContent,
  searchUsers,
};

export default searchActions;
