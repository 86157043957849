import { baseURL } from 'config/constant';
import Utils from 'redux/utils';

const UserLogin = async (payload) => {
  try {
    const { email, password } = payload;
    const apiOptions = {
      endpoint: `${baseURL}/auth/login`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      data: {
        email,
        password,
      },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    if (apiResponse.status === 200) {
      return apiResponse.data;
    } else {
      return apiResponse.response.data;
    }
  } catch (error) {
    return error;
  }
};

const UserInvitation = async (payload) => {
  try {
    const { name, email, description, purpose, category } = payload;
    const apiOptions = {
      endpoint: `${baseURL}/invitationRequests`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      data: {
        name,
        email,
        description,
        purpose,
        category,
      },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const UserSignup = async (payload) => {
  try {
    const {
      firstName,
      lastName,
      dob,
      gender,
      country,
      countryId,
      city,
      cityId,
      email,
      userName,
      password,
      isInvited,
      invitationKey,
      isParental,
      parentName,
      parentEmail,
    } = payload;
    const apiOptions = {
      endpoint: `${baseURL}/auth/signup`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      data: {
        firstName,
        lastName,
        dob,
        gender,
        country,
        city,
        cityId,
        countryId,
        email,
        userName,
        password,
        isInvited,
        invitationKey,
        isParental,
        parentName,
        parentEmail,
      },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    if (apiResponse?.response) {
      return apiResponse.response.data;
    } else {
      return apiResponse.data;
    }
  } catch (error) {
    return error;
  }
};

const UpdateProfile = async (payload, token) => {
  try {
    const objToSend = {
      FirstName: payload.firstName,
      LastName: payload.lastName,
      DOB: payload.dob,
      Gender: payload.gender,
      Country: payload.country,
      City: payload.city,
      CountryId: payload.countryId,
      CityId: payload.cityId,
      Image: payload.profilePicture,
      coverImage: payload.coverPhoto,
      fb: payload.facebook,
      instagram: payload.insta,
      twitter: payload.twitter,
      Description: payload.description,
    };
    const formData = new FormData();
    for (let key in objToSend) {
      formData.append(key, objToSend[key]);
    }
    const apiOptions = {
      endpoint: `${baseURL}/users`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'PUT',
      data: formData,
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    if (apiResponse?.response) {
      return apiResponse.response.data;
    } else {
      return apiResponse.data;
    }
  } catch (error) {
    return error;
  }
};

const UserDeviceMapping = async (payload, token) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/auth/addDeviceId`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: JSON.stringify(payload),
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    if (apiResponse?.response) {
      return apiResponse.response.data;
    } else {
      return apiResponse.data;
    }
  } catch (error) {
    return error;
  }
};

const ResetPasswordRequest = async (payload) => {
  try {
    const { email } = payload;
    const apiOptions = {
      endpoint: `${baseURL}/auth/requestResetPassword`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      data: { email },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    if (apiResponse.status === 200) {
      return apiResponse.data;
    } else {
      return apiResponse.response.data;
    }
  } catch (error) {
    return error;
  }
};

const ChangePassword = async (payload) => {
  try {
    const { id, token, password, confirmPassword } = payload;
    const apiOptions = {
      endpoint: `${baseURL}/auth//resetPassword/${id}/${token}`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      data: { password, confirmPassword },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    if (apiResponse.status === 200) {
      return apiResponse.data;
    } else {
      return apiResponse.response.data;
    }
  } catch (error) {
    return error;
  }
};

const AuthActions = {
  UserLogin,
  UserInvitation,
  UserSignup,
  UpdateProfile,
  UserDeviceMapping,
  ResetPasswordRequest,
  ChangePassword,
};

export default AuthActions;
