import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import './styles.scss';

export default function SeeMore({ viewBy }) {
  return (
    <Card className="seeMoreContainer">
      <div>
        <FontAwesomeIcon
          style={{ fontSize: '30px', height: '30px' }}
          icon={faArrowAltCircleRight}
        />
      </div>
      <br />
      <Link to={`/all?type=post&content=${viewBy}`}>
        <Button variant="outline-danger" size="sm">
          View All
        </Button>
      </Link>
    </Card>
  );
}
