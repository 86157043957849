import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import AuthActions from '../../redux/middleware/auth';
import { useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useHistory } from 'react-router-dom';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [rePassword, setRepassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const { id, token } = useParams();
  const history = useHistory();

  const { isLoading } = useSelector((state) => state.Auth);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!password || !rePassword) {
      // toast.error('Password and Re-Password are required');
      return;
    }
    try {
      const apiResponse = await AuthActions.ChangePassword({
        id,
        token,
        password,
        confirmPassword: rePassword,
      });
      if (apiResponse.status) {
        const { message } = apiResponse;
        const payload = {
          message: message,
        };
        setMessage(payload.message);
        setSuccess(true);
      } else {
        const { message } = apiResponse;
        const payload = {
          message: message,
        };
        setMessage(payload.message);
        setSuccess(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Col xl={6} lg={12} md={12} sm={12} className=" p-5 bg-white full-height">
        <div className="login-main-left">
          <div className="text-center mb-5 login-main-left-header pt-4">
            <img
              src="/img/favicon.png"
              className="img-fluid favLogo"
              alt="LOGO"
              onClick={() => history.push(`/`)}
            />
            <h5 className="mt-3 mb-3">Welcome to TMW</h5>
            <p>
              we want to entertain <br /> the world.
            </p>
          </div>
          {message && (
            <Alert variant={`${success ? 'success' : 'danger'}`}>
              {message}
            </Alert>
          )}
          <Form>
            <Form.Group>
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Re-enter Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setRepassword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit(e);
                  }
                }}
              />
            </Form.Group>

            <div className="mt-4">
              <Row>
                <Col>
                  {!success ? (
                    <Button
                      variant="outline-primary"
                      size="lg"
                      block
                      onClick={onSubmit}
                    >
                      Reset Password
                    </Button>
                  ) : (
                    <Button
                      variant="outline-primary"
                      size="lg"
                      block
                      onClick={() => history.push('/auth/login')}
                    >
                      Return To Login
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {isLoading && <Spinner variant="primary" animation="grow" />}
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </Col>
    </React.Fragment>
  );
}
export default ResetPassword;
