/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import userEngagementActions from 'redux/middleware/userEngagement';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import UnFollowModal from 'components/VideoPage/UnFollowModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faChild,
  faIdBadge,
  faUniversalAccess,
} from '@fortawesome/free-solid-svg-icons';
import { getAge } from 'utils/helpers';
import { defaultColors, somethingWrongMessage } from 'config/constant';
// import { toast } from 'react-toastify';

export default function ProfileNav({
  userInfo,
  loggedInUserInfo,
  setSelectionTab,
  setFollowUnFollowToggle,
  onPressReport,
}) {
  const loginDetails = JSON.parse(localStorage.getItem('__tmw_user'));
  const [selectionState, setSelectionState] = useState('about');
  const [unFollowModal, setUnFollowModal] = useState(false);
  const [requestInfo, setRequestInfo] = useState(null);

  const history = useHistory();
  const handleClose = () => setUnFollowModal(false);
  const { id } = useParams();

  React.useEffect(() => {
    if (id) {
      selectedHandler('about');
    }
  }, [id]);

  const age = getAge(userInfo?.DOB);

  const followHandler = async () => {
    try {
      const apiResponse = await userEngagementActions.followUser(
        loginDetails?.token,
        id
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setFollowUnFollowToggle();
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };

  const unFollowHandlerModal = () => {
    setUnFollowModal(true);
  };

  const selectedHandler = (e) => {
    setSelectionState(e);
    setSelectionTab(e);
  };

  useEffect(() => {
    isFriend();
  }, [userInfo]);

  const isFriend = () => {
    if (userInfo) {
      const { Config } = userInfo;
      if (Config) {
        const { messageRequest } = Config;
        const isRequestExist =
          (messageRequest &&
            messageRequest.length &&
            messageRequest.find((x) => x?.from === loggedInUserInfo?._id)) ||
          null;
        if (isRequestExist) setRequestInfo(isRequestExist);
      }
    }
  };

  const sendMessageReq = async () => {
    try {
      const apiResponse = await userEngagementActions.sendMessageRequest(
        loginDetails?.token,
        userInfo?._id
      );
      if (apiResponse.status) {
        const { status } = apiResponse;
        if (status) {
          setRequestInfo({ status: 'Pending' });
          goToMessages();
        }
      } else {
        // toast.error(apiResponse.message);
      }
    } catch (error) {
      // toast.error(somethingWrongMessage);
    }
  };

  const goToMessages = () => {
    history.push(`/messages?id=${userInfo?._id}`);
  };

  return (
    <React.Fragment>
      <UnFollowModal
        show={unFollowModal}
        handleClose={handleClose}
        setFollowUnFollowToggle={setFollowUnFollowToggle}
      />
      <div className="single-channel-nav">
        <Navbar expand="lg">
          <Navbar.Brand className="channel-brand">
            {userInfo?.FirstName} {userInfo?.LastName}
            {userInfo?.IsAmbassador && (
              <span title="Ambassador">
                <FontAwesomeIcon
                  icon={faUniversalAccess}
                  color={defaultColors.badgeColor}
                  fixedWidth
                />
              </span>
            )}
            {age <= 13 && (
              <span title="Children Account">
                <FontAwesomeIcon
                  icon={faChild}
                  color={defaultColors.childIconColor}
                  fixedWidth
                />
              </span>
            )}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarSupportedContent" />

          <Navbar.Collapse id="navbarSupportedContent">
            <Nav as="ul" className="mr-auto">
              <li
                className={`nav-item ${selectionState === 'about' && 'active'}`}
                onClick={() => selectedHandler('about')}
              >
                <span className="nav-link">About</span>
              </li>
              <li
                className={`nav-item ${
                  selectionState === 'videos' && 'active'
                }`}
                onClick={() => selectedHandler('videos')}
              >
                <span className="nav-link">Videos</span>
              </li>
              {/* userInfo?._id === loggedInUserInfo?._id && (
                <React.Fragment>
                  <li
                    className={`nav-item ${
                      selectionState === 'followers' && 'active'
                    }`}
                    onClick={() => selectedHandler('followers')}
                  >
                    <span className="nav-link">Followers</span>
                  </li>
                  <li
                    className={`nav-item ${
                      selectionState === 'following' && 'active'
                    }`}
                    onClick={() => selectedHandler('following')}
                  >
                    <span className="nav-link">Following</span>
                  </li>
                </React.Fragment>
              ) */}
              {loginDetails?.token && (
                <React.Fragment>
                  <li
                    className={`nav-item ${
                      selectionState === 'followers' && 'active'
                    }`}
                    onClick={() => selectedHandler('followers')}
                  >
                    <span className="nav-link">Followers</span>
                  </li>
                  <li
                    className={`nav-item ${
                      selectionState === 'following' && 'active'
                    }`}
                    onClick={() => selectedHandler('following')}
                  >
                    <span className="nav-link">Following</span>
                  </li>
                </React.Fragment>
              )}
            </Nav>
            <Form inline className=" my-2 my-lg-0">
              {userInfo?._id !== loggedInUserInfo?._id &&
                loginDetails?.token && (
                  <>
                    <Button
                      className="mr-3"
                      variant="outline-danger"
                      size="sm"
                      onClick={
                        userInfo?.isFollow
                          ? unFollowHandlerModal
                          : followHandler
                      }
                    >
                      {userInfo?.isFollow ? `Following` : 'Follow'}
                    </Button>
                    <Button
                      className="mr-3"
                      variant="outline-danger"
                      size="sm"
                      onClick={requestInfo ? goToMessages : sendMessageReq}
                    >
                      {requestInfo ? `Messages` : 'Send Request'}
                    </Button>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => onPressReport(true)}
                    >
                      Report
                    </Button>
                  </>
                )}
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </React.Fragment>
  );
}
