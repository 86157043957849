import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import AuthActions from '../../redux/middleware/auth';
import { useDispatch, useSelector } from 'react-redux';
import {
  loginFailed,
  loginPending,
  loginSuccess,
} from 'redux/reducers/authSlice';
// // import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login({ history }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const { isLoading, error } = useSelector((state) => state.Auth);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      // toast.error('Email and Password are required');
      return;
    }
    dispatch(loginPending());
    try {
      const apiResponse = await AuthActions.UserLogin({
        email: email.toLowerCase(),
        password,
      });
      if (apiResponse.status) {
        const { data, token } = apiResponse;
        const payload = {
          userInfo: data,
          token: token,
        };
        dispatch(loginSuccess(payload));
        localStorage.setItem('__tmw_user', JSON.stringify(payload));
        history.push(`/`);
      } else {
        const { message } = apiResponse;
        const payload = {
          message: message || 'Invalid User Email or Password',
        };
        dispatch(loginFailed(payload));
      }
    } catch (error) {
      dispatch(loginFailed(error));
    }
  };

  return (
    <React.Fragment>
      <Col xl={6} lg={12} md={12} sm={12} className=" p-5 bg-white full-height">
        <div className="login-main-left">
          <div className="text-center mb-5 login-main-left-header pt-4">
            <img
              src="/img/favicon.png"
              className="img-fluid favLogo"
              alt="LOGO"
              onClick={() => history.push(`/`)}
            />
            <h5 className="mt-3 mb-3">Welcome to TMW</h5>
            <p>
              we want to entertain <br /> the world.
            </p>
          </div>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email Address"
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit(e);
                  }
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit(e);
                  }
                }}
              />
            </Form.Group>

            <div className="text-right">
              <p className="light-gray">
                <Link to="/auth/forget-password">Forget Password ?</Link>
              </p>
            </div>

            <div className="mt-4">
              <Row>
                <Col>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    block
                    onClick={onSubmit}
                  >
                    Sign In
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  {isLoading && <Spinner variant="primary" animation="grow" />}
                </Col>
              </Row>
            </div>
          </Form>

          <div className="text-center mt-5">
            <p className="light-gray">
              Don’t have an account?{' '}
              <Link to="/auth/invitation">Request Sign Up</Link>
            </p>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
}
export default Login;
