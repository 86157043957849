import { getContentThumbnailURL } from 'utils/helpers';

export default function ProfileHero({ userInfo }) {
  const getUsernameOrDefault = (name) => {
    if (name === undefined) {
      return '#';
    }
    return name;
  };

  return (
    <div className="single-channel-image">
      <img
        style={{ maxHeight: '400px' }}
        className="img-fluid"
        alt=""
        src={getContentThumbnailURL(
          'coverPhoto',
          userInfo?.Authentication.UserName,
          userInfo?.Config?.CoverPhoto
        )}
      />
      <div className="channel-profile">
        <img
          className="channel-profile-img"
          alt=""
          src={getContentThumbnailURL(
            'photo',
            userInfo?.Authentication.UserName,
            userInfo?.Config?.ProfilePic
          )}
        />
        {userInfo?.SocialLinks &&
          (userInfo?.SocialLinks?.fb !== 'undefined' ||
            userInfo?.SocialLinks?.instagram !== 'undefined' ||
            userInfo?.SocialLinks?.twitter !== 'undefined') && (
            <div className="social hidden-xs">
              My Social &nbsp;
              {userInfo?.SocialLinks?.fb !== 'undefined' && (
                <a
                  className="fb mr-1 fb-background"
                  href={`https://www.facebook.com/${getUsernameOrDefault(
                    userInfo?.SocialLinks?.fb
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              )}
              {userInfo?.SocialLinks?.instagram !== 'undefined' && (
                <a
                  className="tw mr-1 insta-background"
                  href={`https://www.instagram.com/${getUsernameOrDefault(
                    userInfo?.SocialLinks?.instagram
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              )}
              {userInfo?.SocialLinks?.twitter !== 'undefined' && (
                <a
                  className="gp tw-background"
                  href={`https://twitter.com/${getUsernameOrDefault(
                    userInfo?.SocialLinks?.twitter
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              )}
            </div>
          )}
      </div>
    </div>
  );
}
