import { React } from 'react';
import Slider from 'react-slick';
import ChannelCard from 'components/Core/ChannelCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { getContentThumbnailURL, numFormatter } from 'utils/helpers';
function SampleNextArrow(props) {
  return (
    <button
      className="custom-slick-btn custom-slick-next"
      onClick={props.onClick}
    >
      <span>
        <FontAwesomeIcon
          icon={faAngleRight}
          mask={faCircle}
          transform="shrink-7"
        />
      </span>
    </button>
  );
}

function SamplePrevArrow(props) {
  return (
    <button
      className="custom-slick-btn custom-slick-prev"
      onClick={props.onClick}
    >
      <FontAwesomeIcon
        icon={faAngleLeft}
        mask={faCircle}
        transform="shrink-7"
      />
    </button>
  );
}
export default function SearchUsersSlider({
  users,
  setFollowUnFollowToggle,
  followUnFollowToggle,
  // sortCondition,
  // setSortCondition,
}) {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    autoplay: false,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {users &&
          users?.map((item, i) => (
            <ChannelCard
              key={i}
              channelHref={item?._id}
              setFollowUnFollowToggle={() =>
                setFollowUnFollowToggle(!followUnFollowToggle)
              }
              isFollow={item?.isFollow}
              imgSrc={getContentThumbnailURL(
                'photo',
                item?.Authentication?.UserName,
                item?.Config?.ProfilePic
              )}
              views={item?.views}
              channelName={item?.FirstName + ' ' + item?.LastName}
              subscriberCount={numFormatter(item?.followers)}
              postCount={numFormatter(item?.posts_count)}
              outline
            />
          ))}
      </Slider>
    </>
  );
}
