/* eslint-disable no-empty */
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import SectionHeader from 'components/Core/SectionHeader';
import VideoCard from 'components/Core/VideoCard';
import ContentWrapper from 'components/Core/ContentWrapper';
import { useSelector } from 'react-redux';
import postActions from 'redux/middleware/posts';
import { useLocation } from 'react-router-dom';
import {
  getAge,
  getContentThumbnailURL,
  getProfilePicture,
  getVideoThumbnail,
  numFormatter,
  toTime,
} from 'utils/helpers';
import ThinFooter from '../../components/Footer/ThinFooter';
import TopMobileSearch from '../home/components/TopMobileSearch';
import Container from 'react-bootstrap/Container';
import resourceConfiguration from 'redux/middleware/resourceConfiguration';
import { Button } from 'react-bootstrap';
import ChannelCard from 'components/Core/ChannelCard';
import searchActions from 'redux/middleware/search';

function AllSubcategoryItems() {
  const { userDetails } = useSelector((state) => state?.Profile?.UserDetails);
  const location = useLocation();
  const [sortCondition, setSortCondition] = useState('date');
  const from = location?.pathname?.split('/')[2] || 'blah';
  const fromm = location?.state?.fromm;
  const callSpecificApi = location?.state?.callSpecificApi;
  const postsFromState = location?.state?.posts;
  const { id } = useParams();
  const { name } = useParams();
  const [limit, setLimit] = useState(6);
  const [cardDataFeatured, setCardDataFeatured] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [showTrue, setShowTrue] = useState(true);
  const loadMore = () => {
    setLimit(limit + 6);
  };
  const loginDetails = JSON.parse(localStorage.getItem('__tmw_user'));
  // eslint-disable-next-line no-unused-vars
  const [showPosts, setShowPosts] = useState(from);
  const [allPostBySubCategories, setAllPost] = React.useState();
  const [recentPosts, setRecentPost] = React.useState([]);
  const [postsByCountry, setPostByCountry] = React.useState([]);
  const [postsByCountryFlag, setPostByFlag] = React.useState([]);
  const [featuredPost, setFeaturePost] = React.useState([]);
  const [userFollowingPosts, setUserFollowingPost] = React.useState([]);
  const [similarPosts, setSimilarPost] = React.useState([]);
  const [searchAllByKeywords, setSearchResult] = React.useState([]);
  const [subCategoryPosts, setSubCategoryPost] = React.useState([]);
  const [getAllUsers, setAllUsers] = React.useState([]);
  const singleCat = subCategoryPosts?.find(({ subCategories }) =>
    subCategories?.filter((subCat) => subCat.name === showPosts)
  );
  const allMerged = singleCat && singleCat.length && singleCat.flat(1);

  const fetch = async () => {
    try {
      const apiResponse = await postActions.getPostBySubCategory(
        id,
        sortCondition,
        limit
      );

      if (apiResponse.status) {
        const { data } = apiResponse;
        const newCat = subCategoryPosts?.map(({ subCategories }) =>
          subCategories?.filter((subCat) => subCat.name === showPosts)
        );
        const allMerge = newCat && newCat.length && newCat.flat(1);
        if (allMerge.length) {
          setCardDataFeatured(apiResponse.data[0][id].posts);
        }
        setAllPost(data);
      } else {
      }
    } catch (error) {
      // FIXME: Add Logic Here
    }
  };
  const fetchRecentPost = async () => {
    try {
      const apiResponse = await postActions.getRecentPost(sortCondition, limit);

      if (apiResponse?.status) {
        const { data } = apiResponse;
        if (showPosts === 'recent') {
          setCardDataFeatured(apiResponse.data);
        }
        setRecentPost(data);
      } else {
      }
    } catch (error) {
      // FIXME: Add Logic here
    }
  };
  const fetchPostsByCountry = async () => {
    try {
      const apiResponse = await postActions.getPostByCountry(
        sortCondition,
        limit
      );
      if (apiResponse?.status) {
        const { data } = apiResponse;

        if (showPosts === 'country') {
          setCardDataFeatured(apiResponse.data);
        }

        setPostByCountry(data);
      } else {
      }
    } catch (error) {
      // FIXME: Add Logic Here
    }
  };
  const fetchPostsByCountryFlag = async () => {
    try {
      const apiResponse = await postActions.getPostByCountryId(id, limit);
      if (apiResponse?.status) {
        const { data } = apiResponse;
        if (showPosts === 'country') {
          setCardDataFeatured(apiResponse.data);
        }

        setPostByFlag(data);
      } else {
      }
    } catch (error) {
      // FIXME: Add Logic Here
    }
  };
  const fetchFeaturedPost = async () => {
    try {
      const apiResponse = await postActions.getFeaturedPost(
        sortCondition,
        limit
      );
      if (apiResponse?.status) {
        const { data } = apiResponse;
        //setCardDataFeatured(oldArray => [...oldArray, apiResponse.data]);
        if (showPosts === 'featured') {
          setCardDataFeatured(apiResponse.data);
        }
        setFeaturePost(data);
      } else {
      }
    } catch (error) {
      // FIXME
    }
  };
  const fetchUserFollowingPosts = async () => {
    try {
      const apiResponse = await postActions.getUserFollowingPost(
        loginDetails?.token,
        sortCondition,
        limit
      );
      if (apiResponse?.status) {
        const { data } = apiResponse;
        if (showPosts === 'FollowingUsers') {
          setCardDataFeatured(apiResponse.data);
        }
        //setCardDataFeatured(oldArray => [...oldArray, apiResponse.data]);

        setUserFollowingPost(data);
      } else {
      }
    } catch (error) {
      // FIXME
    }
  };
  const fetchSimilarPosts = async () => {
    try {
      const apiResponse = await postActions.getSimilarPost(
        sortCondition,
        userDetails?.Talent?.toString(),
        //  '6331a8d68bb293ef0c8be084',
        userDetails?._id,
        loginDetails?.token,
        limit
      );
      if (apiResponse?.status) {
        const { data } = apiResponse;
        if (showPosts === 'similar') {
          setCardDataFeatured(apiResponse.data);
        }
        setSimilarPost(data);
      } else {
      }
    } catch (error) {
      // FIXME
    }
  };
  const fetchAllUsers = async () => {
    try {
      const apiResponse = await resourceConfiguration.getAllUsers(
        loginDetails?.token,
        limit
      );
      if (apiResponse?.status) {
        const { data } = apiResponse;
        if (showPosts === 'users') {
          setCardDataFeatured(apiResponse.data);
        }
        setAllUsers(data);
      } else {
      }
    } catch (error) {
      // FIXME
    }
  };
  const subCategoryPostss = async () => {
    try {
      const apiResponse = await postActions.getSubCategoryPost();
      if (apiResponse.status) {
        const { data } = apiResponse;
        setSubCategoryPost(data);
      } else {
      }
    } catch (error) {
      // FIXME
    }
  };
  const fetchSearch = async () => {
    try {
      const apiResponse = await searchActions.searchContent(
        sortCondition,
        loginDetails?.token,
        limit,
        { keyword: fromm }
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        if (
          showPosts === 'Hash-Tag-Posts' ||
          showPosts === 'Search-Posts' ||
          showPosts === 'Hashtags'
        ) {
          setCardDataFeatured(apiResponse.data);
        }
        setSearchResult(data);
      } else {
      }
    } catch (error) {
      // FIXME
    }
  };

  useEffect(() => {
    if (callSpecificApi === 'HashTagsPosts') {
      fetchSearch();
    }
    if (callSpecificApi === 'featuredPost') {
      fetchFeaturedPost();
    }
    if (callSpecificApi === 'getAllUsers') {
      fetchAllUsers();
    }
    if (callSpecificApi === 'recentPosts') {
      fetchRecentPost();
    }
    if (callSpecificApi === 'similarPosts') {
      fetchSimilarPosts();
    }
    if (name) {
      fetch();
    }
    if (callSpecificApi === 'userFollowingPosts') {
      fetchUserFollowingPosts();
    }

    if (callSpecificApi === 'postsByCountry') {
      fetchPostsByCountry();
    }

    if (callSpecificApi === 'postsByCountryFlag') {
      fetchPostsByCountryFlag();
    }
    subCategoryPostss();
  }, [limit, sortCondition, callSpecificApi, name]);

  return (
    <ContentWrapper>
      <Container fluid className="pb-0">
        <TopMobileSearch />
        <Row>
          <Col md={12}>
            <SectionHeader
              heading={from}
              setSortCondition={setSortCondition}
              sortCondition={sortCondition}
              noSortFilter={
                allPostBySubCategories &&
                allPostBySubCategories[id] &&
                allPostBySubCategories[id].posts?.length > 0
                  ? false
                  : true
              }
            />
          </Col>
          {allMerged &&
          allMerged.length &&
          allPostBySubCategories &&
          allPostBySubCategories[id] &&
          allPostBySubCategories[id].posts?.length > 0 ? (
            allPostBySubCategories &&
            allPostBySubCategories[id] &&
            allPostBySubCategories[id].posts?.slice(0, limit).map((post) => (
              <Col xl={2} sm={6} className="mb-3" key={post?._id}>
                <VideoCard
                  iconOfType={post?.type}
                  postRef={`/watch/${post?._id}`}
                  imgSrc={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? getVideoThumbnail(post?.images[0])
                      : post?.type === '63778f703e6ea7415d763461'
                      ? getVideoThumbnail(post?.videoImageUrl)
                      : getVideoThumbnail(post?.audioImageUrl)
                  }
                  time={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? post?.images?.length
                      : post?.duration
                  }
                  flag_id={post?.country}
                  videoUserNameId={post?.userId?._id}
                  videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                  videoTitle={post?.title}
                  videoCategory={
                    post?.talentCategory && post?.talentCategory[0]?.name
                  }
                  videoCategoryId={
                    post?.talentCategory && post?.talentCategory[0]?._id
                  }
                  videoSubCategory={
                    post?.talentSubCategory && post?.talentSubCategory[0]?.name
                  }
                  videoSubCategoryId={
                    post?.talentSubCategory && post?.talentSubCategory[0]?._id
                  }
                  views={post?.views}
                  likes={post?.likeCount}
                  timeAgo={moment(post.createdAt)?.fromNow()}
                  verified
                />
                {/* </Link> */}
              </Col>
            ))
          ) : allMerged && allMerged.length ? (
            <Col md={12}>
              <h6 style={{ textAlign: 'center' }}>No posts yet</h6>
            </Col>
          ) : (
            ''
          )}

          {showPosts === 'country' &&
          callSpecificApi !== 'postsByCountryFlag' ? (
            postsByCountry?.slice(0, limit).map((post) => {
              const { userId = null } = post;
              const { IsAmbassador = false, DOB = '' } = userId || {};
              return (
                <Col xl={2} sm={6} className="mb-3" key={post?._id}>
                  <VideoCard
                    iconOfType={post?.type}
                    postRef={`/watch/${post?._id}`}
                    imgSrc={
                      post?.type === '63778f613e6ea7415d76345e'
                        ? getVideoThumbnail(post?.images[0])
                        : post?.type === '63778f703e6ea7415d763461'
                        ? getVideoThumbnail(post?.videoImageUrl)
                        : getVideoThumbnail(post?.audioImageUrl)
                    }
                    time={
                      post?.type === '63778f613e6ea7415d76345e'
                        ? post?.images?.length
                        : post?.duration
                    }
                    flag_id={post?.country}
                    videoUserNameId={post?.userId?._id}
                    videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                    videoTitle={post?.title}
                    videoCategory={
                      post?.talentCategory && post?.talentCategory[0]?.name
                    }
                    videoCategoryId={
                      post?.talentCategory && post?.talentCategory[0]?._id
                    }
                    videoSubCategory={
                      post?.talentSubCategory &&
                      post?.talentSubCategory[0]?.name
                    }
                    videoSubCategoryId={
                      post?.talentSubCategory && post?.talentSubCategory[0]?._id
                    }
                    views={post?.views}
                    likes={post?.likeCount}
                    timeAgo={moment(post.createdAt)?.fromNow()}
                    verified={IsAmbassador}
                    isChild={getAge(DOB) <= 13}
                  />
                  {/* </Link> */}
                </Col>
              );
            })
          ) : showPosts === 'country' &&
            callSpecificApi !== 'postsByCountryFlag' ? (
            <Col md={12}>
              <h6 style={{ textAlign: 'center' }}>No posts yet</h6>
            </Col>
          ) : (
            ''
          )}

          {showPosts === 'Hashtags' ? (
            searchAllByKeywords?.hashtags?.slice(0, limit).map((post) => (
              <Col className="mb-3" key={post?._id}>
                <ChannelCard
                  hashTag={true}
                  imgSrc="img/s1.png"
                  // views={post.name}
                  tagName={post?.name}
                  Count={post?.postCounts}
                  outline
                  //   verified
                />

                {/* </Link> */}
              </Col>
            ))
          ) : showPosts === 'Hashtags' ? (
            <Col md={12}>
              <h6 style={{ textAlign: 'center' }}>No posts yet</h6>
            </Col>
          ) : (
            ''
          )}

          {showPosts === 'Hash-Tag-Posts' ? (
            postsFromState?.slice(0, limit).map((post) => (
              <Col xl={2} sm={6} className="mb-3" key={post?._id}>
                <VideoCard
                  iconOfType={post?.type}
                  postRef={`/watch/${post?._id}`}
                  imgSrc={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? getVideoThumbnail(post?.images[0])
                      : post?.type === '63778f703e6ea7415d763461'
                      ? getVideoThumbnail(post?.videoImageUrl)
                      : getVideoThumbnail(post?.audioImageUrl)
                  }
                  time={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? post?.images?.length
                      : post?.duration
                  }
                  flag_id={post?.country}
                  videoUserNameId={post?.userId?._id}
                  videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                  videoTitle={post?.title}
                  videoCategory={
                    post?.talentCategory && post?.talentCategory[0]?.name
                  }
                  videoCategoryId={
                    post?.talentCategory && post?.talentCategory[0]?._id
                  }
                  videoSubCategory={
                    post?.talentSubCategory && post?.talentSubCategory[0]?.name
                  }
                  videoSubCategoryId={
                    post?.talentSubCategory && post?.talentSubCategory[0]?._id
                  }
                  views={post?.views}
                  likes={post?.likeCount}
                  timeAgo={moment(post.createdAt)?.fromNow()}
                  verified
                />
                {/* </Link> */}
              </Col>
            ))
          ) : showPosts === 'Hash-Tag-Posts' ? (
            <Col md={12}>
              <h6 style={{ textAlign: 'center' }}>No posts yet</h6>
            </Col>
          ) : (
            ''
          )}

          {showPosts === 'Search-Posts' ? (
            postsFromState?.slice(0, limit).map((post) => (
              <Col xl={2} sm={6} className="mb-3" key={post?._id}>
                <VideoCard
                  iconOfType={post?.type}
                  postRef={`/watch/${post?._id}`}
                  imgSrc={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? getVideoThumbnail(post?.images[0])
                      : post?.type === '63778f703e6ea7415d763461'
                      ? getVideoThumbnail(post?.videoImageUrl)
                      : getVideoThumbnail(post?.audioImageUrl)
                  }
                  time={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? post?.images?.length
                      : post?.duration
                  }
                  flag_id={post?.country}
                  videoUserNameId={post?.userId?._id}
                  videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                  videoTitle={post?.title}
                  videoCategory={
                    post?.talentCategory && post?.talentCategory[0]?.name
                  }
                  videoCategoryId={
                    post?.talentCategory && post?.talentCategory[0]?._id
                  }
                  videoSubCategory={
                    post?.talentSubCategory && post?.talentSubCategory[0]?.name
                  }
                  videoSubCategoryId={
                    post?.talentSubCategory && post?.talentSubCategory[0]?._id
                  }
                  views={post?.views}
                  likes={post?.likeCount}
                  timeAgo={moment(post.createdAt)?.fromNow()}
                  verified
                />
                {/* </Link> */}
              </Col>
            ))
          ) : showPosts === 'Search-Posts' ? (
            <Col md={12}>
              <h6 style={{ textAlign: 'center' }}>No posts yet</h6>
            </Col>
          ) : (
            ''
          )}

          {showPosts === 'country' ? (
            postsByCountryFlag?.slice(0, limit).map((post) => {
              const { userId = null } = post;
              const {
                Authentication = null,
                IsAmbassador = false,
                DOB = '',
              } = userId || {};
              return (
                <Col xl={2} sm={6} className="mb-3" key={post?._id}>
                  <VideoCard
                    iconOfType={post?.type}
                    postRef={`/watch/${post?._id}`}
                    imgSrc={getContentThumbnailURL(
                      post?.type,
                      Authentication?.UserName,
                      post
                    )}
                    time={
                      post?.type === '63778f613e6ea7415d76345e'
                        ? post?.images?.length
                        : toTime(post?.duration)
                    }
                    flag_id={post?.country}
                    videoUserNameId={post?.userId?._id}
                    videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                    videoTitle={post?.title}
                    videoCategory={
                      post?.talentCategory && post?.talentCategory[0]?.name
                    }
                    videoCategoryId={
                      post?.talentCategory && post?.talentCategory[0]?._id
                    }
                    videoSubCategory={
                      post?.talentSubCategory &&
                      post?.talentSubCategory[0]?.name
                    }
                    videoSubCategoryId={
                      post?.talentSubCategory && post?.talentSubCategory[0]?._id
                    }
                    views={numFormatter(post?.views)}
                    likes={numFormatter(post?.likeCount)}
                    timeAgo={moment(post.createdAt)?.fromNow()}
                    verified={IsAmbassador}
                    isChild={getAge(DOB) <= 13}
                  />
                  {/* </Link> */}
                </Col>
              );
            })
          ) : showPosts === 'country' ? (
            <Col md={12}>
              <h6 style={{ textAlign: 'center' }}>No posts yet</h6>
            </Col>
          ) : (
            ''
          )}

          {showPosts === 'featured' ? (
            featuredPost?.slice(0, limit).map((post) => (
              <Col xl={2} sm={6} className="mb-3" key={post?._id}>
                <VideoCard
                  iconOfType={post?.type}
                  postRef={`/watch/${post?._id}`}
                  imgSrc={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? getVideoThumbnail(post?.images[0])
                      : post?.type === '63778f703e6ea7415d763461'
                      ? getVideoThumbnail(post?.videoImageUrl)
                      : getVideoThumbnail(post?.audioImageUrl)
                  }
                  time={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? post?.images?.length
                      : post?.duration
                  }
                  flag_id={post?.country}
                  videoUserNameId={post?.userId?._id}
                  videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                  videoTitle={post?.title}
                  videoCategory={
                    post?.talentCategory && post?.talentCategory[0]?.name
                  }
                  videoCategoryId={
                    post?.talentCategory && post?.talentCategory[0]?._id
                  }
                  videoSubCategory={
                    post?.talentSubCategory && post?.talentSubCategory[0]?.name
                  }
                  videoSubCategoryId={
                    post?.talentSubCategory && post?.talentSubCategory[0]?._id
                  }
                  views={post?.views}
                  likes={post?.likeCount}
                  timeAgo={moment(post.createdAt)?.fromNow()}
                  verified
                />
                {/* </Link> */}
              </Col>
            ))
          ) : showPosts === 'featured' ? (
            <Col md={12}>
              <h6 style={{ textAlign: 'center' }}>No posts yet</h6>
            </Col>
          ) : (
            ''
          )}

          {showPosts === 'similar' ? (
            similarPosts?.slice(0, limit).map((post) => (
              <Col xl={2} sm={8} className="mb-3" key={post?._id}>
                <VideoCard
                  iconOfType={post?.type}
                  postRef={`/watch/${post?._id}`}
                  imgSrc={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? getVideoThumbnail(post?.images[0])
                      : post?.type === '63778f703e6ea7415d763461'
                      ? getVideoThumbnail(post?.videoImageUrl)
                      : getVideoThumbnail(post?.audioImageUrl)
                  }
                  time={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? post?.images?.length
                      : post?.duration
                  }
                  flag_id={post?.country}
                  videoUserNameId={post?.userId?._id}
                  videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                  videoTitle={post?.title}
                  videoCategory={
                    post?.talentCategory && post?.talentCategory[0]?.name
                  }
                  videoCategoryId={
                    post?.talentCategory && post?.talentCategory[0]?._id
                  }
                  videoSubCategory={
                    post?.talentSubCategory && post?.talentSubCategory[0]?.name
                  }
                  videoSubCategoryId={
                    post?.talentSubCategory && post?.talentSubCategory[0]?._id
                  }
                  views={post?.views}
                  likes={post?.likeCount}
                  timeAgo={moment(post.createdAt)?.fromNow()}
                  verified
                />
                {/* </Link> */}
              </Col>
            ))
          ) : showPosts === 'similar' ? (
            <Col md={12}>
              <h6 style={{ textAlign: 'center' }}>No posts yet</h6>
            </Col>
          ) : (
            ''
          )}

          {showPosts === 'recent' ? (
            recentPosts?.slice(0, limit).map((post) => (
              <Col xl={2} sm={6} className="mb-3" key={post?._id}>
                <VideoCard
                  iconOfType={post?.type}
                  postRef={`/watch/${post?._id}`}
                  imgSrc={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? getVideoThumbnail(post?.images[0])
                      : post?.type === '63778f703e6ea7415d763461'
                      ? getVideoThumbnail(post?.videoImageUrl)
                      : getVideoThumbnail(post?.audioImageUrl)
                  }
                  time={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? post?.images?.length
                      : post?.duration
                  }
                  flag_id={post?.country}
                  videoUserNameId={post?.userId?._id}
                  videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                  videoTitle={post?.title}
                  videoCategory={
                    post?.talentCategory && post?.talentCategory[0]?.name
                  }
                  videoCategoryId={
                    post?.talentCategory && post?.talentCategory[0]?._id
                  }
                  videoSubCategory={
                    post?.talentSubCategory && post?.talentSubCategory[0]?.name
                  }
                  videoSubCategoryId={
                    post?.talentSubCategory && post?.talentSubCategory[0]?._id
                  }
                  views={post?.views}
                  likes={post?.likeCount}
                  timeAgo={moment(post.createdAt)?.fromNow()}
                  verified
                />
                {/* </Link> */}
              </Col>
            ))
          ) : showPosts === 'recent' ? (
            <Col md={12}>
              <h6 style={{ textAlign: 'center' }}>No posts yet</h6>
            </Col>
          ) : (
            ''
          )}

          {showPosts === 'FollowingUsers' ? (
            userFollowingPosts?.slice(0, limit).map((post) => (
              <Col xl={2} sm={6} className="mb-3" key={post?._id}>
                <VideoCard
                  iconOfType={post?.type}
                  postRef={`/watch/${post?._id}`}
                  imgSrc={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? getVideoThumbnail(post?.images[0])
                      : post?.type === '63778f703e6ea7415d763461'
                      ? getVideoThumbnail(post?.videoImageUrl)
                      : getVideoThumbnail(post?.audioImageUrl)
                  }
                  time={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? post?.images?.length
                      : post?.duration
                  }
                  flag_id={post?.country}
                  videoUserNameId={post?.userId?._id}
                  videoUserName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                  videoTitle={post?.title}
                  videoCategory={
                    post?.talentCategory && post?.talentCategory[0]?.name
                  }
                  videoCategoryId={
                    post?.talentCategory && post?.talentCategory[0]?._id
                  }
                  videoSubCategory={
                    post?.talentSubCategory && post?.talentSubCategory[0]?.name
                  }
                  videoSubCategoryId={
                    post?.talentSubCategory && post?.talentSubCategory[0]?._id
                  }
                  views={post?.views}
                  likes={post?.likeCount}
                  timeAgo={moment(post.createdAt)?.fromNow()}
                  verified
                />
                {/* </Link> */}
              </Col>
            ))
          ) : showPosts === 'FollowingUsers' ? (
            <Col md={12}>
              <h6 style={{ textAlign: 'center' }}>No posts yet</h6>
            </Col>
          ) : (
            ''
          )}

          {showPosts === 'users' ? (
            getAllUsers?.slice(0, limit).map((post, i) => (
              <Col xl={2} sm={6} className="mb-3" key={i}>
                <ChannelCard
                  channelHref={post?._id}
                  isFollow={post?.isFollow}
                  imgSrc={getProfilePicture(post?.Config?.ProfilePic)}
                  views={post?.views}
                  channelName={post?.FirstName + ' ' + post?.LastName}
                  subscriberCount={post?.followers}
                  outline
                  //   verified
                />
              </Col>
            ))
          ) : showPosts === 'users' ? (
            <Col md={12}>
              <h6 style={{ textAlign: 'center' }}>No posts yet</h6>
            </Col>
          ) : (
            ''
          )}

          <div className="container">
            <div className="row">
              <div className="col-md-12 col-md-offset-4 text-center">
                {cardDataFeatured?.length >= 6 && (
                  <div className=" text-center">
                    {showTrue && limit <= cardDataFeatured.length && (
                      <Button className="text-center" onClick={loadMore}>
                        See more
                      </Button>
                    )}
                  </div>
                )}
                {postsFromState?.length >= 6 && (
                  <div className=" text-center">
                    {showTrue && limit <= postsFromState.length && (
                      <Button className="text-center" onClick={loadMore}>
                        See more
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Row>
      </Container>
      <ThinFooter />
    </ContentWrapper>
  );
}
export default AllSubcategoryItems;
