import axios from 'axios';
// // import { toast } from 'react-toastify';

async function CallApi(apiOptions) {
  let apiResponse = {};

  const config = {
    method: apiOptions.method,
    url: apiOptions.endpoint,
    headers: apiOptions.headers,
    data: apiOptions?.data,
    params: apiOptions?.params,
  };

  await axios(config)
    .then((result) => {
      apiResponse = result;
    })
    .catch((error) => {
      apiResponse = error;

      const { status } = error.response;

      if (status === 401) {
        // eslint-disable-next-line no-use-before-define
        // toast.info('Session Expired, Please login again. redirecting...');
        localStorage.removeItem('__tmw_user');
      }
    });

  return apiResponse;
}

const Utils = {
  CallApi,
};

export default Utils;
