import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SectionHeader from 'components/Core/SectionHeader';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import SearchUsersSlider from './SearchUsersSlider';
import ContentPlaceHolderList from 'components/ContentLoader';

function UserViewer({
  users,
  setFollowUnFollowToggle,
  followUnFollowToggle,
  sortCondition,
  setSortCondition,
  isLoading,
}) {
  return (
    <div className="top-category section-padding mb-4">
      <Row>
        <Col md={12}>
          <SectionHeader
            noSortFilter //={users?.length > 0 ? false : true}
            heading="Users"
            sortCondition={sortCondition}
            setSortCondition={setSortCondition}
            noIconLabel
            icon={faEllipsisH}
          />
        </Col>
        <Col md={12}>
          {isLoading ? (
            <ContentPlaceHolderList type="User" />
          ) : (
            <SearchUsersSlider
              users={users}
              setFollowUnFollowToggle={setFollowUnFollowToggle}
              followUnFollowToggle={followUnFollowToggle}
              // sortCondition={sortCondition}
              // setSortCondition={setSortCondition}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default UserViewer;
