import React, { useRef } from 'react';
import AutoCompleteInput from './SearchAutoComplete';
import { Divider } from 'antd';
import searchActions from 'redux/middleware/search';
// import { somethingWrongMessage } from 'config/constant';
// import { toast } from 'react-toastify';
import userEngagementActions from 'redux/middleware/userEngagement';
import { getContentThumbnailURL } from 'utils/helpers';
import {
  getDatabase,
  onValue,
  ref,
  remove,
  child,
  push,
} from 'firebase/database';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBTypography,
  MDBTextArea,
  MDBCardHeader,
} from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import './styles.scss';
import { firebaseApp } from 'firebaseInit';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';

export default function ChatApp() {
  const [searchResult, setSearchResult] = React.useState();
  const [dataSearch, setDataSearch] = React.useState('');
  const [userList, setUserList] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [messages, setMessages] = React.useState({});
  const [activeSelectedUser, setActiveSelectedUser] = React.useState(null);
  const [messageText, setMessageText] = React.useState('');
  const userLoggedIn = JSON.parse(localStorage.getItem('__tmw_user'));
  const [showMessages, setShowMessages] = React.useState(false);
  const { width } = window.screen;
  const { id = null } = useParams();

  const database = getDatabase(
    firebaseApp,
    'https://talentmw-898e5-default-rtdb.firebaseio.com/'
  );
  const msgReqRef = ref(
    database,
    `messageRequest/${userLoggedIn?.userInfo?._id}`
  );
  const msgReqToRef = ref(
    database,
    `messageRequest/${activeSelectedUser?.userId}`
  );
  const msgRef = ref(database, `messages/`);
  const messagesEndRef = useRef(null);

  React.useEffect(() => {
    onValue(msgReqRef, (snapshot) => {
      const notifications = snapshot.val();
      const List = [];
      for (let id in notifications) {
        List.push({ id, ...notifications[id] });
      }
      getMessageRequests(messages);
    });

    const msgChildRef = child(msgRef, `${userLoggedIn?.userInfo?._id}`);
    onValue(msgChildRef, (snapshot) => {
      const msgs = snapshot.val();
      let List = null;
      for (let id in msgs) {
        List = { ...List, [id]: Object.values(msgs[id]) };
      }
      if (List && Object.keys(List).length) {
        setMessages(List);
        getMessageRequests(List);
      }
    });
  }, []);

  React.useEffect(() => {
    if (dataSearch.length > 2) searchUser(dataSearch);
  }, [dataSearch]);

  React.useEffect(() => {
    if (selectedUser) {
      const isExist =
        userList.length > 1 &&
        userList.filter(
          (x) =>
            x?.userId === selectedUser?.userId ||
            x?.from === selectedUser?.userId ||
            x?.to === selectedUser?.userId
        ).length > 0;
      if (!isExist) {
        setDataSearch('');
        const updatedList = [selectedUser, ...userList];
        setUserList(updatedList);
      } else {
        const userExist = userList.find(
          (x) => x.userId === selectedUser.userId
        );
        setActiveSelectedUser(userExist);
      }
    }
  }, [selectedUser]);

  React.useEffect(() => {
    if (activeSelectedUser && activeSelectedUser?.status === '')
      checkUserStatus();
  }, [activeSelectedUser]);

  const selectedMessages = messages[activeSelectedUser?.chatId] || [];

  React.useEffect(() => {
    if (selectedMessages?.length > 3) scrollToBottom();
  }, [selectedMessages]);

  const scrollToBottom = () => {
    // { behavior: 'smooth' }
    messagesEndRef.current.scrollIntoView();
  };

  const searchUser = async (keyword) => {
    try {
      const apiResponse = await searchActions.searchContent(
        'date',
        userLoggedIn?.token,
        15,
        { keyword }
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setSearchResult(data);
      } else {
        // toast.error(apiResponse.message);
      }
    } catch (error) {
      console.log(error);
      // toast.error(somethingWrongMessage);
    }
  };

  const checkUserStatus = async () => {
    try {
      const apiResponse = await userEngagementActions.checkUserChatStatus(
        userLoggedIn?.token,
        activeSelectedUser?.userId
      );
      if (apiResponse.status) {
        const { request, status } = apiResponse;
        const index = userList.findIndex(
          (x) => x?.id === activeSelectedUser?.userId
        );
        if (index > -1) {
          const list = [...userList];
          let userInfo = list[index];
          userInfo = { ...userInfo, status: status ? '' : 'Rejected', request };
          list[index] = userInfo;
          setUserList(list);
        }
      } else {
        // toast.error(apiResponse.message);
      }
    } catch (error) {
      console.log(error);
      // toast.error(somethingWrongMessage);
    }
  };

  const getMessageRequests = async (List) => {
    try {
      const apiResponse = await userEngagementActions.getMessageRequests(
        userLoggedIn?.token
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let message = null;
          if (Object.keys(List).length && element?.chatId) {
            message = Object.values(List[element?.chatId]).pop();
          }
          const item = { ...element, ...message };
          data[index] = item;
        }
        setUserList(
          data
            .sort((objA, objB) => {
              const d1 = new Date(objA.dt).getTime();
              const d2 = new Date(objB.dt).getTime();

              return d1 - d2;
            })
            .reverse()
        );
        if (data.length > 0 && width > 700) {
          if (!activeSelectedUser && id) {
            const selectedUser = data.find((x) => x?.userId === id);
            setActiveSelectedUser(selectedUser);
          } else if (!activeSelectedUser && !id) setActiveSelectedUser(data[0]);
        }
      } else {
        // toast.error(apiResponse.message);
      }
    } catch (error) {
      console.log(error);
      // toast.error(somethingWrongMessage);
    }
  };

  const sendMessageReq = async () => {
    try {
      const apiResponse = await userEngagementActions.sendMessageRequest(
        userLoggedIn?.token,
        activeSelectedUser?.userId
      );
      if (apiResponse.status) {
        const { status } = apiResponse;
        if (status) {
          setActiveSelectedUser({ ...activeSelectedUser, status: 'Pending' });
          getMessageRequests(messages);
        }
      } else {
        // toast.error(apiResponse.message);
      }
    } catch (error) {
      console.log(error);
      // toast.error(somethingWrongMessage);
    }
  };

  const AcceptMessageReq = async () => {
    try {
      const chatId = activeSelectedUser?.chatId
        ? activeSelectedUser?.chatId
        : `${userLoggedIn?.userInfo?._id}0-${activeSelectedUser?.userId}`;

      const apiResponse = await userEngagementActions.acceptChatReq(
        userLoggedIn?.token,
        activeSelectedUser?.msgId,
        activeSelectedUser?.userId,
        chatId
      );
      if (apiResponse.status) {
        const { status } = apiResponse;
        if (status) {
          setActiveSelectedUser({
            ...activeSelectedUser,
            status: 'Accepted',
            updatedBy: userLoggedIn?.userInfo?._id,
            chatId,
          });
          getMessageRequests(messages);
        }
      } else {
        // toast.error(apiResponse.message);
      }
    } catch (error) {
      console.log(error);
      // toast.error(somethingWrongMessage);
    }
  };

  const RejectMessageReq = async () => {
    try {
      const chatId = activeSelectedUser?.chatId
        ? activeSelectedUser?.chatId
        : `${activeSelectedUser?.to || userLoggedIn?.userInfo?._id}0-${
            activeSelectedUser?.from || userLoggedIn?.userInfo?._id
          }`;
      const apiResponse = await userEngagementActions.rejectChatReq(
        userLoggedIn?.token,
        activeSelectedUser?.msgId,
        activeSelectedUser?.userId,
        chatId
      );
      if (apiResponse.status) {
        const { status } = apiResponse;
        if (status) {
          setActiveSelectedUser({
            ...activeSelectedUser,
            status: 'Rejected',
            updatedBy: userLoggedIn?.userInfo?._id,
            chatId,
          });
          getMessageRequests(messages);
        }
      } else {
        // toast.error(apiResponse.message);
      }
    } catch (error) {
      console.log(error);
      // toast.error(somethingWrongMessage);
    }
  };

  const renderRequestAction = (status) => {
    switch (status) {
      case '':
        return (
          <MDBBtn color="success" rounded onClick={() => sendMessageReq()}>
            Say Hi !!
          </MDBBtn>
        );
      case 'Waiting':
        return (
          <div>
            <MDBBtn
              color="info"
              rounded
              onClick={() => AcceptMessageReq()}
              className="mr-3"
            >
              Accept
            </MDBBtn>
            <MDBBtn color="danger" rounded onClick={() => RejectMessageReq()}>
              Reject
            </MDBBtn>
          </div>
        );
      case 'Pending': {
        return <p className="small text-muted">Request Sent</p>;
      }
      case 'Rejected':
        return (
          <p className="small text-muted">{`You can't communicate with this user`}</p>
        );
      case 'Accepted':
        return (
          <p className="small text-muted">
            You can chat with this user now, send him a message to start your
            conversation
          </p>
        );
      default:
        break;
    }
  };

  const renderRequestPopup = () => {
    const { to, dt } = activeSelectedUser;
    return (
      <>
        <div className="MessageRequestContainer">
          <p>Start making new connections send message request</p>
          <img
            src={getContentThumbnailURL(
              'photo',
              activeSelectedUser?.userName,
              activeSelectedUser?.thumbnail
            )}
            alt="avatar"
            className="rounded-circle d-flex align-self-center shadow-1-strong"
            width="60"
          />
          <h6 className="pt-3">{activeSelectedUser?.userName}</h6>
          {renderRequestAction(activeSelectedUser?.status)}
        </div>
        <div className="mt-5">
          {renderMessageItem(
            {
              to,
              dt,
              text: `Hi, What's up ? I would like to connect with you to talk about content`,
            },
            0
          )}
        </div>
      </>
    );
  };

  const getRequestStatus = (status) => {
    switch (status) {
      case 'Pending':
        return 'Request Sent';
      case 'Waiting':
        return 'Request Requires Action';
      case 'Accepted':
        return 'Chat Friend';
      case 'Rejected':
        return 'Request Disapproved';
      default:
        return 'Send Message Request - Draft';
    }
  };

  const sendMessage = () => {
    if (messageText !== '') {
      const { userId, chatId } = activeSelectedUser;
      const msgToChildRef = child(msgRef, `${userId}/${chatId}`);
      push(msgToChildRef, {
        to: userId,
        text: messageText,
        dt: moment().toString(),
      });
      push(msgReqToRef, {
        to: userId,
        from: userLoggedIn?.userInfo?._id,
        text: messageText,
        dt: moment().toString(),
      });
      const msgChildRef = child(
        msgRef,
        `${userLoggedIn?.userInfo?._id}/${chatId}`
      );
      push(msgChildRef, {
        to: userId,
        text: messageText,
        dt: moment().toString(),
      });
      sendMessageNotifyEmail();
      setMessageText('');
    }
  };

  const sendMessageNotifyEmail = async () => {
    try {
      const { FirstName, LastName } = userLoggedIn?.userInfo;
      console.log(activeSelectedUser?.userId, userLoggedIn?.userInfo?._id);
      const apiResponse = await userEngagementActions.sendMessageEmail(
        userLoggedIn?.token,
        `${FirstName} ${LastName}`,
        activeSelectedUser?.userId,
        userLoggedIn?.userInfo?._id
      );
      if (apiResponse.status) {
        const { status } = apiResponse;
        if (status) {
          console.log('email sent');
        }
      } else {
        // toast.error(apiResponse.message);
      }
    } catch (error) {
      console.log(error);
      // toast.error(somethingWrongMessage);
    }
  };

  const clearConversation = () => {
    const msgChildRef = child(
      msgRef,
      `${userLoggedIn?.userInfo?._id}/${activeSelectedUser.chatId}`
    );
    remove(msgChildRef);
  };

  const renderMessageItem = (item, index) => {
    if (
      (item.to === undefined && item.dt !== undefined) ||
      (item.dt !== undefined && item.to !== activeSelectedUser?.userId)
    ) {
      return (
        <li className="d-flex mb-4 firstUser" key={index}>
          <img
            src={getContentThumbnailURL(
              'photo',
              activeSelectedUser?.userName,
              activeSelectedUser?.thumbnail
            )}
            alt="avatar"
            className="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
            width="60"
          />
          <MDBCard>
            <MDBCardHeader className="d-flex justify-content-between p-3">
              <p className="fw-bold mb-0">{activeSelectedUser?.userName}</p>
              <p className="text-muted small mb-0">
                <MDBIcon far icon="clock" /> {moment(item?.dt).fromNow()}
              </p>
            </MDBCardHeader>
            <MDBCardBody>{item?.text}</MDBCardBody>
          </MDBCard>
        </li>
      );
    }

    return (
      <li className="d-flex mb-4 secondUser" key={index}>
        <MDBCard>
          <MDBCardHeader className="d-flex justify-content-between p-3">
            <p className="fw-bold mb-0">You</p>
            <p className="text-muted small mb-0">
              <MDBIcon far icon="clock" /> {moment(item?.dt).fromNow()}
            </p>
          </MDBCardHeader>
          <MDBCardBody>
            <p className="mb-0">{item?.text}</p>
          </MDBCardBody>
        </MDBCard>
      </li>
    );
  };

  const renderDesktopView = () => {
    return (
      <MDBRow>
        <MDBCol md="6" lg="5" xl="4" className="mb-4 mb-md-0">
          <MDBCard>
            <MDBCardBody className="chatUserList">
              <MDBTypography listUnStyled className="mb-0">
                <AutoCompleteInput
                  searchResult={searchResult}
                  onChangeText={setDataSearch}
                  dataSearch={dataSearch}
                  onSelectUser={setSelectedUser}
                />
                <Divider className="mb-2 mt-1" />
                {userList.length > 0 &&
                  userList.map((item, index) => (
                    <li
                      className={`p-2 border-bottom userItem ${
                        activeSelectedUser?.userId == item?.userId
                          ? 'activeUser'
                          : ''
                      }`}
                      key={index}
                      onClick={() => setActiveSelectedUser(item)}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-row">
                          <img
                            src={getContentThumbnailURL(
                              'photo',
                              item?.userName,
                              item?.thumbnail
                            )}
                            alt="avatar"
                            className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                            width="60"
                          />
                          <div className="pt-1">
                            <p className="fw-bold mb-0">{item?.userName}</p>
                            <p className="small text-muted">
                              {getRequestStatus(item?.status)}
                            </p>
                          </div>
                        </div>
                        <div className="pt-1">
                          <p className="small text-muted mb-1">
                            {moment(item?.dt).fromNow()}
                          </p>
                          {/* <span className="badge bg-danger float-end">1</span> */}
                        </div>
                      </div>
                    </li>
                  ))}
              </MDBTypography>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol md="6" lg="7" xl="8">
          <MDBTypography listUnStyled className="chatUserInbox">
            {selectedMessages?.length < 1 &&
              activeSelectedUser &&
              renderRequestPopup()}
            {selectedMessages?.length > 0 &&
              selectedMessages.map((item, index) =>
                renderMessageItem(item, index)
              )}
            <div ref={messagesEndRef} />
          </MDBTypography>
          <MDBTypography listUnStyled>
            <li className="bg-white mb-3">
              <MDBTextArea
                label="Message"
                id="textAreaExample"
                rows={4}
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                disabled={activeSelectedUser?.status !== 'Accepted'}
              />
            </li>
            <MDBBtn
              color="info"
              rounded
              className="float-start mr-2"
              disabled={activeSelectedUser?.status !== 'Accepted'}
              onClick={clearConversation}
            >
              Clear
            </MDBBtn>
            {activeSelectedUser?.status === 'Accepted' && (
              <MDBBtn
                color="danger"
                rounded
                className="float-start"
                onClick={RejectMessageReq}
              >
                Block
              </MDBBtn>
            )}
            {activeSelectedUser?.status === 'Rejected' &&
              userLoggedIn?.userInfo?._id === activeSelectedUser?.updatedBy && (
                <MDBBtn
                  color="danger"
                  rounded
                  className="float-start"
                  onClick={AcceptMessageReq}
                >
                  Unblock
                </MDBBtn>
              )}
            {activeSelectedUser?.status === 'Rejected' &&
              userLoggedIn?.userInfo?._id !== activeSelectedUser?.updatedBy && (
                <span style={{ color: 'red' }}>
                  You are blocked, You cannot sent message
                </span>
              )}
            <MDBBtn
              color="success"
              rounded
              className="float-end"
              disabled={activeSelectedUser?.status !== 'Accepted'}
              onClick={sendMessage}
            >
              Send
            </MDBBtn>
          </MDBTypography>
        </MDBCol>
      </MDBRow>
    );
  };

  const renderMobileView = () => {
    return (
      <MDBRow>
        {!showMessages && (
          <MDBCol md="6" lg="5" xl="4" className="mb-4 mb-md-0">
            <MDBCard>
              <MDBCardBody className="chatUserList">
                <MDBTypography listUnStyled className="mb-0">
                  <AutoCompleteInput
                    searchResult={searchResult}
                    onChangeText={setDataSearch}
                    dataSearch={dataSearch}
                    onSelectUser={setSelectedUser}
                  />
                  <Divider className="mb-2 mt-1" />
                  {userList.length > 0 &&
                    userList.map((item, index) => (
                      <li
                        className={`p-2 border-bottom userItem ${
                          activeSelectedUser?.userId == item?.userId
                            ? 'activeUser'
                            : ''
                        }`}
                        key={index}
                        onClick={() => {
                          setActiveSelectedUser(item);
                          setShowMessages(true);
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-row">
                            <img
                              src={getContentThumbnailURL(
                                'photo',
                                item?.userName,
                                item?.thumbnail
                              )}
                              alt="avatar"
                              className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                              width="60"
                            />
                            <div className="pt-1">
                              <p className="fw-bold mb-0">{item?.userName}</p>
                              <p className="small text-muted">
                                {getRequestStatus(item?.status)}
                              </p>
                            </div>
                          </div>
                          <div className="pt-1 msg_nav_icon">
                            {/* <p className="small text-muted mb-1">Just now</p> 
                        <span className="badge bg-danger float-end">1</span> */}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </div>
                        </div>
                      </li>
                    ))}
                </MDBTypography>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        )}
        {showMessages && (
          <>
            <MDBCol md="6" lg="7" xl="8">
              <MDBCard>
                <MDBCardBody>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-row">
                      <div className="pt-1">
                        <p className="fw-bold mb-0">
                          {activeSelectedUser?.userName}
                        </p>
                      </div>
                    </div>
                    <div
                      className="pt-1 msg_nav_icon"
                      onClick={() => {
                        setShowMessages(false);
                        setActiveSelectedUser(null);
                      }}
                    >
                      {/* <p className="small text-muted mb-1">Just now</p> 
                        <span className="badge bg-danger float-end">1</span> */}
                      <span className="pr-2">Back To List</span>
                      <FontAwesomeIcon icon={faSignOutAlt} />
                    </div>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol md="6" lg="7" xl="8">
              <MDBTypography listUnStyled className="chatUserInbox">
                {selectedMessages?.length < 1 &&
                  activeSelectedUser &&
                  renderRequestPopup()}
                {selectedMessages?.length > 0 &&
                  selectedMessages.map((item, index) =>
                    renderMessageItem(item, index)
                  )}
                <div ref={messagesEndRef} />
              </MDBTypography>
              <MDBTypography listUnStyled>
                <li className="bg-white mb-3">
                  <MDBTextArea
                    label="Message"
                    id="textAreaExample"
                    rows={4}
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                    disabled={activeSelectedUser?.status !== 'Accepted'}
                  />
                </li>
                <MDBBtn
                  color="info"
                  rounded
                  className="float-start mr-2"
                  disabled={activeSelectedUser?.status !== 'Accepted'}
                  onClick={clearConversation}
                >
                  Clear
                </MDBBtn>
                {activeSelectedUser?.status === 'Accepted' && (
                  <MDBBtn
                    color="danger"
                    rounded
                    className="float-start"
                    onClick={RejectMessageReq}
                  >
                    Block
                  </MDBBtn>
                )}
                {activeSelectedUser?.status === 'Rejected' && (
                  <MDBBtn
                    color="danger"
                    rounded
                    className="float-start"
                    onClick={AcceptMessageReq}
                  >
                    Unblock
                  </MDBBtn>
                )}
                <MDBBtn
                  color="success"
                  rounded
                  className="float-end"
                  disabled={activeSelectedUser?.status !== 'Accepted'}
                  onClick={sendMessage}
                >
                  Send
                </MDBBtn>
              </MDBTypography>
            </MDBCol>
          </>
        )}
      </MDBRow>
    );
  };

  return (
    <MDBContainer className="chatContainer">
      {width > 700 && renderDesktopView()}
      {width <= 700 && renderMobileView()}
    </MDBContainer>
  );
}
