import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

function TopMobileSearch() {
  return (
    <div className="top-mobile-search">
      <Row>
        <Col md={12}>
          <Form className="mobile-search ">
            {/* <InputGroup>
              <FormControl
                placeholder="Search for..."
                className=""
                value={dataSearch}
                type="text"
                onChange={e => setDataSearch(e.target.value)}
              />
              <InputGroup.Append>
                <Button variant="light">
                  <Link
                    //to="/search-by-keyword">
                    to={{
                      pathname: `/search-by-keyword`,
                      state: { from: dataSearch }
                    }}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Link>
                </Button>
              </InputGroup.Append>
            </InputGroup> */}
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default TopMobileSearch;
