/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VideoCardList from './VideoCardList';
import SectionHeader from '../Core/SectionHeader';
import { useEffect, useState } from 'react';
import {
  getContentThumbnailURL,
  getVideoThumbnail,
  numFormatter,
  toTime,
} from 'utils/helpers';
import postActions from 'redux/middleware/posts';
import moment from 'moment';

const SingleVideoRight = ({ userSinglePost }) => {
  const [sortCondition, setSortByCondition] = useState('date');
  const categoryName = userSinglePost?.talentCategory[0]?._id;
  const [postsByCategory, setPostByCountry] = useState();
  const apiPostByCategory = async () => {
    try {
      const apiResponse = await postActions.getPostByCategory(
        userSinglePost?.talentCategory[0]._id,
        sortCondition
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setPostByCountry(data);
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };

  useEffect(() => {
    userSinglePost && apiPostByCategory();
  }, [userSinglePost, sortCondition]);

  return (
    <div className="single-video-right">
      <Row>
        <Col md={12} className="rightOptionList">
          <SectionHeader
            heading="More Like This"
            setSortCondition={setSortByCondition}
            sortCondition={sortCondition}
          />
        </Col>
        <Col md={12} className="rightOptionList">
          <div className="video-slider-right-list">
            {postsByCategory &&
              postsByCategory[0][categoryName] &&
              postsByCategory[0][categoryName].posts?.map((post) => (
                <VideoCardList
                  postHref={`/watch/${post?._id}`}
                  key={post?._id}
                  imgSrc={getContentThumbnailURL(
                    post?.type,
                    post?.userId?.Authentication?.UserName,
                    post
                  )}
                  time={
                    post?.type === '63778f613e6ea7415d76345e'
                      ? post?.images.length
                      : toTime(post?.duration)
                  }
                  flag_id={post?.country}
                  iconOfType={post?.type}
                  videoTitle={post?.title}
                  userName={`${post?.userId?.FirstName} ${post?.userId?.LastName}`}
                  views={numFormatter(post?.views)}
                  likes={numFormatter(post?.likeCount)}
                  videoUserNameId={post?.userId?._id}
                  timeAgo={moment(post.createdAt).fromNow()}
                  videoCategory={
                    post?.talentCategory[0] && post?.talentCategory[0].name
                  }
                  videoCategoryId={
                    post?.talentCategory[0] && post?.talentCategory[0]._id
                  }
                  videoSubCategory={
                    post?.talentSubCategory[0] &&
                    post?.talentSubCategory[0].name
                  }
                  videoSubCategoryId={
                    post?.talentSubCategory[0] && post?.talentSubCategory[0]._id
                  }
                  verified
                />
              ))}
          </div>

          {/* <AdBlock /> */}
        </Col>
      </Row>
    </div>
  );
};

function AdBlock() {
  return (
    <div className="adblock">
      <div className="img">
        Google AdSense
        <br />
        336 x 280
      </div>
    </div>
  );
}

export default SingleVideoRight;
export { AdBlock };
