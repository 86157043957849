import { configureStore } from '@reduxjs/toolkit';

/* reducers */
import AuthReducer from './reducers/authSlice';
import ProfileReducer from './reducers/profileSlice';

export const store = configureStore({
  reducer: {
    Auth: AuthReducer,
    Profile: ProfileReducer,
  },
});
