import React, { useState } from 'react';
import './UpdateProfile.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ThinFooter from '../../components/Footer/ThinFooter';
import ContentWrapper from '../../components/Core/ContentWrapper';
import { HeadingWrapper } from '../../components/Core/SectionHeader';
import UpdateProfileForm from './UpdateProfileForm';
import { useSelector } from 'react-redux';
import UpdateProfileHero from './UpdateProfileHero';
import UpdateProfileNav from './UpdateProfileNav';
import { getContentThumbnailURL } from 'utils/helpers';

const UpdateProfile = () => {
  const { userDetails } = useSelector((state) => state.Profile.UserDetails);
  const [coverImageSelect, setCoverImageSelect] = useState('');
  const [profileImageSelect, setProfileImageSelect] = useState('');
  const [coverImageFile, setCoverImageFile] = useState();
  const [profileImageFile, setProfileImageFile] = useState();
  const [coverImageState, setCoverImageState] = useState(true);
  const [profileImageState, setprofileImageState] = useState(true);

  React.useEffect(() => {
    setProfileImageSelect(
      getContentThumbnailURL(
        'photo',
        userDetails?.Authentication.UserName,
        userDetails?.Config?.ProfilePic
      )
    );

    setCoverImageSelect(
      getContentThumbnailURL(
        'coverPhoto',
        userDetails?.Authentication.UserName,
        userDetails?.Config?.CoverPhoto
      )
    );
  }, [userDetails]);

  return (
    <React.Fragment>
      <ContentWrapper className="single-channel-page">
        <UpdateProfileHero
          coverImg={coverImageSelect}
          profileImg={profileImageSelect}
          userInfo={userDetails}
          setCoverImageSelect={setCoverImageSelect}
          setprofileImageSelect={setProfileImageSelect}
          coverImageFile={coverImageFile}
          profileImageFile={profileImageFile}
          coverImageState={coverImageState}
          profileImageState={profileImageState}
        />
        <UpdateProfileNav
          userInfo={userDetails}
          loggedInUserInfo={userDetails}
          setCoverImageFile={setCoverImageFile}
          setProfileImageFile={setProfileImageFile}
          setCoverImageState={setCoverImageState}
          setprofileImageState={setprofileImageState}
          setCoverImageSelect={setCoverImageSelect}
          setprofileImageSelect={setProfileImageSelect}
        />
        <Container fluid className="upload-details">
          <Row>
            <Col lg={12}>
              <HeadingWrapper heading="Update Profile" />
            </Col>
          </Row>
          <hr />

          <UpdateProfileForm
            userDetails={userDetails}
            coverImageSelect={coverImageSelect}
            profileImageSelect={profileImageSelect}
          />
        </Container>
      </ContentWrapper>
      <ThinFooter />
    </React.Fragment>
  );
};

export default UpdateProfile;
