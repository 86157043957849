import { Link } from 'react-router-dom';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Calendar } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  loginSuccess,
  signupFailed,
  signupPending,
  signupSuccess,
} from 'redux/reducers/authSlice';
import AuthActions from 'redux/middleware/auth';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import {
  CityFailed,
  CityPending,
  CitySuccess,
} from 'redux/reducers/resourceSlice';
import resourceConfiguration from 'redux/middleware/resourceConfiguration';

function Signup() {
  const [openDate, setOpenDate] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [countryId, setCountry] = useState('');
  const [cityId, setCity] = useState('');
  const [gender, setGender] = useState('');
  const [userName, setUserName] = useState('');
  const [parentName, setParentName] = useState(null);
  const [parentEmail, setParentEmail] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isParental, setIsParental] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  let data;

  const { Country, City } = useSelector((state) => state.Profile.Resources);

  // const decode = jwt_decode(token);

  var dateInput = new Date(dob);
  //calculate month difference from current date in time
  var month_diff = Date.now() - dateInput.getTime();

  //convert the calculated difference in date format
  var age_dt = new Date(month_diff);

  //extract year from date
  var year = age_dt.getUTCFullYear();

  //now calculate the age of the user
  var age = Math.abs(year - 1970);

  const checkValid = () => {
    if (
      !firstName ||
      !lastName ||
      !dob ||
      !gender ||
      !countryId ||
      !cityId ||
      !userName ||
      !password
    ) {
      // toast.error('All fields are required');
      return false;
    }
    if (isParental && (!parentName || !parentEmail)) {
      // toast.error('Parent details required');
      return false;
    }
    if (password.length > 7) {
      if (password.trim() !== confirmPassword.trim()) {
        // toast.error('Password not matched');
        return false;
      }
    } else {
      // toast.error('Password length should be minimum 8 characters');
      return false;
    }
    return true;
  };

  const submitHandler = async () => {
    const isValid = checkValid();

    if (isValid) {
      if (age < 13) {
        setIsParental(true);
      }
      if (isParental) {
        if (parentName && parentEmail) {
          data = {
            firstName,
            lastName,
            dob,
            gender,
            countryId,
            cityId,
            country: Country.find((x) => x._id === countryId)?.name || '',
            city: City.find((x) => x?._id === cityId)?.name || '',
            userName,
            password: password.trim(),
            isInvited: id ? true : false,
            invitationKey: window.location.pathname.split('register/')[1],
            isParental: true,
            parentName,
            parentEmail,
          };
        }
      } else {
        data = {
          firstName,
          lastName,
          dob,
          gender,
          countryId,
          cityId,
          country: Country.find((x) => x._id === countryId)?.name || '',
          city: City.find((x) => x?._id === cityId)?.name || '',
          userName,
          password: password.trim(),
          isInvited: id ? true : false,
          invitationKey: window.location.pathname.split('register/')[1],
          isParental: false,
        };
      }
      try {
        dispatch(signupPending());
        setLoading(true);
        const apiResponse = await AuthActions.UserSignup(data);
        if (apiResponse.status) {
          dispatch(signupSuccess());
          toast.success(apiResponse.message + ', Setting up your profile');
          const loginApiResponse = await AuthActions.UserLogin({
            email: apiResponse.data.Authentication.Email,
            password: password.trim(),
          });
          const { data, token } = loginApiResponse;
          const payload = {
            userInfo: data,
            token: token,
          };
          dispatch(loginSuccess(payload));
          setLoading(false);
          toast.success(loginApiResponse.message);
          localStorage.setItem('__tmw_user', JSON.stringify(payload));
          setTimeout(() => {
            history.push(`/profile/${data?._id}`);
          }, 3000);
        } else {
          const payload = {
            message: apiResponse.message,
          };
          setLoading(false);
          dispatch(signupFailed(payload));
          // toast.error(payload.message);
        }
      } catch (error) {
        setLoading(false);
        dispatch(signupFailed(error));
      }
    }
  };

  const fetchCities = async () => {
    dispatch(CityPending());
    try {
      const apiResponse = await resourceConfiguration.getCities(countryId);

      if (apiResponse.status) {
        const { data } = apiResponse;
        const payload = {
          City: data,
        };
        dispatch(CitySuccess(payload));
      } else {
        const payload = {
          message: apiResponse.message,
        };
        dispatch(CityFailed(payload));
      }
    } catch (error) {
      dispatch(CityFailed(error));
    }
  };

  useEffect(() => {
    if (age < 13) {
      setIsParental(true);
    }
    if (age > 13) {
      setIsParental(false);
    }
  }, [dob]);

  useEffect(() => {
    if (countryId) {
      fetchCities();
    }
  }, [countryId]);

  return (
    <React.Fragment>
      <Col
        xl={6}
        lg={12}
        md={12}
        sm={12}
        className=" p-5 bg-white full-height leftContainer"
      >
        <div className="signUp-main-left">
          <div className="text-center mb-5 login-main-left-header pt-4">
            <img
              src="/img/favicon.png"
              className="img-fluid favLogo"
              alt="LOGO"
              onClick={() => history.push(`/`)}
            />
            <h5 className="mt-3 mb-3">Sign Up to Join</h5>
          </div>

          <Form className="w-100">
            <div
              className="d-flex justify-content-between w-100"
              style={{ gap: '20px' }}
            >
              <div style={{ flex: 1 }}>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g. 'James'"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g. 'Ellsworth'"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  style={{
                    position: 'relative',
                    zIndex: 1,
                  }}
                >
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={dob.toString().split('00:')[0]}
                    onClick={() => setOpenDate(!openDate)}
                    style={{
                      background: 'white',
                    }}
                  />
                  {openDate && (
                    <Calendar
                      date={dob || new Date()}
                      onChange={(e) => {
                        setDob(e);
                        setOpenDate(!openDate);
                      }}
                      className="date"
                    />
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  >
                    <option>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  >
                    <option>Select Country</option>
                    {Country &&
                      Country?.map((country) => (
                        <>
                          <option value={country?._id}>{country?.name}</option>
                        </>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  >
                    {City && City?.length ? (
                      <>
                        <option>Select City</option>
                        {City.map((city) => (
                          <>
                            <option value={city?._id}>{city?.name}</option>
                          </>
                        ))}
                      </>
                    ) : (
                      <option>Select Country first</option>
                    )}
                  </Form.Control>
                </Form.Group>
              </div>
              <div style={{ flex: 1 }}>
                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g. 'ItsJames'"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="e.g 'qw123!2$1@'"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span>Password length min 8 characters required</span>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Re enter password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>
                {isParental && (
                  <React.Fragment>
                    <Form.Group>
                      <Form.Label>Parent Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter parent name"
                        onChange={(e) => setParentName(e.target.value)}
                        disabled={!isParental}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Parent Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter parent email"
                        onChange={(e) => setParentEmail(e.target.value)}
                        disabled={!isParental}
                      />
                    </Form.Group>
                    <Form.Group
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <p
                        style={{
                          maxWidth: '100%',
                          fontSize: '14px',
                          textAlign: 'center',
                        }}
                      >
                        You are under 13, filling parental details are necessary
                        for you
                      </p>
                    </Form.Group>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="mt-4">
              <Row>
                <Col>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    block
                    onClick={submitHandler}
                  >
                    Sign Up
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  {isLoading && <Spinner variant="primary" animation="grow" />}
                </Col>
              </Row>
            </div>
          </Form>

          <div className="text-center mt-5">
            <p className="light-gray">
              Already have an account? <Link to="/auth/login">Log In</Link>
            </p>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
}

export default Signup;
