import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SectionHeader from '../Core/SectionHeader';
import ContentSlider from './contentSlider';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import ContentPlaceHolderList from 'components/ContentLoader';

function PostViewer({
  title,
  posts,
  sortCondition,
  setSortCondition,
  // eslint-disable-next-line no-unused-vars
  keyword = null,
  noSortFilter = true,
  isLoading,
  viewBy,
}) {
  return (
    <div className="top-category section-padding mb-4">
      <Row>
        <Col md={12}>
          <SectionHeader
            sortCondition={sortCondition}
            setSortCondition={setSortCondition}
            heading={title}
            noIconLabel
            icon={faEllipsisH}
            noSortFilter={noSortFilter}
          />
        </Col>
        <Col md={12}>
          {isLoading ? (
            <ContentPlaceHolderList />
          ) : (
            <ContentSlider posts={posts} viewBy={viewBy} />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default PostViewer;
