import { combineReducers } from '@reduxjs/toolkit';
import UserPostReducer from './userPostSlice';
import ResourceReducer from './resourceSlice';
import UserDetailReducer from './userDetailsSlice';

export default combineReducers({
  UserPost: UserPostReducer,
  Resources: ResourceReducer,
  UserDetails: UserDetailReducer,
});
