import './Slide.scss';
import { VerifiedTooltip } from '../CustomCheckTooltips';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Slide({
  href,
  imgSrc,
  label,
  description,
  verified = null,
  categoryName = '',
}) {
  const verifyLabel = verified ? <VerifiedTooltip /> : '';
  return (
    <div className="item">
      <div className={`category-item`}>
        <Link to={`${href}`} title={label}>
          {/* <img
              className="img-fluid custom-slick-img"
              src={imgSrc}
              alt={imgAlt}
          /> */}
          <FontAwesomeIcon
            icon={imgSrc}
            fixedWidth
            className={`categoryIcon-${categoryName}`}
            size="2x"
          />
          <h6>
            {label} {verifyLabel}
          </h6>

          {/* <h6>
							{description} {verifyLabel}
						</h6> */}
          {/* <p>{views} views</p> */}
          {description && <p>{description}</p>}
        </Link>
      </div>
    </div>
  );
}
