import { baseURL } from 'config/constant';
import Utils from 'redux/utils';

const updateUserTalent = async (payload, token) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/talent`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: payload,
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getUserTalentById = async (payload, id) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/talent/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getUserPosts = async (payload, sortCondition) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/posts/userProfile?userId=${payload}&${sortCondition}=true`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
      data: payload,
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getUserDetails = async (id) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/users/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getUserFollowers = async (payload, token) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/userFollowing/getAllFollowerUsers/${payload}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
      // params: { id: payload },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getUserFollowings = async (payload, token) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/userFollowing/getAllFollowUsers/${payload}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
      // data: payload,
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getVisitedUserDetails = async (id, token) => {
  try {
    const apiOptions = token
      ? {
          endpoint: `${baseURL}/users/${id}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          method: 'GET',
        }
      : {
          endpoint: `${baseURL}/users/${id}`,
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
        };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const profileActions = {
  updateUserTalent,
  getUserTalentById,
  getUserPosts,
  getUserDetails,
  getUserFollowers,
  getUserFollowings,
  getVisitedUserDetails,
};

export default profileActions;
