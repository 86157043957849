import { useEffect } from 'react';

export default function UpdateProfileHero({
  coverImageFile,
  profileImageFile,
  coverImg,
  profileImg,
}) {
  useEffect(() => {}, [coverImageFile, profileImageFile]);

  return (
    <div className="single-channel-image">
      <img
        style={{ maxHeight: '400px' }}
        className="img-fluid"
        alt=""
        src={coverImg}
      />
      <div className="channel-profile">
        <img className="channel-profile-img" alt="" src={profileImg} />
        {/* <div className="social hidden-xs d-flex">
            <Form.File
              type="file"
              onChange={(e) => {
                setCoverImageFile(e.target.files[0]);
                setCoverImageState(false);
                setCoverImageSelect(e.target.files[0]);
              }}
            />

            <Form.File
              type="file"
              onChange={(e) => {
                setProfileImageFile(e.target.files[0]);
                setprofileImageState(false);
                setprofileImageSelect(e.target.files[0]);
              }}
            />
          </div> */}
      </div>
    </div>
  );
}
