import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import ReactGA from 'react-ga';
import CustomScrollToTop from './components/Core/ScrollToTop';
import Navigation from './components/Navigation/Navigation';
import Sidebar from './components/Sidebar/Sidebar';
import FourZeroFour from './components/FourZeroFour/FourZeroFour';
import SingleChannel from './components/SingleChannel/SingleChannel';
import VideoPage from './components/VideoPage/VideoPage';
import UploadVideo from './components/UploadVideo/UploadVideo';
import Upload from './components/Upload/Upload';

import Auth from './pages/Auth/Auth';
import Homepage from './pages/home';
import SubCategory from 'pages/subTalent';
import View from 'pages/view/subCategoryItems';
import Profile from 'pages/profile/Profile';
import UpdateProfile from 'pages/settings/UpdateProfile';
import CategoryDetails from 'pages/talents';
import Messages from 'pages/Messages';
import SearchPostByKeyword from 'pages/search/SearchPostByKeyword';
import ViewAll from 'pages/viewAll';

import { useDispatch, useSelector } from 'react-redux';
import {
  CountrySuccess,
  CountryPending,
  CountryFailed,
  TalentPending,
  TalentSuccess,
  TalentFailed,
} from 'redux/reducers/resourceSlice';
import resourceConfiguration from 'redux/middleware/resourceConfiguration';
import { getToken } from '@firebase/messaging';
import { messaging } from 'firebaseInit';
import {
  getNotificationPermission,
  getSignedInUser,
  setNotificationPermission,
} from 'utils/helpers';
import loader from 'assets/img/loading.svg';
import LoadingOverlay from 'react-loading-overlay';
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import AuthActions from 'redux/middleware/auth';
import { VAPID_KEY } from 'config/constant';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function App() {
  LoadingOverlay.propTypes = undefined;

  const [showMenu, setShowMenu] = useState(true);
  const dispatch = useDispatch();
  const loggedInUser = getSignedInUser();
  const { processLoading = false } = useSelector(
    (state) => state.Profile.Resources
  );
  const notificationPermission = getNotificationPermission();
  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: VAPID_KEY,
      });
      // save token in db
      const data = { deviceId: token };
      // eslint-disable-next-line no-unused-vars
      const response = await AuthActions.UserDeviceMapping(
        data,
        loggedInUser?.token
      );

      if (response.status) {
        setNotificationPermission(true);
      } else {
        setNotificationPermission(false);
      }
    } else if (permission === 'denied') {
      setNotificationPermission(false);
      console.log('Reject Push Notifications');
    }
  }

  const getCountryList = async () => {
    dispatch(CountryPending());
    try {
      const apiResponse = await resourceConfiguration.getCountries();

      if (apiResponse.status) {
        const { data } = apiResponse;
        const payload = {
          Country: data,
        };
        dispatch(CountrySuccess(payload));
      } else {
        const payload = {
          message: apiResponse.message,
        };
        dispatch(CountryFailed(payload));
      }
    } catch (error) {
      dispatch(CountryFailed(error));
    }
  };

  const getTalentList = async () => {
    dispatch(TalentPending());
    try {
      const apiResponse = await resourceConfiguration.getTalentList({
        subCategories: true,
      });
      if (apiResponse.status) {
        const { data } = apiResponse;
        const payload = {
          Talents: data,
        };
        dispatch(TalentSuccess(payload));
      } else {
        const payload = {
          message: apiResponse.message,
        };
        dispatch(TalentFailed(payload));
      }
    } catch (error) {
      dispatch(TalentFailed(error));
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add('sidebar-toggled');
    } else {
      document.body.classList.remove('sidebar-toggled');
    }
  }, [showMenu]);

  useEffect(() => {
    if (loggedInUser && !notificationPermission) {
      requestPermission();
    }
    getCountryList();
    getTalentList();
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        if (location.pathname.includes('/auth/') && getSignedInUser()) {
          window.location.href = window.location.origin;
        }
      }
    });
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/auth" render={() => <Auth />} />
        <Route path="/uploadVideo" render={() => <UploadVideo />} />
        <Route path="/SingleChannel" render={() => <SingleChannel />} />
        <Route path="/VideoPage" render={() => <VideoPage />} />
        <Route path="/Upload" render={() => <Upload />} />
        <Route path="/">
          <Content
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            processLoading={processLoading}
          />
        </Route>
        <Route path="*" render={() => <FourZeroFour />} />
      </Switch>
    </Router>
  );
}

function Content(props) {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <LoadingOverlay
      active={props.processLoading}
      spinner={<img src={loader} className="tmw_overlay_loader" />}
      // text="Uploading your content..."
    >
      <ToastContainer />
      <div className="homepage-wrapper">
        <Navigation props={props} />
        <div id="wrapper">
          <Sidebar props={props} />

          <Switch>
            <Route exact path="/">
              <Homepage />
            </Route>
            <Route path="/profile/:id">
              <Profile />
            </Route>
            <Route path="/updateProfile/:id">
              <UpdateProfile />
            </Route>
            <Route exact path="/watch/:id">
              <VideoPage />
            </Route>
            <Route exact path="/upload-video">
              <UploadVideo />
            </Route>
            <Route exact path="/upload">
              <Upload />
            </Route>
            <Route exact path="/talent/:name/:id">
              <CategoryDetails />
            </Route>
            <Route exact path="/sub-talent/:name/:id">
              <SubCategory />
            </Route>
            <Route exact path="/view/:name/:id">
              <View />
            </Route>
            <Route exact path="/all">
              <ViewAll />
            </Route>
            <Route exact path="/search">
              <SearchPostByKeyword />
            </Route>
            <Route exact path="/search/:id">
              <SearchPostByKeyword />
            </Route>
            {getSignedInUser() && (
              <>
                <Route exact path="/messages/:id">
                  <Messages />
                </Route>
                <Route exact path="/messages">
                  <Messages />
                </Route>
              </>
            )}
            <Route path="*" render={() => <FourZeroFour />} />
          </Switch>
        </div>
        {location.pathname !== '/messages' && (
          <ScrollToTop
            smooth
            component={<CustomScrollToTop />}
            className="scroll-to-top outline-0"
            color="white"
          />
        )}
      </div>
    </LoadingOverlay>
  );
}

export default App;
