/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import userEngagementActions from 'redux/middleware/userEngagement';
import { getContentThumbnailURL } from 'utils/helpers';
import { CommentMedia, CustomMedia } from './CommentMedia';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const CommentBody = (props) => {
  const { userSinglePost } = props;
  const { userDetails } = useSelector((state) => state.Profile.UserDetails);
  const [comments, setComments] = React.useState([]);
  const location = useLocation().pathname.split('/');

  React.useEffect(() => {
    apiCallComments();
    setInterval(() => {
      apiCallComments();
    }, 10000);
  }, []);

  const apiCallComments = async () => {
    try {
      const apiResponse = await userEngagementActions.getComments(location[2]);
      if (apiResponse.status) {
        const { data } = apiResponse;
        setComments(data);
      } else {
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {
      // FIXME
    }
  };

  useEffect(() => {
    userSinglePost && apiCallComments();
  }, [userSinglePost]);

  return (
    <React.Fragment>
      <CustomMedia
        commentCount={comments?.length}
        userDetails={userDetails}
        onSubmitSuccess={apiCallComments}
      />
      {comments?.map((comment, i) => (
        <CommentMedia
          key={i}
          commentAuthorPP={getContentThumbnailURL(
            'photo',
            comment?.userId?.Authentication?.UserName,
            comment?.userId?.Config?.ProfilePic
          )}
          commentAuthor={`${comment?.userId?.FirstName} ${comment?.userId?.LastName}`}
          commentAuthorId={comment?.userId?._id}
          commentBody={[comment?.desc]}
          commentMention={comment?.mentioned}
          commentHashTags={comment?.hashTags}
          timeAgo={moment(comment?.createdAt).fromNow()}
          likesCount="123"
          unlikesCount="02"
        />
      ))}
    </React.Fragment>
  );
};

export default CommentBody;
