import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SectionHeader from 'components/Core/SectionHeader';
import AllSubCategoriesOfCategorySlider from './subCategorySlider';

import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

function AllSubCategoriesOfCategory({
  allSubCategories,
  sortCondition,
  setSortCondition,
}) {
  const location = useLocation();
  return (
    <div className="top-category section-padding mb-4">
      <Row>
        <Col md={12}>
          <SectionHeader
            sortCondition={sortCondition}
            setSortCondition={setSortCondition}
            heading={`${
              location?.pathname?.split('/') &&
              location?.pathname?.split('/')[2]
            }`}
            noIconLabel
            icon={faEllipsisH}
            noSortFilter
          />
        </Col>
        <Col md={12}>
          <AllSubCategoriesOfCategorySlider
            allSubCategories={allSubCategories}
          />
        </Col>
      </Row>
    </div>
  );
}

export default AllSubCategoriesOfCategory;
