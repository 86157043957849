import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TopSlider from './TopSlider';
import ContentPlaceHolderList from 'components/ContentLoader';
import './styles.scss';

function PostTypeSlider({ list, isLoading, onPressType, selectedPostType }) {
  return (
    <div className="top-category section-padding mb-4">
      <Row>
        <Col md={12}>
          {isLoading ? (
            <ContentPlaceHolderList type="User" />
          ) : (
            <TopSlider
              list={list}
              onPressOptions={onPressType}
              selectedPostType={selectedPostType}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default PostTypeSlider;
