/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import Spinner from 'react-bootstrap/Spinner';
import { Row, Col } from 'react-bootstrap';
import ChannelCard from '../../components/Core/ChannelCard';
import { useEffect, useState } from 'react';
import profileActions from 'redux/middleware/profile';
import { getContentThumbnailURL, getProfilePicture } from 'utils/helpers';

const Following = ({ userDetails }) => {
  const { token } = JSON.parse(localStorage.getItem('__tmw_user'));
  const [following, setFollowing] = useState([]);
  const [followUnFollowToggle, setFollowUnFollowToggle] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const apiCallFollowing = async () => {
    try {
      setLoading(true);
      const apiResponse = await profileActions.getUserFollowings(
        userDetails._id,
        token
      );
      if (apiResponse.status) {
        const { data } = apiResponse;
        setFollowing(data);
        setLoading(false);
      } else {
        setLoading(false);
        const payload = {
          message: apiResponse.message,
        };
      }
    } catch (error) {}
  };

  useEffect(() => {
    apiCallFollowing();
  }, [followUnFollowToggle]);

  return (
    <div className="video-block section-padding ">
      <Row>
        {/* following?.length > 0 || (
          <Col
            md={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '25px 0',
            }}
          >
            <h3>{`You haven't followed anyone`}</h3>
          </Col>
          ) */}
        {isLoading && <Spinner variant="primary" animation="grow" />}

        {following?.map((item, index) => (
          <Col xl={3} sm={6} className="mb-3" key={index}>
            <ChannelCard
              channelHref={item?._id}
              setFollowUnFollowToggle={() =>
                setFollowUnFollowToggle(!followUnFollowToggle)
              }
              isFollow={item?.isFollow}
              imgSrc={getContentThumbnailURL(
                'photo',
                item?.Authentication?.UserName,
                item?.Config?.ProfilePic
              )}
              views={item?.views}
              channelName={item?.FirstName + ' ' + item?.LastName}
              subscriberCount={item?.followers}
              outline
              //   verified
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Following;
