import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import NotificationIcon from './assets/img/notification-icon.png';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBLBM-CbY1C9CP61TNynFJQ-1LYYncdCqQ',
  authDomain: 'talentmw-898e5.firebaseapp.com',
  projectId: 'talentmw-898e5',
  storageBucket: 'talentmw-898e5.appspot.com',
  messagingSenderId: '111302224990',
  appId: '1:111302224990:web:68c5ccfdd0ad4543dc58cf',
  measurementId: 'G-N3C2PSXMD1',
};

export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const reactGa = ReactGA.initialize(firebaseConfig.measurementId);

const openUrl = (data) => {
  const { eventId, postId = null, followerId = null, baseUrl } = data;
  let Url = null;

  switch (eventId) {
    case '003': // Add New Comment
      Url = `watch/${postId}`;
      break;
    case '002': // Add New Post
      Url = `watch/${postId}`;
      break;
    case '004': // Like
      Url = `watch/${postId}`;
      break;
    case '001': // FOLLOW
      Url = `profile/${followerId}`;
      break;
    default:
      break;
  }

  if (Url) {
    Url = `${baseUrl}${Url}`;

    window.location.href = Url;
  }
};

onMessage(messaging, (payload) => {
  const { data = null } = payload;

  const notificationTitle = `${data?.body}`;
  toast(notificationTitle, {
    icon: () => <img src={NotificationIcon} width={'100%'} />,
    onClick: () => openUrl(data),
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: true,
    pauseOnHover: true,
  });
});
