import {
  alt_youtube_img_thumbnail_path,
  contentBaseURL,
  defaultPath,
  folderPaths,
  yt_hres_default_img_path,
} from 'config/constant';

const placeholderProfile = `uploads/profile/image/imagePlaceholder.jpg`;
const placeholderCover = `uploads/cover/coverImagePlaceholder.png`;
export const placeholderVideo = `uploads/video_thumbnails/video_thumbnail.jpg`;

export const getProfilePicture = (picture = null) => {
  if (picture === '' || !picture) {
    const img_path = `${defaultPath}/${placeholderProfile}`;
    return img_path;
  }
  return `${defaultPath}/${picture}`;
};

export const getCoverPhoto = (picture) => {
  if (picture === '' || !picture) return `${defaultPath}/${placeholderCover}`;

  return `${defaultPath}/${picture}`;
};

export const getVideoThumbnail = (video) => {
  if (video === '' || !video) return `${defaultPath}/${placeholderVideo}`;

  return `${defaultPath}/${video}`;
};

export const getSignedInUser = () => {
  let user = null;
  user = localStorage.getItem('__tmw_user');
  if (user) {
    return JSON.parse(user);
  } else {
    return null;
  }
};

export const setNotificationPermission = (permission) => {
  try {
    if (permission) {
      localStorage.setItem('__tmw_notification_permission', permission);
    } else {
      localStorage.removeItem('__tmw_notification_permission');
    }
  } catch (error) {
    console.log('setNotificationPermission -> error', error);
  }
};

export const getNotificationPermission = () => {
  let permission = null;
  permission = localStorage.getItem('__tmw_notification_permission');
  if (permission) {
    return JSON.parse(permission);
  } else {
    return null;
  }
};

export const getContentThumbnailURL = (type, userName, content) => {
  switch (type) {
    case '63778f613e6ea7415d76345e': // Post Type Images
      if (content?.images[0]?.includes('http')) return content?.videoImageUrl;
      return `${contentBaseURL}/${userName}/${folderPaths.photos}/${content?.images[0]}`;
    case '63778f703e6ea7415d763461': {
      // Post Type Video
      if (content?.videoType === 'youtube' && content?.videoImageUrl === null) {
        return youtubeThumbnailURL(content?.videoUrl);
      }
      if (content?.videoImageUrl?.includes('http'))
        return content?.videoImageUrl;
      return `${contentBaseURL}/${userName}/${folderPaths.video_thumbnail}/${content?.videoImageUrl}`;
    }
    case 'coverPhoto':
      // if (content.includes('http')) return content;
      if (content === undefined || content === '') {
        const cover_path = getCoverPhoto();
        return cover_path;
      }
      return `${contentBaseURL}/${userName}/${folderPaths.cover_photo}/${content}`;
    case 'photo': {
      if (content === undefined || content === '') {
        const profile_path = getProfilePicture();
        return profile_path;
      }
      // if (content.includes('http')) return content;
      return `${contentBaseURL}/${userName}/${folderPaths.profile_photos}/${content}`;
    }
    default: // Post Type Audio
      if (content?.audioImageUrl?.includes('http'))
        return content?.audioImageUrl;
      return `${contentBaseURL}/${userName}/${folderPaths.audio_thumbnail}/${content?.audioImageUrl}`;
  }
};

export const getContentURL = (type, userName, content) => {
  let Url = null;
  switch (type) {
    case '63778f703e6ea7415d763461': {
      // Post Type Video
      if (content?.videoUrl?.includes('http')) Url = content?.videoUrl;
      Url = `${contentBaseURL}/${userName}/${folderPaths.video}/${content?.videoUrl}`;
      if (!content?.videoUrl.includes('360_')) {
        Url = `${contentBaseURL}/${userName}/${folderPaths.video}/360_${content?.videoUrl}`;
      }
      break;
    }
    case 'photos': {
      // Post Type Images
      if (content?.includes('http')) Url = content;
      Url = `${contentBaseURL}/${userName}/${folderPaths.photos}/${content}`;
      break;
    }
    default: {
      // Post Type Audio
      if (content?.audioUrl?.includes('http')) Url = content?.audioUrl;
      Url = `${contentBaseURL}/${userName}/${folderPaths.audio}/${content?.audioUrl}`;
      break;
    }
  }
  return Url;
};

export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? ' :' : ' : ') : '';
  var mDisplay = m > 0 ? m + (m == 1 ? ' :' : ' : ') : '';
  var sDisplay = s > 0 ? s + (s == 1 ? ' :' : '') : '';
  return hDisplay + mDisplay + sDisplay;
};

export const toTime = (seconds) => {
  var date = new Date(null);
  date.setSeconds(seconds);
  return date.toISOString().substring(14, 19);
};

export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
  } else if (num < 999) {
    return num; // if value < 1000, nothing to do
  }
};

export const youtubeThumbnailURL = (yt_url) => {
  let yt_params, yt_id;
  if (yt_url.includes('youtu.be')) {
    yt_params = yt_url.split('youtu.be/');
    yt_id = yt_params.length && yt_params[1].split('?')[0];
  } else {
    yt_params = yt_url.split('=');
    yt_id = yt_params.length && yt_params[1];
  }
  const yt_img_url = `${alt_youtube_img_thumbnail_path}${yt_id}${yt_hres_default_img_path}`;
  return yt_img_url;
};
